export const AddFilterInImage = (e, canvas) => {
  //Adding all filters with value 0
  if ((e.target.type === "image" && e.target.imageRect) || e.target._type === "stencil_frame") {
    // alert("here")
    let img = e.target;
    var webglBackend;
    try {
      webglBackend = new fabric.WebglFilterBackend();
    } catch (e) {
      console.log(e);
    }
    var canvas2dBackend = new fabric.Canvas2dFilterBackend();
    fabric.filterBackend = fabric.initFilterBackend();
    // if(img.filter)
    // if(img.filters[0])
    let brightnessFilter = img.filters.push(new fabric.Image.filters.Brightness({ brightness: 0 })); // index of brightness filter = 0
    let contrastFilter = img.filters.push(new fabric.Image.filters.Contrast({ contrast: 0 })); // index of contrast filter = 1
    let saturationFilter = img.filters.push(new fabric.Image.filters.Saturation({ saturation: 0 })); // index of saturation filter = 2
    let blurFilter = img.filters.push(new fabric.Image.filters.Blur({ blur: 0 })); // index of saturation filter = 3
    // index of pixelate filter = 4

    fabric.filterBackend = canvas2dBackend;
    img.applyFilters();
    canvas.renderAll();
  }
  if (e.target.type === "textbox") {
    // e.target.minScaleLimit = 1
    e.target.setControlVisible("mt", false);
    e.target.setControlVisible("mb", false);
  }
  ////////////////////////////////
  if (e.target.id === "_cropArea" || e.target.id === "_bndry") {
  } else {
    e.target.set({
      cornerColor: "rgba(255,255,255,1)",
      cornerStrokeColor: "rgba(0,0,0, 0.15)",
      cornerHighlightColor: "#4BD3FB",
      cornerRoundSize: 3,
      // cornerColor: 'green',
    });
    // e.target.setControlsVisibility({ tl: true, mt: false });
  }
};
export const AddFilterInJSON=(obj,canvas)=>{
  if ((obj.type === "image" && obj.imageRect) || obj._type === "stencil_frame") {
    // alert("here")
    let img =obj;
    var webglBackend;
    try {
      webglBackend = new fabric.WebglFilterBackend();
    } catch (e) {
      console.log(e);
    }
    var canvas2dBackend = new fabric.Canvas2dFilterBackend();
    fabric.filterBackend = fabric.initFilterBackend();
    // if(img.filter)
    // if(img.filters[0])
    let brightnessFilter = img.filters.push(new fabric.Image.filters.Brightness({ brightness: 0 })); // index of brightness filter = 0
    let contrastFilter = img.filters.push(new fabric.Image.filters.Contrast({ contrast: 0 })); // index of contrast filter = 1
    let saturationFilter = img.filters.push(new fabric.Image.filters.Saturation({ saturation: 0 })); // index of saturation filter = 2
    let blurFilter = img.filters.push(new fabric.Image.filters.Blur({ blur: 0 })); // index of saturation filter = 3
    // index of pixelate filter = 4

    fabric.filterBackend = webglBackend;
    img.applyFilters();
    canvas.renderAll();
  }
}
