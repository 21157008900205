import { event } from "jquery";
import { _onClipMouseDown } from "../component/ImagesTemplate/Ap_CropImage";
import {
  CropImage,
  RestoreimagePosition,
  _adjustPositionV2,
  _onMousedown,
  _onScaling,
  handleMoving,
  handleRotating,
  handleScaling,
} from "../component/ImagesTemplate/CropImage copy";
import {
  Restore_background,
  crop_Background,
} from "../component/toolBar/Bg_crop";
import {
  cropStencils,
  deleteFromCanvas,
  handleImageToStencilMovement,
  handleStencilScaling,
  restoreStencil,
  NewCalStencil,
} from "../Reducer/Canvas_Slicer";

let canvass;
export const initializeCanvasEventListeners = (canvas) => {
  canvass = canvas;
  canvas.on("object:moving", handleObjectMoving);
  canvas.on("object:rotating", handleObjectRotating);
  canvas.on("object:scaling", handleObjectScaling);

  canvas.on("mouse:down", handleCanvasMouseDown);
  canvas.on("mouse:dblclick", handleCanvasDoubleClick);
  canvas.on("mouse:up", handleMouseUp);
  canvas.on("mouse:move", handleMouseMove);

  canvas.on("selection:updated", handleCanvasSelectionUpdate);
  canvas.on("selection:cleared", handleCanvasSelectionCleared);
  canvas.on("before:transform", handleCanvasBeforeTransform);

  let lastTap = 0;

  if (window.innerWidth < 991) {
    canvas.on("mouse:up", (event) => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTap;

      if (tapLength < 300 && tapLength > 0) {
        handleCanvasDoubleClick(event);
      }

      lastTap = currentTime;
    });
  }
};
const handleObjectMoving = (e) => {
  handleImageToStencilMovement(e);
  if (e && e.target && e.target.type === "rect" && e.target.original_img) {
    handleMoving(e.transform.target.canvas, e.transform.target);
  } else if (
    e &&
    e.transform &&
    e.transform.target.type === "image" &&
    e.transform.target.imageRect
  ) {
    _adjustPositionV2(e);
  }

  if (e && e.target) {
    let canvas = e.target.canvas;
    if (!canvas.crop_mode) {
      if (e.target) {
        e.target.hasControls = false;
        e.target.borderDashArray = [3];
        canvas.renderAll();
      }
    }
  }
};
const handleObjectRotating = (e) => {
  if (e && e.target && e.target.type === "rect" && e.target.original_img) {
    handleRotating(e.transform.target.canvas, e.transform.target);
  }
};

const handleObjectScaling = (e) => {
  if (e && e.target && e.target.type === "rect" && e.target.original_img) {
    handleScaling(e.transform.target.canvas, e);
  } else if (
    e &&
    e.transform &&
    e.transform.target.type === "image" &&
    e.transform.target.imageRect
  ) {
    _onScaling(e);
  }

  if (e.target.canvas.crop_mode) {
    if (e && e.target._type == "stencil_frame") {
      handleStencilScaling(e);
    }
  }
  if (e && e.target._type == "stencil_frame") {
    NewCalStencil(e);
  }
};

const handleMouseUp = (e) => {
  // console.log(e.target, e.target.canvas, "Default GlobalMouseUp");
  if (e && e.target) {
    deleteFromCanvas(e.target, e.target.canvas);
  }
  HandToolMouseUpHandler(e);
};

const handleMouseMove = (e) => {
  HandToolMouseMoveHandler(e);
};

const handleCanvasMouseDown = (e) => {
  HandToolMouseDownHandler(e);
  if (e && e.target && e.target.type === "rect" && e.target.original_img) {
    _onClipMouseDown(e, e.target.original_img);
  } else if (e && e.target && e.target.type === "image" && e.target.imageRect) {
    _onMousedown(e);
  } else {
    return;
  }
};
const handleCanvasDoubleClick = (e) => {
  console.log(e.target, "trgetttt");

  if (
    (e && e.target && e.target.typeis == "image") ||
    (e &&
      e.target &&
      e.target.type &&
      e.target.type === "image" &&
      e.target.imageRect)
  ) {
    handleImageCropping(e.target.canvas, e);
  } else if (e.target && e.target.type_ && e.target.type_ === "background") {
    if (!e.target.canvas.crop_mode) {
      $("#mdcropping").click();
    } else if (e.target.canvas.crop_mode) {
      $("#cropdone").click();
    }
  } else if (e.target && e.target._type && e.target._type == "stencil_frame") {
    let Canvas = e.target.canvas;
    let cropMode_stn = Canvas && Canvas.crop_mode;

    if (e.target.placeholder == 0) {
    } else {
      if (!Canvas.crop_mode) {
        Canvas.discardActiveObject();
        Canvas.setActiveObject(e.target);
        Canvas.crop_mode = !0;
        cropStencils(e, e.target, Canvas);
        cropMode_stn = !0;
      } else if (Canvas.crop_mode) {
        restoreStencil(e.target, Canvas);
        e.target.count = e.target.count + 1;
      }
    }
  }
};
const handleImageCropping = (canvas, e) => {
  let boxframe = canvas.getObjects().filter((o) => o.type_ === "background")[0];

  if (e.target.type === "rect" && e.target.original_img) {
    if (canvas.crop_mode == !1) {
      canvas.crop_mode = !0;
      let Active_img = CropImage(
        canvas,
        e,
        e.target.original_img,
        boxframe,
        e.target
      );
      canvas.croping_active_img = Active_img;
      canvas.renderAll();
    } else return;
  } else if (
    e.target.type === "image" &&
    e.target.type_ &&
    e.target.type_ === "background"
  ) {
    // console.log("doubleclick bg")
  } else if (e.target.type === "image" && e.target.imageRect) {
    if (canvas.crop_mode) {
      let cropData = RestoreimagePosition(
        canvas,
        e.target,
        [e.target.imageRect],
        boxframe,
        e.target.cropArea,
        canvas.crop_mode
      );
      canvas.crop_mode = cropData.cropMode;
      canvas.setActiveObject(cropData.imageRect);
      return;
    }
  } else if (
    e.target.type === "rect" &&
    e.target.pimg &&
    e.target.id === "_cropArea"
  ) {
    if (canvas.crop_mode) {
      let cropData = RestoreimagePosition(
        canvas,
        e.target.pimg,
        [e.target.pimg.imageRect],
        boxframe,
        e.target.pimg.cropArea,
        canvas.crop_mode
      );
      canvas.crop_mode = cropData.cropMode;
      canvas.setActiveObject(cropData.imageRect);
    }
  }
};
const handleCanvasSelectionUpdate = (e) => {
  if (e && e.target) {
    let canvas = e.target.canvas;
    if (e.target.canvas) {
      canvas = e.target.canvas;
    } else if (e.target.original_img && e.target.original_img.canvas) {
      canvas = e.target.original_img.canvas;
    }
    if (canvas.croping_active_img) {
      if (canvas.crop_mode) {
        if (
          e.deselected &&
          e.deselected.length >= 0 &&
          e.deselected[0].id === "_cropArea" &&
          e.selected &&
          e.selected.length >= 0 &&
          e.selected[0].id === canvas.croping_active_img.id &&
          e.selected &&
          e.selected.length >= 0 &&
          e.selected[0].type === canvas.croping_active_img.type
        ) {
          return;
        } else if (
          e.deselected &&
          e.deselected.length >= 1 &&
          e.deselected[0].id === canvas.croping_active_img.id &&
          e.selected &&
          e.selected.length >= 1 &&
          e.selected[0].id === "_cropArea"
        ) {
          return;
        } else {
          let boxframe = canvas
            .getObjects()
            .filter((o) => o.type_ === "background")[0];
          let cropData = RestoreimagePosition(
            canvas,
            e.deselected[0].id === "_cropArea"
              ? e.deselected[0].pimg
              : e.deselected[0],
            e.deselected[0].id === "_cropArea"
              ? [e.deselected[0].pimg.imageRect]
              : [e.deselected[0].imageRect],
            boxframe,
            e.deselected[0].id === "_cropArea"
              ? e.deselected[0]
              : e.deselected[0].cropArea,
            canvas.crop_mode
          );
          // ropMode = cropData.cropMode;

          canvas.crop_mode = cropData.cropMode;
          canvas.renderAll();
        }
      }
    } else if (canvas.crop_mode) {
      console.log("insideeeeee");
      if (e.deselected[0]._type == "stencil_frame") {
        console.log("Inside Hii");
        restoreStencil(e.deselected[0], canvas);
      } else if (e.deselected[0]?.type_ === "background") {
        Restore_background(canvas, e.deselected[0], "selectionUpdate");
        canvas.crop_mode = !1;
        canvas.renderAll();
      }
    }
  }
};
const getOriginalRect = (e) => {
  var t = e.target.getCenterPoint(),
    a = fabric.util.rotatePoint(
      new fabric.Point(e.target.left, e.target.top),
      t,
      fabric.util.degreesToRadians(-(e.target.angle || 0))
    );
  return {
    x: a.x,
    y: a.y,
    width: e.target.width,
    height: e.target.height,
    scaleX: e.target.scaleX,
    scaleY: e.target.scaleY,
  };
};
const handleCanvasSelectionCleared = (e) => {
  if (e.deselected && e.deselected.length >= 0) {
    let canvas = e.deselected[0].canvas;
    if (canvas && canvas.crop_mode) {
      if (
        (e.deselected &&
          e.deselected.length >= 0 &&
          e.deselected[0].id === "_cropArea") ||
        (canvas.croping_active_img &&
          canvas.croping_active_img.id &&
          e.deselected &&
          e.deselected.length >= 0 &&
          e.deselected[0].id === canvas.croping_active_img.id)
      ) {
        console.log("Hiiii");
        let boxframe = canvas
          .getObjects()
          .filter((o) => o.type_ === "background")[0];
        let cropData = RestoreimagePosition(
          canvas,
          e.deselected[0].id === "_cropArea"
            ? e.deselected[0].pimg
            : e.deselected[0],
          e.deselected[0].id === "_cropArea"
            ? [e.deselected[0].pimg.imageRect]
            : [e.deselected[0].imageRect],
          boxframe,
          e.deselected[0].id === "_cropArea"
            ? e.deselected[0]
            : e.deselected[0].cropArea,
          canvas.crop_mode
        );

        canvas.crop_mode = cropData.cropMode;
      } else if (
        e.deselected[0]._type &&
        e.deselected[0]._type == "stencil_frame"
      ) {
        console.log("Inside Hii");
        restoreStencil(e.deselected[0], canvas);
      } else if (
        e.deselected[0].type_ &&
        e.deselected[0].type_ == "background"
      ) {
        Restore_background(canvas, e.deselected[0]);
      }
    }
  }
};
const handleCanvasBeforeTransform = (e) => {
  if (
    e.transform &&
    e.transform.action === "rotate" &&
    e.transform.corner === "mtr"
  ) {
    const new_rect = getOriginalRect(e.transform);

    // canvas.renderAll();
  }
};

const STATE_IDLE = "idle";
const STATE_PANNING = "panning";
let state = STATE_IDLE;
let lastClientX;
let lastClientY;

const getClientCoordinates = (e) => {
  return e.touches
    ? { clientX: e.touches[0].clientX, clientY: e.touches[0].clientY }
    : { clientX: e.clientX, clientY: e.clientY };
};

const HandToolMouseUpHandler = (e) => {
  let canvas = canvass;
  if (canvas.dragMode) {
    state = STATE_IDLE;
    lastClientX = null;
    lastClientY = null;
  }
};

const HandToolMouseDownHandler = (e) => {
  let canvas = canvass;
  if (canvas.dragMode) {
    state = STATE_PANNING;
    const { clientX, clientY } = getClientCoordinates(e.e);
    lastClientX = clientX;
    lastClientY = clientY;
  }
};

const HandToolMouseMoveHandler = (e) => {
  let canvas = canvass;
  if (canvas.dragMode) {
    if (state === STATE_PANNING && e && e.e) {
      // Calculate deltas
      let deltaX = 0;
      let deltaY = 0;
      const { clientX, clientY } = getClientCoordinates(e.e);

      if (lastClientX) {
        console.log("InsideMovement");
        deltaX = clientX - lastClientX;
      }
      if (lastClientY) {
        deltaY = clientY - lastClientY;
      }

      // Update the last X and Y values
      lastClientX = clientX;
      lastClientY = clientY;

      let delta = new fabric.Point(deltaX, deltaY);
      canvas.relativePan(delta);
    }
  }
};
