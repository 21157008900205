import { FUNCTION_URL } from "./AppConstant";
import { getCookies, setCookies } from "./Cookies";
import * as appConstants from "../utils/AppConstant";
import MeterBillingPlanPriceJson from "../utils/MeterBillingPlanPrice.json";
import jsonData from "../component/ImagesTemplate/ImageCategories.json";
import axios from "axios";
import { dycKeyData } from "./AuthService";
import { setFirebaseDocument, updateFirebaseDocument } from "../helper/sdk_factory";

const getPaymentPostData = async (queryString, body) => {
  const authHeaders = new Headers();
  authHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: authHeaders,
    body: JSON.stringify(body),
  };

  return await fetch(`${FUNCTION_URL}${queryString}`, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);

      return "error";
    });
};

export async function getPaymentPlans(payload) {
  const responseData = await getPaymentPostData("/payment_conversions", payload);
  // console.log('---payment country----', responseData);
  return responseData;
}
export async function encryptPaymentPlan(plan) {
  const responseData = await getPaymentPostData("/payment_encryption", plan);
  return responseData;
}
export async function getBillingUrl() {
  const clientId = getCookies("clientId");
  const emailId = getCookies("userEmail");
  const billingId = `${clientId}####${emailId}####design`;
  const payload = {
    action: "encrypt",
    string: billingId,
  };
  const encData = await encryptPaymentPlan(payload);
  setCookies("billingUrl", encData.string);
  return encData.string;
}
export const isFreeUser = () => {
  const freeUser =
    getCookies("userType") === appConstants.USER_TYPE.FREE ? true : false || getCookies("userType") === appConstants.USER_TYPE.CANCLED ? true : false;

  console.log("user type------>>>>>", appConstants.USER_TYPE, getCookies("userType"));
  return freeUser;
};

export async function getCountryCode() {
  let countryCode = "US";
  const myHeaders = new Headers();
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch("https://checkout.appypie.com/api/get-visitor-ip", requestOptions)
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.status === 1) {
        countryCode = responseJson.countryCode;
      }
      return countryCode;
    })
    .catch((error) => console.log("error", error));
}

export const encryptDecryptDataOld = async (type, d) => {
  var data = JSON.stringify({
    action: type,
    string: d,
  });

  var config = {
    method: "post",
    // url: `${FUNCTION_URL}/payment_encryption`,
    url: `https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/payment_encryption`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = await axios(config);
  // console.log(response);
  if (type == "decrypt") {
    return response.data;
  } else {
    return response.data.string;
  }
};
export const encryptDecryptData = async (type, d) => {
  let plan = {
    action: type,
    string: d,
  };
  let response = await encryptPaymentPlan(plan);
  // console.log("handleMeterBilling encryptPaymentPlan", response);
  // var data = JSON.stringify({
  //   action: type,
  //   string: d,
  // });

  // var config = {
  //   method: "post",
  //   // url: `${FUNCTION_URL}/payment_encryption`,
  //   url: `https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/payment_encryption`,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   data: data,
  // };

  // let response = await axios(config);
  // // console.log(response);

  if (type == "decrypt") {
    return response;
  } else {
    return response.string;
  }
};

export const isUserMeteredBilled = async (userId, productId) => {
  // console.log("handleMeterBilling ----------isUserMeteredBilled----------", userId, productId);
  let payload_raw = {
    productName: "design",
    userId: userId,
    productId: productId,
    method: "checkCurrentActivePlan",
  };
  console.time("login_time_checkCurrentActivePlan");
  const isMeter = await checkoutAPI(payload_raw);
  console.timeEnd("login_time_checkCurrentActivePlan");
  console.log(isMeter, " handleMeterBilling ----------checkCurrentActivePlan----------");
  return isMeter.data;
};

export const subscribeMeterBilling = async ({ userType, userId, productId, planId = 5, planPeriod, currency = "INR" }) => {
  console.log("handleMeterBilling subscribingProps", userType, userId, productId, planId, planPeriod);
  // if (userType == "FREE_USER") {
  //   const paymentData = await getPaymentPlans({
  //     string: {
  //       method: "pricing",
  //       product: "design",
  //       countryCode: this.state.countryCode,
  //       email: "dheerajs@appypiellp.com",
  //     },
  //   });
  //   console.log(paymentData, "----------planData----------");
  //   this.state.payment_details.currency = paymentData.priceArr[0].currencyCode;
  // }
  let planData = {
    productId: productId,
    method: "meterBillingPlanDataUpgrade",
    productType: "design",
    userId: userId,
    planId: planId, //this.state.payment_details.planId,
    currentPlanId: 1,
    billingCycle: planPeriod,
    currentSubscriptionPeriod: "",
    isUpgradePayment: 2,
    currency: currency, //this.state.payment_details.currency,
    resellerId: 0,
  };
  let response = await checkoutAPI(planData);
  console.log(response, planData, "handleMeterBilling,meterBillingPlanDataUpgrade");
  return response;
};

export const checkoutAPI = async (data) => {
  // let encryptedPayload = await encryptDecryptData("encrypt", data);


  let encryptedPayload = dycKeyData("encrypt", JSON.stringify(data));
  var config = {
    method: "post",
    url: `${appConstants.PAYMENT_DOMAIN_NAME}/api`,
    data: encryptedPayload,
  };
  let res = await axios(config);

  // let decryptedRes = await encryptDecryptData("decrypt", res.data);
  let decryptedRes = await dycKeyData("decrypt", res.data);
  // console.log("handleMeterBilling subscriePlanBenefits", decryptedRes);
  return decryptedRes;
};

export const updateCreditBalance = async ({ isUpdate = false, userId, productId, userType, currencyCode }) => {
  console.log("handleMeterBilling updateCreditBalance first-->>", userId, productId);
  console.time("login_time_updateCreditBalance");
  let getServiceExtraUsages = {
    userId: userId,
    productId: productId,
    productName: "design",
    // userId: "518",
    // productId: "319711701516911540",
    method: "getServiceUsageDetails",
  };
  // let getServiceExtraUsages = {
  //   userId: userId,
  //   productId: productId,
  //   // userId: "518",
  //   // productId: "319711701516911540",
  //   method: "getServiceExtraUsages",
  // };
  let quota = await checkoutAPI(getServiceExtraUsages);
  console.timeEnd("login_time_updateCreditBalance");
  let data = quota.data;
  const userWalletBalance = quota.walletBalance;
  console.log("handleMeterBilling updateCreditBalance", quota);
  let _credit = 0;
  let giftedItems = [];
  if (userType == "trial") {
    data = data.filter((service) => service.type !== "AIStudioGen");
    data.forEach((service) => {
      if (service.type === "AIStudioTrialGen") {
        service.type = "AIStudioGen"; // Change the type to "AIStudioGen"
      }
    });
  } 
  // else if (userType == "paid") {
  //   const aiStudioTrialCredit = data.filter((service) => service.type == "AIStudioTrialGen")[0];
  //   if (aiStudioTrialCredit.totalUsage <= aiStudioTrialCredit.giftedItem) {
  //     data.forEach((d) => {
  //       if (d.type == "AIStudioGen") {
  //         d.giftedItem += aiStudioTrialCredit.giftedItem - aiStudioTrialCredit.totalUsage;
  //       }
  //     });
  //   }
  // }
  data.forEach((s) => {
    let credit = parseInt(s.giftedItem) - parseInt(s.totalUsage);
    if (credit < 0) {
      credit = 0;
    }

    if (s.type == "AIImageGenerations") {
      _credit = _credit + credit;
      s.credits = credit;
      giftedItems.push(s);
    } else if (s.type == "AILogoGenerations") {
      _credit = _credit + credit;
      s.credits = credit;
      giftedItems.push(s);
    } else if (s.type == "AIVideoGenerations") {
      _credit = _credit + credit;
      s.credits = credit;
      giftedItems.push(s);
    } else if (s.type == "AIMusicGenerations") {
      _credit = _credit + credit;
      s.credits = credit;
      giftedItems.push(s);
    } else if (s.type == "AIVoiceOverGenerations") {
      _credit = _credit + credit;
      s.credits = credit;
      giftedItems.push(s);
    } else if (s.type == "AIStudioGen") {
      // _credit = _credit + credit;
      s.credits = credit;
      giftedItems.push(s);
    } else {
    }
  });
  let result;
  if (userType == "FREE_USER" || userType == "trialWithoutCC" || isUpdate) {
    // let AllServicesCreditAndCta = getAllServicesCreditAndCta(userType,giftedCredits,"IN",);
    result = { giftedCredits: _credit, giftedItems };
    // updateAllServicesCreditAndCta();
  } else {
    const { MeterdQuota, MeterBillingPrice } = await getMeterBillingPrices(userId, productId, currencyCode, userWalletBalance);
    result = { giftedCredits: _credit, giftedItems, userWalletBalance, MeterdQuota, MeterBillingPrice };
  }

  if (!result.MeterdQuota) {
    result.MeterdQuota = {
      AIVideoGenerations: 0,
      AIImageGenerations: 0,
      AILogoGenerations: 0,
      AIMusicGenerations: 0,
      AIVoiceOverGenerations: 0,
      AIStudioGen: 0,
    };
  }

  return result;
};

export const getMeterBillingPlanPriceByCountry = (currencyCode) => {
  let AllCountryPlanPrice = MeterBillingPlanPriceJson.meterBillingPlan;
  let currentCountryPlanPrice = AllCountryPlanPrice.find((plan) => plan.currency === currencyCode);
  return { meteredBillingArr: [currentCountryPlanPrice] };
};
export const getCountryCodeFromCurrency = (currencyCode) => {
  let AllCountryPlanPrice = MeterBillingPlanPriceJson.meterBillingPlan;
  let currentCountryPlanPrice = AllCountryPlanPrice.find((plan) => plan.currency === currencyCode);
  return currentCountryPlanPrice?.countryCode;
};

export const getMeterBillingPrices = async (userId, productId, currencyCode, userWalletBalance) => {
  console.time("login_time_meterBillingPlanPrice");
  // let getMeterBillingPricePayload = {
  //   // productId: productId,
  //   planId: 5,
  //   countryCode: countryCode,
  //   method: "meterBillingPlanPrice",
  // };
  // getMeterBillingPlanPriceByCountry(currencyCode);
  let MeterBillingPrice = getMeterBillingPlanPriceByCountry(currencyCode); //await checkoutAPI(getMeterBillingPricePayload);
  console.log("handleMeterBilling gettingMeterBillingPrice", MeterBillingPrice);
  console.timeEnd("login_time_meterBillingPlanPrice");
  const { MeterdQuota } = await updateWalletBalance(userId, MeterBillingPrice.meteredBillingArr[0].extraUsageCharges, userWalletBalance);
  // return { userMeterBalance: userBalance, MeterdQuota: quota };
  return { MeterdQuota, MeterBillingPrice };
  // return pernftprice;
  // this.setState(
  //   {
  //     priceList: pernftprice.meteredBillingArr[0].extraUsageCharges,
  //     currencySymbol: pernftprice.meteredBillingArr[0].currencySymbol,
  //     userBalance: parseFloat(pernftprice.userBalance.userBalance).toFixed(2),
  //   },
  //   () => {
  //     this.updateWalletBalance();
  //   }
  // );
};
// export const getMeterBillingPrices = async (userId, productId) => {
//   let getMeterBillingPricePayload = {
//     productId: productId,
//     method: "meterBillingPrice",
//   };

//   let MeterBillingPrice = await checkoutAPI(getMeterBillingPricePayload);
//   console.log("handleMeterBilling gettingMeterBillingPrice", MeterBillingPrice);
//   const { userWalletBalance, MeterdQuota } = await updateWalletBalance(userId, MeterBillingPrice.meteredBillingArr[0].extraUsageCharges);
//   // return { userMeterBalance: userBalance, MeterdQuota: quota };
//   return { userWalletBalance, MeterdQuota, MeterBillingPrice };
//   // return pernftprice;
//   // this.setState(
//   //   {
//   //     priceList: pernftprice.meteredBillingArr[0].extraUsageCharges,
//   //     currencySymbol: pernftprice.meteredBillingArr[0].currencySymbol,
//   //     userBalance: parseFloat(pernftprice.userBalance.userBalance).toFixed(2),
//   //   },
//   //   () => {
//   //     this.updateWalletBalance();
//   //   }
//   // );
// };

export const updateWalletBalance = async (userId, priceList, userWalletBalance) => {
  // let getCreditBlance = {
  //   productName: "design",
  //   method: "getCreditBlance",
  //   userId: userId,
  // };
  // let walletBalance = await checkoutAPI(getCreditBlance);

  // console.log("handleMeterBilling updateWalletBalance", walletBalance);

  let userBalance = parseFloat(userWalletBalance);
  // this.setState({ userBalance: userBalance.toFixed(2) });
  // const priceList = this.state.priceList;

  const AIVideoGenerations = Math.floor(userBalance / parseFloat(priceList["AIVideoGenerationsPrice"]).toFixed(3));
  const AIImageGenerations = Math.floor(userBalance / parseFloat(priceList["AIImageGenerationsPrice"]).toFixed(3));
  const AILogoGenerations = Math.floor(userBalance / parseFloat(priceList["AILogoGenerationsPrice"]).toFixed(3));
  const AIMusicGenerations = Math.floor(userBalance / parseFloat(priceList["AIMusicGenerationsPrice"]).toFixed(3));
  const AIVoiceOverGenerations = Math.floor(userBalance / parseFloat(priceList["AIVoiceOverGenerationsPrice"]).toFixed(3));
  const AIStudioGen = Math.floor(userBalance / parseFloat(priceList["AIStudioGenPrice"]).toFixed(3));
  const quota = {
    AIVideoGenerations: AIVideoGenerations,
    AIImageGenerations: AIImageGenerations,
    AILogoGenerations: AILogoGenerations,
    AIMusicGenerations: AIMusicGenerations,
    AIVoiceOverGenerations: AIVoiceOverGenerations,
    AIStudioGen: AIStudioGen,
  };
  console.log("handleMeterBilling, ----------meteredQuota----------", quota, userBalance, priceList);
  return { MeterdQuota: quota };
  // this.state.AICreditsMetered = quota;
  // this.updateAllServicesCreditAndCta();
};

export const getAllServicesCreditAndCta = (userType, giftedCredits, MeterdQuota, countryCode, paymentStatus) => {
  const data = {};
  data.AIVideoGenerations = getCreditInfo("AIVideoGenerations", userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  data.AIImageGenerations = getCreditInfo("AIImageGenerations", userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  data.AILogoGenerations = getCreditInfo("AILogoGenerations", userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  data.AIMusicGenerations = getCreditInfo("AIMusicGenerations", userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  data.AIVoiceOverGenerations = getCreditInfo("AIVoiceOverGenerations", userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  data.AIStudioGen = getCreditInfo("AIStudioGen", userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  return data;
};

export const getCreditInfo = (aiServiceName, userType, giftedCredits, MeterdQuota, countryCode, paymentStatus) => {
  if (userType == "trialWithoutCC") {
    return {
      country: countryCode,
      isFreeUser: true,
      isMeterBilled: false,
      creditRemaining: giftedCredits,
      CTA: {
        code_str: "GET_DESIGN_PRO_2_UNLOCK",
        code_num: 2,
        msg: "trial user has exhausted his free limit upgrade now to use remaining credits",
      },
    };
  }

  if (userType == "canceled" || userType == "refund") {
    return {
      country: countryCode,
      isFreeUser: true,
      isMeterBilled: false,
      creditRemaining: 0,
      CTA: {
        code_str: "GET_DESIGN_PRO",
        code_num: 4,
        msg: "free user has exhausted free credits ",
      },
    };
  }
  const btn = isGenerationAllowed(aiServiceName, userType, giftedCredits, MeterdQuota, countryCode, paymentStatus);
  if (userType == "FREE_USER") {
    return {
      country: countryCode,
      isFreeUser: true,
      isMeterBilled: false,
      creditRemaining: giftedCredits,
      CTA: btn,
    };
  } else {
    if (giftedCredits > 0) {
      return {
        country: countryCode,
        isFreeUser: false,
        isMeterBilled: false,
        creditRemaining: giftedCredits,
        CTA: btn,
      };
    } else {
      return {
        country: countryCode,
        isFreeUser: false,
        isMeterBilled: true,
        creditRemaining: MeterdQuota[aiServiceName],
        CTA: btn,
      };
    }
  }
};

export const isGenerationAllowed = (name, userType, giftedCredits, MeterdQuota, countryCode, paymentStatus) => {
  if (userType == "FREE_USER") {
    if (giftedCredits > 0) {
      return {
        code_str: "GENERATE_1",
        code_num: 0,
        msg: "user is allowed to generate ",
      };
    } else {
      //   const { disableTryNowCountry, countryCode } = this.state;
      //   const disableTryNowCountry=appConstant
      const isTrialAllowed = !disableTryNowCountry.includes(countryCode);
      if (!countryCode) {
        //check and get updated country code before proceeding
      }
      console.log("isTrialAllowed", isTrialAllowed, countryCode);
      return {
        code_str: isTrialAllowed ? "GET_TRIAL" : "GET_DESIGN_PRO",
        code_num: isTrialAllowed ? 1 : 4,
        msg: "free user has exhausted free credits ",
      };
    }
  } else {
    // const paymentStatus = paymentStatus.paymentStatus;
    if (giftedCredits > 0) {
      if (paymentStatus == "trial" || paymentStatus == "trialWithoutCC") {
        if (giftedCredits > 15) {
          return {
            code_str: "GENERATE_1",
            code_num: 0,
            msg: "user is allowed to generate ",
          };
        } else {
          return {
            code_str: "GET_DESIGN_PRO_2_UNLOCK",
            code_num: paymentStatus == "trialWithoutCC" ? 2 : 5,
            msg: "trial user has exhausted his free limit upgrade now to use remaining credits",
          };
        }
      } else {
        return {
          code_str: "GENERATE_1",
          code_num: 0,
          msg: "user is allowed to generate ",
        };
      }
    } else {
      if (MeterdQuota[name] > 0) {
        return {
          code_str: "GENERATE_1",
          code_num: 0,
          msg: "user is allowed to generate ",
        };
      } else {
        return {
          code_str: "ADD_FUND",
          code_num: 3,
          msg: "user has exhausted his gifted credits add fund to wallet to use more",
        };
      }
    }
  }
};
export const getServiceName = (sName, giftedCredits, giftedItems) => {
  if (giftedCredits == 0) {
    return sName;
  }
  var pName = "";
  let allgiftedItemsExceptStudio = giftedItems.filter((f) => f.type !== "AIStudioGen");
  allgiftedItemsExceptStudio.forEach((s) => {
    if (s.credits > 0) {
      pName = s.type;
    }
  });
  return pName;
};
export const getStudioServiceName = (userType)=>{
  if(userType=="trial"){
    return "AIStudioTrialGen"
  }else{
    return "AIStudioGen"
  }
}
export const createStudioLog = async ({
  serviceName,
  productId,
  userId,
  userType,
  currencyCode,
  paymentStatus,
  giftedCredits,
  giftedItems,
  serviceUsesCount,
  countryCode,
  modelName,
  output,
  feature,
  outputType,
  genDetails,
}) => {
  try {
    const serviceName = getStudioServiceName(userType, giftedCredits, giftedItems);
    const makeMeterBillingLogs = {
      productId,
      service: serviceName,
      method: "generateServiceLog",
      serviceUsesCount: userId=="1231465"?0:serviceUsesCount, // numc,
      feature: feature,
      productName: "design",
      fromSection: modelName,
      // fromNumber: "",
      // toNumbers: "",
      // messageType: outputType, // Model name
      // serviceType: outputType,
      serviceValue: output,
      appOwnerId: userId,
      commonUserId : userId,
    };
    // const makeMeterBillingLogs = {
    //   productId,
    //   service: sname,
    //   method: "generateServiceLog",
    //   serviceUsesCount: serviceUsesCount, // numc,
    //   feature: "design",
    //   productName: "design",
    //   fromSection: "design pro section",
    //   // fromNumber: "",
    //   // toNumbers: "",
    //   appOwnerId: userId,
    // };
    const response = await checkoutAPI(makeMeterBillingLogs);
    console.log(response, "----------makeMeterBillingLogs----------");

    if (response.data.status) {
      const creditBalance = await updateCreditBalance({ userType, userId, productId, currencyCode });
      const AllServicesCreditAndCta = getAllServicesCreditAndCta(
        userType,
        creditBalance.giftedCredits,
        creditBalance.MeterdQuota,
        countryCode,
        paymentStatus
      );
      const StudioCreditDetails = getStudioCreditDetails(creditBalance);
      const creditLogDBPayload={
        logIds:response.data.logIds||["Id not found"],
        userId,
        frontendTimeStamp:new Date().getTime(),
        ...genDetails,

      }
      let d= new Date
      console.log("CreditLog saving in db before",creditLogDBPayload,response.data, d )
      const genDetailsDbStatus= await setFirebaseDocument(`CreditLog/${genDetails?.imagegroupid}`, creditLogDBPayload, true)
      console.log("CreditLog saving in db after",genDetailsDbStatus, creditLogDBPayload,response.data, d)
      const revertSnapshotHitManual= await setFirebaseDocument(`CreditRevert/${userId}`, {logUpdate:true,timestamp:Date.now()}, true)
      if(genDetailsDbStatus){
        console.log("credit deduct and db update successfully", creditLogDBPayload, d)
      }else{
        console.log("credit deduct but db not update successfully", creditLogDBPayload, d)
      }
      return { AllServicesCreditAndCta, creditBalance, StudioCreditDetails };
    } else {
      // Handle rejection at a higher level
      // const creditBalance = await updateCreditBalance({ userType, userId, productId });
      // const AllServicesCreditAndCta = getAllServicesCreditAndCta(
      //   userType,
      //   creditBalance.giftedCredits,
      //   creditBalance.MeterdQuota,
      //   countryCode,
      //   paymentStatus
      // );

      throw new Error(`Generation not approved. Something went wrong. ${response.data.msg}`);
    }
  } catch (error) {
    // Handle other potential errors at a higher level
    throw new Error(`Error in createLog: ${error.message}`);
  }
};
export const revertedCreditUpdateLog = async ({ userType, userId, productId, currencyCode, countryCode,paymentStatus }) => {
  const creditBalance = await updateCreditBalance({ userType, userId, productId, currencyCode });
  const AllServicesCreditAndCta = getAllServicesCreditAndCta(
    userType,
    creditBalance.giftedCredits,
    creditBalance.MeterdQuota,
    countryCode,
    paymentStatus
  );
  const StudioCreditDetails = getStudioCreditDetails(creditBalance);
  return { AllServicesCreditAndCta, creditBalance, StudioCreditDetails };
};
export const createLog = async ({
  sname,
  productId,
  userId,
  userType,
  currencyCode,
  paymentStatus,
  giftedCredits,
  giftedItems,
  countryCode,
  modelName,
  output,
  feature,
  outputType,
}) => {
  try {
    const serviceName = getServiceName(sname, giftedCredits, giftedItems);
    const makeMeterBillingLogs = {
      productId,
      service: serviceName,
      method: "generateServiceLog",
      serviceUsesCount: 1, // numc,
      feature: feature,
      productName: "Design",
      fromSection: modelName,
      // fromNumber: "",
      // toNumbers: "",
      // messageType: outputType, // Model name
      // serviceType: outputType,
      serviceValue: output,
      appOwnerId: userId,
      commonUserId : userId
    };
    // const makeMeterBillingLogs = {
    //   productId,
    //   service: serviceName,
    //   method: "makeMeterBillingLogs",
    //   serviceUsesCount: 1, // numc,
    //   feature: "design",
    //   fromSection: "design pro section",
    //   fromNumber: "",
    //   toNumbers: "",
    //   appOwnerId: userId,
    // };

    const response = await checkoutAPI(makeMeterBillingLogs);
    console.log(response, "----------makeMeterBillingLogs----------");

    if (response.data.status) {
      const creditBalance = await updateCreditBalance({ userType, userId, productId, currencyCode });
      const AllServicesCreditAndCta = getAllServicesCreditAndCta(
        userType,
        creditBalance.giftedCredits,
        creditBalance.MeterdQuota,
        countryCode,
        paymentStatus
      );
      const StudioCreditDetails = getStudioCreditDetails(creditBalance);
      return { AllServicesCreditAndCta, creditBalance, StudioCreditDetails };
    } else {
      // Handle rejection at a higher level
      // const creditBalance = await updateCreditBalance({ userType, userId, productId });
      // const AllServicesCreditAndCta = getAllServicesCreditAndCta(
      //   userType,
      //   creditBalance.giftedCredits,
      //   creditBalance.MeterdQuota,
      //   countryCode,
      //   paymentStatus
      // );

      throw new Error("Generation not approved. Something went wrong.");
    }
  } catch (error) {
    // Handle other potential errors at a higher level
    throw new Error(`Error in createLog: ${error.message}`);
  }
};

export const getStudioCreditDetails = (creditBalance) => {
  let giftedStudioRemainingCredit;
  let priceList = creditBalance?.MeterBillingPrice?.meteredBillingArr[0]?.extraUsageCharges;
  let userWalletBalance = creditBalance?.userWalletBalance;
  let walletBalanceCredit = Math.floor(parseFloat(userWalletBalance) / parseFloat(priceList["AIStudioGenPrice"]).toFixed(3));
  creditBalance.giftedItems.forEach((service) => {
    if (service.type == "AIStudioGen") {
      let credit = parseInt(service.giftedItem) - parseInt(service.totalUsage);
      if (credit <= 0) {
        giftedStudioRemainingCredit = 0;
      } else {
        giftedStudioRemainingCredit = credit;
        service.credits = credit;
      }
    }
  });

  let totalRemainingCredit = giftedStudioRemainingCredit + walletBalanceCredit;
  return { totalRemainingCredit, giftedStudioRemainingCredit, walletBalanceCredit };
  // const GiftedStudioCredit =
};

// export const getStudioBtnCTAs = (StudioCreditDetails, userType, countryCode, paymentStatus) => {
//   if (StudioCreditDetails?.giftedStudioRemainingCredit >= 0) {
//     if (paymentStatus == "trial" || paymentStatus == "trialWithoutCC") {
//       if (StudioCreditDetails?.giftedStudioRemainingCredit > appConstants.allowedCreditForFree) {
//         return {
//           code_str: "GENERATE_1",
//           code_num: 0,
//           msg: "user is allowed to generate ",
//         };
//       } else {
//         return {
//           code_str: "GET_DESIGN_PRO_2_UNLOCK",
//           code_num: paymentStatus == "trialWithoutCC" ? 2 : 5,
//           msg: "trial user has exhausted his free limit upgrade now to use remaining credits",
//         };
//       }
//     }
//   } else {
//     if (StudioCreditDetails?.walletBalanceCredit > 0) {
//       return {
//         code_str: "GENERATE_1",
//         code_num: 0,
//         msg: "user is allowed to generate ",
//       };
//     } else {
//       return {
//         code_str: "ADD_FUND",
//         code_num: 3,
//         msg: "user has exhausted his gifted credits add fund to wallet to use more",
//       };
//     }
//   }

// };
export const getStudioBtnCTAs = (StudioCreditDetails, userType, countryCode, paymentStatus) => {
  if (userType == "FREE_USER") {
    if (giftedCredits > 0) {
      return {
        code_str: "GENERATE_1",
        code_num: 0,
        msg: "user is allowed to generate ",
      };
    } else {
      //   const { disableTryNowCountry, countryCode } = this.state;
      //   const disableTryNowCountry=appConstant
      const isTrialAllowed = !disableTryNowCountry.includes(countryCode);
      if (!countryCode) {
        //check and get updated country code before proceeding
      }
      console.log("isTrialAllowed", isTrialAllowed, countryCode);
      return {
        code_str: isTrialAllowed ? "GET_TRIAL" : "GET_DESIGN_PRO",
        code_num: isTrialAllowed ? 1 : 4,
        msg: "free user has exhausted free credits ",
      };
    }
  } else {
    // const paymentStatus = paymentStatus.paymentStatus;
    if (StudioCreditDetails?.giftedStudioRemainingCredit >= 0) {
      if (paymentStatus == "trial" || paymentStatus == "trialWithoutCC") {
        if (StudioCreditDetails?.giftedStudioRemainingCredit > 0) {
          return {
            code_str: "GENERATE_1",
            code_num: 0,
            msg: "user is allowed to generate ",
          };
        } else {
          return {
            code_str: "GET_DESIGN_PRO_2_UNLOCK",
            code_num: paymentStatus == "trialWithoutCC" ? 2 : 5,
            msg: "trial user has exhausted his free limit upgrade now to use remaining credits",
          };
        }
      } else {
        return {
          code_str: "GENERATE_1",
          code_num: 0,
          msg: "user is allowed to generate ",
        };
      }
    } else {
      if (StudioCreditDetails?.walletBalanceCredit > 0) {
        return {
          code_str: "GENERATE_1",
          code_num: 0,
          msg: "user is allowed to generate ",
        };
      } else {
        return {
          code_str: "ADD_FUND",
          code_num: 3,
          msg: "user has exhausted his gifted credits add fund to wallet to use more",
        };
      }
    }
  }
};

export const getStudioBtnCTAsOnNumber = (StudioCreditDetails, noOfCredittobeused, paymentStatus) => {
  
  if (StudioCreditDetails?.totalRemainingCredit >= noOfCredittobeused) {
    return {
      code_str: "GENERATE_1",
      code_num: 0,
      msg: "user is allowed to generate ",
    };
  } else {
    if (paymentStatus == "trial" || paymentStatus == "trialWithoutCC") {
      if (StudioCreditDetails?.giftedStudioRemainingCredit <= noOfCredittobeused) {
        return {
          code_str: "GET_DESIGN_PRO_2_UNLOCK",
          code_num: paymentStatus == "trialWithoutCC" ? 2 : 5,
          msg: "trial user has exhausted his free limit upgrade now to use remaining credits",
        };
      }
    }
    return {
      code_str: "ADD_FUND",
      code_num: 3,
      msg: "user has exhausted his gifted credits add fund to wallet to use more",
    };
    // if(){

    // }
  }
};

export const calculatePerServiceCredit = (activeModel, countryCode, MeterBillingPrice) => {
  const Studioprice = jsonData.Studioprice;
  if (activeModel.meteredCategory == "AiTemplate" || activeModel.meteredCategory == "AiTemplateYT" || activeModel.meteredCategory == "AiFlexBanner" ) {
    const TemplatePriceList = Studioprice.modelPrice.find((model) => model.id === `${activeModel.meteredCategory}`);
    const countryPrice = TemplatePriceList?.options[0]?.countryWisePrice.find((price) => price.countryCode === countryCode);
    if (countryPrice) {
      const totalPrice = countryPrice.marginalPrice * 1;
      const totalCredit = Math.round(totalPrice / MeterBillingPrice?.meteredBillingArr[0]?.extraUsageCharges?.AIStudioGenPrice);
      return totalCredit;
    }
  }
};