import { createSlice } from "@reduxjs/toolkit";

export const meterBillingSlice = createSlice({
  name: "meterBilling",
  initialState: {
    userDetails: null,
    paymentDetails: null,
    MeterBillingPrice: null,
    giftedCredits: null,
    MeterdQuota: null,
    userWalletBalance: null,
    AllServicesCreditAndCta: null,
    countryCode: null,
    isCreditLoaded: false,
    giftedItems: null,
    StudioCreditDetails: {
      totalRemainingCredit: 0,
      giftedStudioRemainingCredit: 0,
      walletBalanceCredit: 0,
    },
  },
  reducers: {
    updatePaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
    updateMeteredDetails: (state, action) => {
      console.log("handleMeterBilling, reducers", action.payload);
      Object.keys(action.payload).forEach((key) => {
        if (state.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      });
      state.isCreditLoaded = true;
    },
    updateStudioCreditDetails(state, action) {
      console.log("finally i am called!! ");
      state.StudioCreditDetails = { ...state.StudioCreditDetails, ...action.payload };
    },
  },
});

export const { updatePaymentDetails, updateMeteredDetails, updateStudioCreditDetails } = meterBillingSlice.actions;
