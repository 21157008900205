import { getOriginalRect, _adjustPositionV2, _onMousedown, _onScaling } from "../ImagesTemplate/CropImage copy";
let previous_canvas_clippath;
export const crop_Background = (canvas, bg) => {
  // export const CropImage = (canvas, e, bimg, boxframe, Active_imageRect) => {
  canvas = canvas;

  previous_canvas_clippath = canvas.clipPath;
  let img_ = bg;
  let sWidth = 4;

  img_.evented = true;
  img_.selectable = true;
  img_.editingScale = img_.scaleX;

  img_.setControlVisible("mtr", false);
  img_.setControlVisible("ml", false);
  img_.setControlVisible("mt", false);
  img_.setControlVisible("mr", false);
  img_.setControlVisible("mb", false);

  canvas.bringToFront(img_);

  canvas.crop_mode = !0;

  let new_c = {
    x: img_.left - img_.cropX * img_.scaleX,
    y: img_.top - img_.cropY * img_.scaleY,
  };

  // let n = e.target.getCenterPoint()
  let vc = { x: img_.left, y: img_.top };
  let v = (img_.angle || 0) + (img_.imageAngle || 0);
  let b = fabric.util.rotatePoint(new fabric.Point(new_c.x, new_c.y), vc, fabric.util.degreesToRadians(v));
  let pretop = img_.top;
  let preleft = img_.left;
  img_.set({
    top: b.y,
    left: b.x, //b.x-40,
    angle: img_.angle,
    cropY: 0,
    cropX: 0,
    selectable: !0,
    isEditing_: !0,
    width: img_._width, //- 6,
    height: img_._height, // - 6,
    setControlsVisibility: {
      mtr: false,
      ml: false,
      mr: false,
      mt: false,
      mb: false,
    },

    borderColor: "#4BD3FB",
    transparentCorners: false,
    // cornerStyle: "circle",
    // cornerSize: 12,
    // cornerColor: "green"
  });

  canvas.clipPath = null;
  canvas.setActiveObject(img_);

  let clipPath = new fabric.Rect({
    left: preleft,
    top: pretop,
    fill: "rgba(255,0,0,0.5)",
    opacity: 1,
    // originX:'center',
    // originY:'center',
    width: img_.vptwidth,
    height: img_.vptheight,
    angle: img_.angle,
    inverted: true,
    absolutePositioned: true,
    angle: img_.angle,
    lockScalingFlip: true,
  });
  // console.log(canvas.getCenter(), fabric.util.transformPoint(new fabric.Point(0, 0), canvas.viewportTransform))
  let cropMask = new fabric.Rect({
    left: 0 - canvas.viewportTransform[4] / canvas.getZoom(),
    top: 0 - canvas.viewportTransform[5] / canvas.getZoom(),
    fill: "rgb(100,100,100)",
    opacity: 0.8,
    // scaleX: canvas.viewportTransform[0],
    // scaleY: canvas.viewportTransform[3],
    width: canvas.width / canvas.getZoom(),

    height: canvas.height / canvas.getZoom(),
    clipPath: clipPath,
    selectable: false,
    id: "masking",
    lockScalingFlip: true,
  });
  let cropArea = new fabric.Rect({
    // padding: 1,
    // rx: 5,
    // ry: 5,
    left: preleft,
    top: pretop,
    fill: "",
    width: img_.vptwidth - sWidth,
    height: img_.vptheight - sWidth,
    // scaleX: Active_imageRect.original_img.scaleX,
    // scaleY: Active_imageRect.original_img.scaleY,
    absolutePositioned: true,
    id: "_cropArea",
    lockMovementX: true,
    lockMovementY: true,
    perPixelTargetFind: true,
    stroke: "#4BD3FB",
    // originX:'center',
    // originY:'center',
    strokeWidth: sWidth,
    selectable: false,
    evented: false,
    strokeUniform: true,
    noScaleCache: false,
    lockScalingFlip: true,
    angle: img_.angle,
    transparentCorners: false,
    lockUniScaling: true,
    borderColor: "black",
    cornerColor: "black",
    cornerSize: 10,
    cornerStyle: "circle",
    pimg: img_,
    cropMask: cropMask,
    centeredRotation: true,

    // dirty: true,
  });
  // if (img_.width > img_.vptwidth) {
  //     cropArea.width = img_.vptwidth;
  //     clipPath.width = img_.vptwidth;
  // }
  // if (img_.height > img_.vptheight) {
  //     cropArea.height = img_.vptheight;
  // }
  img_.set({
    cropArea: cropArea,
    clipChild: cropArea,
    // editingAngle: img_.angle,
  });

  cropArea.setControlsVisibility({ mtr: false });

  canvas.controlsAboveOverlay = true;
  canvas.overlayImage = cropMask;

  canvas.add(cropArea);
  // canvas.bringToFront(img_)
  canvas.setActiveObject(img_);
  canvas.bringToFront(cropArea);
  canvas.renderAll();
  img_.on("moving", (e) => {
    _adjustPositionV2(e);
  });
  img_.on("scaling", (e) => {
    _onScaling(e);
  });
  img_.on("mousedown", (e) => {
    _onMousedown(e);
    canvas.renderAll();
  });

  img_.setCoords();
  canvas.renderAll();
};

export const Restore_background = (canvas, bg, usedFor) => {
  let cropArea = bg.cropArea;
  let imageRect;
  let img_ = bg;
  let cREct = getOriginalRect(cropArea, cropArea);
  let iRect = getOriginalRect(img_, cropArea);
  img_.set({
    top: cropArea.top,
    left: cropArea.left,
    // angle: cropArea.angle,
    angle: cropArea.angle !== undefined ? cropArea.angle : 0,
    cropY: (cREct.y - iRect.y) / img_.scaleY,
    cropX: (cREct.x - iRect.x) / img_.scaleX,
    width: cropArea.getScaledWidth() / img_.scaleX,
    // clipPath: boxframe,
    height: cropArea.getScaledHeight() / img_.scaleY,
    // isCropped: "cropped",
    selectable: false,

    stroke: null,
    strokeWidth: null,
    cropArea: null,
    clipChild: null,
  });
  canvas.remove(cropArea);
  canvas.clipPath = previous_canvas_clippath;
  previous_canvas_clippath = null;
  canvas.renderAll();
  canvas.moveTo(img_, 0);
  canvas.overlayImage = null;
  img_.setCoords();
  bg.setCoords();
  canvas.crop_mode = false;
  if (usedFor !== "selectionUpdate") {
    canvas.discardActiveObject();
  }

  canvas.renderAll();
  canvas.savestate();
};
