import Cookies from "universal-cookie";
import { dycKeyData } from "./AuthService"; // Import from the correct path

const cookies = new Cookies();

/**
 * Sets a cookie with the provided key and value.
 * @param {string} key - The key for the cookie.
 * @param {string} value - The value to be stored in the cookie.
 */
export const setCookies = (key = "", value = "") => {
  try {
    if (key) {
      // Set the cookie with base64-encoded value
      cookies.set(key, btoa(value), { path: "/" });
    }
  } catch (error) {
    console.error("Error setting cookie:", error);
  }
};

/**
 * Retrieves the value of a cookie with the provided key.
 * @param {string} key - The key of the cookie to retrieve.
 * @returns {string} - The decoded value of the cookie.
 */
export const getCookies = (key = "") => {
  try {
    let value = "";
    if (key) {
      const encValue = cookies.get(key);
      if (key === "keydata") {
        // Decode using dycKeyData for specific key
        value = encValue ? dycKeyData("decrypt", encValue) : "";
      } else {
        // Decode using base64 for other keys
        value = encValue ? atob(encValue) : "";
      }
    }
    return value;
  } catch (error) {
    console.error("Error getting cookie:", error);
    // Consider handling this error case appropriately, e.g., redirecting to a login page
  }
};

/**
 * Removes a cookie with the provided key.
 * @param {string} key - The key of the cookie to be removed.
 */
export const removeCookies = (key = "") => {
  if (key) {
    // Remove the cookie
    cookies.remove(key);
  }
};
/**
 * Removes all cookie from browser.
 */
export const removeAllCookies = () => {
  //get all cookies
  const cookiesData = cookies.getAll();

  // iterate through each cookie and remove it
  for (const key in cookiesData) {
    cookies.remove(key);
  }

  // return after removing all cookies
  return;
};
