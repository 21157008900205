import { createSlice } from "@reduxjs/toolkit";

export const projct_related = createSlice({
  name: "project",
  initialState: {
    projectName: "Untitled Designeeee",
    pageName: null,
    customCanvas: {
      width: null,
      height: null,
      unit:"px",
      unitWidth:null,
      unitHeight:null,
    },
    fullScreenLoader:false
    
    // toolbar_pageName: null,
  },
  reducers: {
    update_pageName: (state, action) => {
      return {
        ...state,
        pageName: action.payload.value,
      };
    },
    update_project_name: (state, action) => {
      return {
        ...state,
        pageName: action.payload.value,
      };
    },
    update_custom_canvas: (state, action) => {
      const { width, height, unit, unitWidth, unitHeight } = action.payload;
    
      return {
        ...state,
        customCanvas: {
          width: width !== undefined ? width : state.customCanvas.width,
          height: height !== undefined ? height : state.customCanvas.height,
          unit: unit !== undefined ? unit : state.customCanvas.unit,
          unitWidth: unitWidth !== undefined ? unitWidth : state.customCanvas.unitWidth,
          unitHeight: unitHeight !== undefined ? unitHeight : state.customCanvas.unitHeight,
        },
      };
    },
    update_Full_screen_loader:(state,action)=>{
      return {
        ...state,
        fullScreenLoader: action.payload.value,
      };
    }
    // update_custom_canvas_unit: (state, action) => {
    //   // const { width, height } = action.payload.size;
    //   return {
    //     ...state,
    //     customCanvasSizeInUnit: {
    //       size:action.payload.size,
    //       unit:action.payload.unit?.id
    //     },
    //   };
    // },
  },
});
export const { update_pageName, update_custom_canvas, update_project_name,update_Full_screen_loader } = projct_related.actions;

export const toolBar_slice = createSlice({
  name: "toolBar",
  initialState: {
    toolbarname: ["font List"],
  },
  reducers: {
    updateToolbardata: (state, action) => {
      return {
        ...state,
        toolbarname: action.payload.value,
      };
    },
  },
});
export const { updateToolbardata } = toolBar_slice.actions;
