import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getSession } from "./utils/AuthService";

const PrivateRoute = ({ isAuthenticated, propsuserId }) => {
  const { id: currentUserId } = getSession();
  const isUserChanged = parseInt(currentUserId) !== parseInt(propsuserId);

  if (isAuthenticated && !isUserChanged) {
    return <Outlet />;
  } else {
    const userId = getSession().id;
    sessionStorage.getItem("pathnameSDKSearchQ") ||
      sessionStorage.setItem(
        "pathnameSDKSearchQ",
        btoa(window.location.search)
      );

    return (
      <Navigate
        replace
        to={`/signup${atob(sessionStorage.getItem("pathnameSDKSearchQ"))}`}
      />
    );
  }
};

export default PrivateRoute;
