import md5 from "md5";
import * as Realm from "realm-web";
import { REALM_ID } from "../utils/AppConstant";
import { getAuthCookies } from "../utils/AuthService";
import * as appConstants from "../utils/AppConstant";
import { commonApi } from "../utils/factory";

// Initialize the Realm app with your app ID
const app = new Realm.App({ id: REALM_ID });

/**
 * Retrieves session data from Realm using the user's authentication credentials.
 * @returns {Object|null} userData - The user's session data.
 */
// OLD REALM (BETA LIVE)
// export const getRealmSession = async () => {
//   try {
//     console.time("realm session timing")
//     // Check if there is a logged-in user; if not, log in using API key
//     let user = app.currentUser;
//     if (!user) {
//       const credentials = Realm.Credentials.apiKey(REALM_KEY);
//       user = await app.logIn(credentials);
//     }

//     // Get the filename from authentication cookies
//     const fileName = getAuthCookies("APPYID");

//     // Hash the filename using MD5 and retrieve session data from Realm
//     const realmData = await user.functions.getSessionData(md5(fileName), "owner");

//     // Parse and extract the user data from the response
//     let userData = "";
//     if (realmData && realmData.userdata) {
//       const parsedData = JSON.parse(realmData.userdata);
//       userData = parsedData.userdata || "";
//     }
//     console.timeEnd("realm session timing")
//     return userData;
//   } catch (error) {
//     console.error("Error while retrieving session data from realm:", error);
//     throw error;
//   }
// };
export const getRealmSession = async () => {
  try {
    console.time("realm session timing");
    const file_name = getAuthCookies("APPYID");

    if (!file_name) {
      throw new Error("APPYID from cookies is undefined or null");
    }
    const res = await commonApi({
      API_BASE_URL: appConstants.LOGIN_BACKEND_URL,
      apiEndpoint: appConstants.REALM_API_ENDPOINT,
      method: "POST",
      payload: { file_name },
    });

    // Handle response and extract user data
    if (res.status && res.data) {
      if (res.data.status === 200 && res.data.userdata) {
        console.timeEnd("realm session timing");
        return res.data.userdata;
      } else {
        throw new Error(`Unexpected RealmAPI response status: ${res.data.status}`);
      }
    } else {
      throw new Error("Failed to fetch user details from the API");
    }
  } catch (error) {
    console.error("Error while retrieving session data from realm:", error);
    const pathnameSDK = sessionStorage.getItem("pathnameSDK");
    const pathnameSDKSearchQ = sessionStorage.getItem("pathnameSDKSearchQ");
    const pathname = pathnameSDK ? atob(pathnameSDK) : "/";
    const pathnameSearchQ = pathnameSDKSearchQ ? atob(pathnameSDKSearchQ) : "";
    //throw error; // Exit after max retries
    const current_url = window.location.href;
    const redirectUrl =
        current_url.includes("/signup") ? `${appConstants.SIGNUP_URL}${pathname}${pathnameSearchQ}` : `${appConstants.LOGIN_URL}${pathname}${pathnameSearchQ}`;
      // const redirectUrl = path === "/signup" ? `${appConstants.SIGNUP_URL}${pathname}` : `${appConstants.LOGIN_URL}`;
      // Perform a URL replace to redirect the user
      window.location.replace(redirectUrl);
  }
};