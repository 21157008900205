import React, { useEffect, useState } from "react";
import { getCookies } from "../../utils/Cookies";
import { SIGNUP_URL, USER_TYPE } from "../../utils/AppConstant";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getSession } from "../../utils/AuthService";
import "../../App.css";

const PaymentFail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [messageHead, setMessageHead] = useState("Sorry, your payment failed!");
  const [messageBody, setMessageBody] = useState("");
  useEffect(() => {
    const userType = getCookies("userType");
    const userData = getSession();

    if (userType === USER_TYPE.PAID) {
      navigate(`/`);
      return;
    }

    if (!userData) {
      const pathnameSDK = sessionStorage.getItem("pathnameSDK");
      const pathname = pathnameSDK ? atob(pathnameSDK) : "/";
      const redirectUrl = `${SIGNUP_URL}&${pathname}`;
      window.location.replace(redirectUrl);
      return;
    }
    let { type } = Object.fromEntries([...searchParams]);
    if (type && type === "tryNow") {
      setMessageHead("");
      setMessageBody("Something went wrong, kindly try again to start your free trial.");
    }
  }, []);

  const showPaymentPlan = () => {
    navigate("/payment-plan");
    return;
  };

  return (
    <>
      <div className="success-wrapper">
        <div className="container">
          <div className="col-md-12">
            <div className="msgBox">
              <img alt="" src="/payment/failed.svg" />
              <h2>
                Transaction failed! <br /> {messageHead}
              </h2>
              <h5>{messageBody}</h5>
              <a className="cursor-pointer btn btn-primary" onClick={() => showPaymentPlan()}>
                Try Again
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFail;
