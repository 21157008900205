import React from "react";
import "./LoadingMain.css";

export default function LoadingMain() {
  return (
    <div className="newLoader1" id="templateload">
      <div className="loaderNew">
        <div className="spinner_new">
          <div className="double-bouncenew1"></div>
          <div className="double-bouncenew2"></div>
        </div>
        <span className="loadText" id="loadText6">
          Creating Package...
        </span>
      </div>
    </div>
  );
}
