import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    designSDKLoginStatus: null,
    userInfo: null,
    realmData: null,
    userProductDetails: { userId: null, productId: null, userType: null, paymentStatus: null, planPeriod: null, countryCode: null, deviceLocation:null,paymentDetailsCountryCode:null },
    projectName: null,
    projectLength: "",
    currentUserRole: { role: "", isAdmin: false, isDesigner: false, isDeveloper: false, userEmail: null },
    creditPolicyUpdatePopup:false
  },
  reducers: {
    updateDesignSDKLoginStatus: (state, action) => {
      // console.log("update_img_status----....", action.payload)
      return {
        ...state,
        // changable_img_status: action.payload
        designSDKLoginStatus: action.payload,
      };
    },
    updateUserInfoReducer: (state, action) => {
      console.log("update_user_info ", action.payload);
      return {
        ...state,
        userInfo: action.payload,
      };
    },
    updateRealmData: (state, action) => {
      return {
        ...state,
        realmData: action.payload,
      };
    },
    updateUserProductDetails: (state, action) => {
      state.userProductDetails = { ...state.userProductDetails, ...action.payload };
    },
    updateProjectName: (state, action) => {
      return {
        ...state,
        projectName: action.payload,
      };
    },
    updateProjecLength: (state, action) => {
      return {
        ...state,
        projectLength: action.payload,
      };
    },
    updateCurrentUserRole: (state, action) => {
      state.currentUserRole = { ...state.currentUserRole, ...action.payload };
    },
    updateCreditPolicyUpdatePopup: (state, action) => {
      state.creditPolicyUpdatePopup = action.payload;
    },
  },
});
export const {
  updateDesignSDKLoginStatus,
  updateUserInfoReducer,
  updateRealmData,
  updateUserProductDetails,
  updateProjectName,
  updateProjecLength,
  updateCurrentUserRole,
  updateCreditPolicyUpdatePopup
} = userSlice.actions;
