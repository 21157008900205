import { createSlice } from "@reduxjs/toolkit";
import JSONDATA from "../component/ImagesTemplate/ImageCategories.json";
import { calculatePerServiceCredit, getStudioBtnCTAsOnNumber } from "../utils/PaymentService";

export const aiToolsSlice = createSlice({
  name: "aiTools",
  initialState: {
    activeAiTool: null,
    visibilityPop: true,
    visibilitySideBar:false,
    AIImageGenerations: {
      promptValue: "",
      charRemaining: 1000,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      aspectRatio: { aspectRatioName: "Square", aspectRatioSize: "1024x1024", aspectRatioWidth: 1024, aspectRatioHeight: 1024 },
      showRecent: false,
      isGenerating: false,
    },
    AILogoGenerations: {
      CompanyPrompt: "",
      promptValue: "",
      charRemaining: 1000,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      showRecent: false,
      isGenerating: false,
    },
    AITemplateGenerations: {
      promptValue: "",
      negativePromptValue: JSONDATA.Aiimage.filter(d=>d.id=="ai_template_maker")[0]?.negativePromptPresets[0].value,
      charRemaining: 1000,
      negativeCharRemaining:1000-JSONDATA.Aiimage.filter(d=>d.id=="ai_template_maker")[0]?.negativePromptPresets[0].value.length,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      showRecent: false,
      isGenerating: false,
      AiTemplateJSONDetails:JSONDATA.Aiimage.filter(d=>d.id=="ai_template_maker")[0],
      countryCode:null,
      MeterBillingPrice:null,
      paymentStatus:null,
      StudioCreditDetails:null,
    },
    AIFlexBannerGenerations: {
      promptValue: "",
      charRemaining: 1000,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      uploadingError: {  errorInUploading: false, errorUploadingText: "" },
      showRecent: false,
      isGenerating: false,
      advanceSettingStatus:false,
      isLogoUploading:false,
      uploadedLogo:null,
      fileName:null,
      addressValue:"",
      upiValue:"",
      websiteValue:"",
      qrValueB:"",
      addressCharRemaining:100,
      AiTemplateJSONDetails:JSONDATA.Aiimage.filter(d=>d.id=="ai_flex_banner")[0],
      countryCode:null,
      MeterBillingPrice:null,
      paymentStatus:null,
      StudioCreditDetails:null,
    },
    AIMusicGenerations: { numCredits: null, cta_data: null, ctaDetails: null },
    AIVoiceOverGenerations: { numCredits: null, cta_data: null, ctaDetails: null },
    AIVideoGenerations: { numCredits: null, cta_data: null, ctaDetails: null },
    AIQRCodeGenerations: {
      activeQRTab: "AIQR",
      standardQrPrompt: "",
      AIQRTextPrompt: "",
      AIQRInputPrompt: "",
      charRemaining: 1000,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      showRecent: false,
      isStandardGenerating: false,
      isGenerating: false,
      error: { errorInGeneration: false, errorText: "" },
      standardQrError: { standardQrerrorInGeneration: false, standardQrerrorText: "" },
    },
    AIEmojiGenerations: {
      promptValue: "",
      charRemaining: 1000,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      showRecent: false,
      isGenerating: false,
    },
    AIAvatarGenerations: {
      uploadedface: null,
      uploadedReface: null,
      activeSelectedAvatar: null,
      sampleAvatarData: null,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      NSFWerror: { NSFWDetected: false, NSFWerrorText: "" },
      NSFWerrorReface: { NSFWDetectedReface: false, NSFWerrorTextReface: "" },
      // submissionError: { errorInsubmit: true, errorText: "" },
      showRecent: false,
      isGenerating: false,
    },
    AIYTGenerations: {
      promptValue: "",
      charRemaining: 1000,
      negativePromptValue: JSONDATA.Aiimage.filter(d=>d.id=="ai_youtube_thumbnail_maker")[0]?.negativePromptPresets[0].value,
      negativeCharRemaining:1000-JSONDATA.Aiimage.filter(d=>d.id=="ai_youtube_thumbnail_maker")[0]?.negativePromptPresets[0].value.length,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      showRecent: false,
      isGenerating: false,
      AiTemplateJSONDetails:JSONDATA.Aiimage.filter(d=>d.id=="ai_youtube_thumbnail_maker")[0],
      countryCode:null,
      MeterBillingPrice:null,
      paymentStatus:null,
      StudioCreditDetails:null,
    },
    AIAdsGenerations: {
      // selectedLayout: JSONDATA.YTLayout[0],
      inputImage: null,
      promptValue: "",
      charRemaining: 1000,
      isFirst: true,
      numCredits: null,
      cta_data: null,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      error: { errorInGeneration: false, errorText: "" },
      Uploaderror: { errorInUploading: false, UploadingerrorText: "" },
      showRecent: false,
      isGenerating: false,
    },
    dalleGenerations: {
      promptValue: "",
      //   quality: null,
      //   selectedStyle: null,
      charRemaining: 1000,
      numberOfGenerations: 1,
      aspectRatio: null, //activeModel?.data?.aspectRatioPresets[0],
      // error: { errorInGeneration: false, errorText: "" },
      isGenerating: false,
      numCredits: null,
      cta_data: null,
      creditDeductionNumber: 0,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    },
    SDXLGenerations: {
      videoTutorial:{},
      promptValue: "",
      charRemaining: 1000,
      selectedStyle: null,
      uploadingLoader: false,
      uploadedFile: null,
      videoFrameUrl: null,
      avatarUploadingLoader: false,
      avatarUploadedFace:null,
      numberOfGenerations: 1,
      error: { errorInGeneration: false, errorText: "" },
      isGenerating: false,
      numCredits: null,
      cta_data: null,
      creditDeductionNumber: 0,
      isAdvanceSettingOpen:false,
      LoRAWeightFlawless:92,
      ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
      headingTextFlawless: "",
      subheadingTextFlawless: "",
      bodyTextFlawless: "",
      headingFontStyleFlawless: {name:"None",key:"None","family": "Montserrat:400","cssfamily": "Montserrat, sans-serif","weight": "400", style:""},
      subheadingFontStyleFlawless: {name:"None",key:"None","family": "Montserrat:400","cssfamily": "Montserrat, sans-serif","weight": "400",style:""},
      bodyFontStyleFlawless: {name:"None",key:"None","family": "Montserrat:400","cssfamily": "Montserrat, sans-serif","weight": "400",style:""}
    },
    commonForAllPlaygroundModel: {
      promptValue: "",
      charRemaining: 1000,
      imagegroupids: "",
    },
    generationDetails:{
      sholudBtnShow:false,
      imagegroupid:null,
      uniqueImageIds:[],
      generatingInterface:null
    }
  },
  reducers: {
    updateActveAiTool(state, action) {
      state.activeAiTool = action.payload;
    },
    updateAILogoGenerations(state, action) {
      state.AILogoGenerations = { ...state.AILogoGenerations, ...action.payload };
    },
    updateAiTextToImage(state, action) {
      state.AIImageGenerations = { ...state.AIImageGenerations, ...action.payload };
    },
    updateAiTemplateMakerEditor(state, action) {
      state.AITemplateGenerations = { ...state.AITemplateGenerations, ...action.payload };
    },
    updateAIFlexBannerGenerations(state, action) {
      state.AIFlexBannerGenerations = { ...state.AIFlexBannerGenerations, ...action.payload };
    },
    updateAIQRCodeGenerations(state, action) {
      state.AIQRCodeGenerations = { ...state.AIQRCodeGenerations, ...action.payload };
    },
    updateAIEmojiGenerations(state, action) {
      state.AIEmojiGenerations = { ...state.AIEmojiGenerations, ...action.payload };
    },
    updateAIAvatarGenerations(state, action) {
      state.AIAvatarGenerations = { ...state.AIAvatarGenerations, ...action.payload };
    },
    updateAIYTGenerations(state, action) {
      state.AIYTGenerations = { ...state.AIYTGenerations, ...action.payload };
    },
    updateAIAdsGenerations(state, action) {
      state.AIAdsGenerations = { ...state.AIAdsGenerations, ...action.payload };
    },
    updateDalleGenerations(state, action) {
      state.dalleGenerations = { ...state.dalleGenerations, ...action.payload };
    },
    updateSDXLGenerations(state, action) {
console.log("actionaction",action.payload);

      state.SDXLGenerations = { ...state.SDXLGenerations, ...action.payload };
    },

    updatePopUp(state, action) {
      console.log(state, action,"sendDatatoParent-1")
      state.visibilityPop = action.payload.value;
    },
    updateVideoTutorial(state, action) {
      console.log(state, action,"updateVideoTutorial")
      state.SDXLGenerations.videoTutorial = action.payload.videoTutorial;
    },
    updateSideBar(state, action) {
      console.log(state, action,"sendDatatoParent-2")
      state.visibilitySideBar = action.payload.value;
    },
    updateGenerationDetails(state, action) {
      state.generationDetails = { ...state.generationDetails, ...action.payload };
    },
    updateCommonForAllPlaygroundModel(state, action) {
      state.commonForAllPlaygroundModel = { ...state.commonForAllPlaygroundModel, ...action.payload };
    },
    updateCreditsAndCta(state, action) {
      console.log("handleMeterBilling cdata", action.payload);

      const ctaDetailsMapping = {
        0: { isPaymentRequired: false, btn_cta: "Generate" }, //generation allowed
        1: { isPaymentRequired: true, btn_cta: "Start Free Trial" }, // start free trial
        2: { isPaymentRequired: true, btn_cta: "Upgrade to unlock credits" }, // upgrade to unlock credits by checkout
        3: { isPaymentRequired: true, btn_cta: "Add Funds to Wallet" }, //add fund
        4: { isPaymentRequired: true, btn_cta: "Subscribe" }, // buy now or subscribe
        5: { isPaymentRequired: true, btn_cta: "Upgrade to unlock credits" }, //upgrade to unlock by API
      };

      const { AIImageGenerations } = action.payload;
      action.payload = {
        ...action.payload,
        AIQRCodeGenerations: AIImageGenerations,
        AIEmojiGenerations: AIImageGenerations,
        AIAvatarGenerations: AIImageGenerations, 
        AIAdsGenerations: AIImageGenerations,
        dalleGenerations: action.payload?.AIStudioGen,
        SDXLGenerations: action.payload?.AIStudioGen,
        AITemplateGenerations: action.payload?.AIStudioGen,
        AIYTGenerations: action.payload?.AIStudioGen,
        AIFlexBannerGenerations: action.payload?.AIStudioGen,
      };
      
      Object.entries(action.payload).forEach(([key, data]) => {
        if (key === "AITemplateGenerations" || key==="AIYTGenerations" || key==="AIFlexBannerGenerations") {
          console.log("getting additional info", state[key]?.countryCode);
          const creditDeductionNumber = calculatePerServiceCredit(
            JSONDATA.Aiimage.filter((d) => d.id ===( key==="AIYTGenerations"?"ai_youtube_thumbnail_maker":key==="AIFlexBannerGenerations"?"ai_flex_banner":"ai_template_maker"))[0],
            state[key]?.countryCode,
            state[key]?.MeterBillingPrice
          );
          const CTA = getStudioBtnCTAsOnNumber(state[key]?.StudioCreditDetails, creditDeductionNumber, state[key]?.paymentStatus);
          const ctaDetails = ctaDetailsMapping[CTA.code_num] || {};
          // console.log("credit deduction number", creditDeductionNumber,btn);
          state[key] = { ...state[key], numCredits: data.creditRemaining, cta_data: CTA, ctaDetails: ctaDetails };
        } else {
          const cta_data = data.CTA;
          const ctaDetails = ctaDetailsMapping[cta_data.code_num] || {};
          state[key] = { ...state[key], numCredits: data.creditRemaining, cta_data: cta_data, ctaDetails: ctaDetails };
        }

        // this.setState({
        //   numCredits: cdata.creditBalance,
        //   cta_data: cta_data,
        //   isCreditDataLoaded: true,
        //   isFreeUser: false,
        // });
      });
    },
    updateStudioCreditsAndCta(state, action) {
      const ctaDetailsMapping = {
        0: { isPaymentRequired: false, btn_cta: "Generate" }, //generation allowed
        1: { isPaymentRequired: true, btn_cta: "Start Free Trial" }, // start free trial
        2: { isPaymentRequired: true, btn_cta: "Upgrade to unlock credits" }, // upgrade to unlock credits by checkout
        3: { isPaymentRequired: true, btn_cta: "Add Funds to Wallet" }, //add fund
        4: { isPaymentRequired: true, btn_cta: "Subscribe" }, // buy now or subscribe
        5: { isPaymentRequired: true, btn_cta: "Upgrade to unlock credits" }, //upgrade to unlock by API
      };

      const ctaDetails = ctaDetailsMapping[action.payload.code_num] || {};
      state.dalleGenerations = { ...state.dalleGenerations, cta_data: action.payload, ctaDetails: ctaDetails };
      state.SDXLGenerations = { ...state.SDXLGenerations, cta_data: action.payload, ctaDetails: ctaDetails };
    },
    resetState(state, action) {
      const initialState = getInitialState();
      Object.assign(state, initialState);
    },
  },
});

export const {
  updateActveAiTool,
  updateAiTextToImage,
  updateCreditsAndCta,
  updateAILogoGenerations,
  updateAiTemplateMakerEditor,
  updateAIQRCodeGenerations,
  updateAIEmojiGenerations,
  updateAIAvatarGenerations,
  updateAIYTGenerations,
  updateAIAdsGenerations,
  updateDalleGenerations,
  updateSDXLGenerations,
  updatePopUp,
  updateVideoTutorial,
  updateSideBar,
  updateCommonForAllPlaygroundModel,
  updateStudioCreditsAndCta,
  resetState,
  updateGenerationDetails,
  updateAIFlexBannerGenerations
} = aiToolsSlice.actions;

export const getInitialState = () => ({
  activeAiTool: null,
  visibilityPop,
  AIImageGenerations: {
    promptValue: "",
    charRemaining: 1000,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    error: { errorInGeneration: false, errorText: "" },
    showRecent: false,
    isGenerating: false,
  },
  AILogoGenerations: {
    CompanyPrompt: "",
    promptValue: "",
    charRemaining: 1000,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    error: { errorInGeneration: false, errorText: "" },
    showRecent: false,
    isGenerating: false,
  },
  AIMusicGenerations: { numCredits: null, cta_data: null, ctaDetails: null },
  AIVoiceOverGenerations: { numCredits: null, cta_data: null, ctaDetails: null },
  AIVideoGenerations: { numCredits: null, cta_data: null, ctaDetails: null },
  AIQRCodeGenerations: {
    activeQRTab: "AIQR",
    standardQrPrompt: "",
    AIQRTextPrompt: "",
    AIQRInputPrompt: "",
    charRemaining: 1000,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    showRecent: false,
    isStandardGenerating: false,
    isGenerating: false,
    error: { errorInGeneration: false, errorText: "" },
    standardQrError: { standardQrerrorInGeneration: false, standardQrerrorText: "" },
  },
  AIEmojiGenerations: {
    promptValue: "",
    charRemaining: 1000,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    error: { errorInGeneration: false, errorText: "" },
    showRecent: false,
    isGenerating: false,
  },
  AIAvatarGenerations: {
    uploadedface: null,
    uploadedReface: null,
    activeSelectedAvatar: null,
    sampleAvatarData: null,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    error: { errorInGeneration: false, errorText: "" },
    NSFWerror: { NSFWDetected: false, NSFWerrorText: "" },
    NSFWerrorReface: { NSFWDetectedReface: false, NSFWerrorTextReface: "" },
    // submissionError: { errorInsubmit: true, errorText: "" },
    showRecent: false,
    isGenerating: false,
  },
  AIYTGenerations: {
    selectedLayout: JSONDATA.YTLayout[0],
    inputImage: null,
    promptValue: "",
    charRemaining: 1000,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    error: { errorInGeneration: false, errorText: "" },
    Uploaderror: { errorInUploading: false, UploadingerrorText: "" },
    showRecent: false,
    isGenerating: false,
  },
  AIAdsGenerations: {
    // selectedLayout: JSONDATA.YTLayout[0],
    inputImage: null,
    promptValue: "",
    charRemaining: 1000,
    isFirst: true,
    numCredits: null,
    cta_data: null,
    ctaDetails: { isPaymentRequired: null, btn_cta: "null" },
    error: { errorInGeneration: false, errorText: "" },
    Uploaderror: { errorInUploading: false, UploadingerrorText: "" },
    showRecent: false,
    isGenerating: false,
  },
});


export const AITemplateGenerationsState= {
  promptValue: "",
  negativePromptValue: JSONDATA.Aiimage.filter(d=>d.id=="ai_template_maker")[0]?.negativePromptPresets[0].value,
  charRemaining: 1000,
  negativeCharRemaining:1000-JSONDATA.Aiimage.filter(d=>d.id=="ai_template_maker")[0]?.negativePromptPresets[0].value.length,
  isFirst: true,
  error: { errorInGeneration: false, errorText: "" },
  showRecent: false,
  isGenerating: false,
  AiTemplateJSONDetails:JSONDATA.Aiimage.filter(d=>d.id=="ai_template_maker")[0],
}
export const AIFlexBaneerState = {
  promptValue: "",
  charRemaining: 1000,
  isFirst: true,
  error: { errorInGeneration: false, errorText: "" },
  uploadingError: { errorInUploading: false, errorUploadingText: "" },
  showRecent: false,
  isGenerating: false,
  advanceSettingStatus: false,
  isLogoUploading: false,
  uploadedLogo: null,
  fileName:null,
  addressValue: "",
  upiValue: "",
  websiteValue: "",
  qrValueB:"",
  addressCharRemaining: 100,
};
