import { createSlice } from "@reduxjs/toolkit";

export const template_slice = createSlice({
    name: "template",
    initialState: {
        template_data: {
            isTemplatePresent: false,
            template_details: null,
        },
        changable_img_status: !1,
        temp_imgect_id: null,
    },
    reducers: {
        update_img_status: (state, action) => {
            // console.log("update_img_status----....", action.payload)
            return {
                ...state,
                // changable_img_status: action.payload
                changable_img_status: action.payload
            }
        },
        changable_imgRect_id: (state, action) => {
            // console.log("changable_imgRect_id", action.payload)
            return {
                ...state,
                temp_imgect_id: action.payload
            }
        },
        update_template_data: (state, action) => {
            return {
                ...state,
                template_data: {
                    ...state.template_data,
                    isTemplatePresent: action.payload.isTemplatePresent,
                    template_details: action.payload.template_details
                },
            }
        }
    }
})
export const { update_img_status, changable_imgRect_id, update_template_data } = template_slice.actions