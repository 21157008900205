import React from "react";
import { updateCreditPolicyUpdatePopup } from "../../../Reducer/User_Slicer";
import { useDispatch } from "react-redux";
import { updateFirebaseDocument } from "../../../helper/sdk_factory";
import { getCookies } from "../../../utils/Cookies";

const CreditPolicyUpdate = ({showCreditPolicyUpdatePopup}) => {
    const dispatch = useDispatch()
    const handleCreditPolicyUpdateBtn = async()=>{
        const userId= getCookies("userId")
        dispatch(updateCreditPolicyUpdatePopup(false))
        await updateFirebaseDocument(`paymentDetails/${userId}`, {isCreditPolicyUpdatePoupShow:true})
    }
  return (
    <div
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
      className={`modal creditpolicy_popup fade ${showCreditPolicyUpdatePopup ? "show d-block" : ""} `}
      style={{ backgroundColor: `rgb(0 0 0 / 40%)`, backdropFilter: "blur(3px)"}}
    >
      <div className="modal-dialog modal-dialog-centered modal-sm creditpolicy_wrp">
        <div className={`modal-content`}>
          <div className="modal-body text-center creditpolicy_body">
            <div className="flex justify-center">
            <img src="/creditPolicyUpdateImg.svg"/>
            </div>
            <h5 className="credit_policy_heading">Credit Policy Update</h5>
            <p className="creditpolicy_text">We have recently updated our credit policy, which may result in some changes to how credits appear in your account. Rest assured, there’s no need for concern.</p>
            <p className="creditpolicy_text">For detailed information, please visit our updated <a href="https://www.appypie.com/design/pricing-plan" target="_blank" >pricing page</a> or reach out to our support team – <a href="mailto:support@appypiellp.com" >support@appypiellp.com</a> we’re here to help!</p>
            <p className="creditpolicy_text">Thank you for your understanding and continued support.</p>
            <button className="btn btn-primary creditpolicy_bt" onClick={()=>{handleCreditPolicyUpdateBtn()}}>Got it!</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditPolicyUpdate;
