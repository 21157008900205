import {
  FieldValue,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
// import firebase from "firebase/app";
// import "firebase/firestore";
import { getCookies } from "../utils/Cookies";
import { PROJECT_STATUS, Python_FUNCTION_URL } from "../utils/AppConstant";
import { aIYTcanvasDataURL, getRandomID } from "../Reducer/Canvas_Slicer";
import { getPostData, setUserProjectUpdated } from "../utils/factory";
import FirebaseServices from "../firebase";
import { getDownloadURL, ref, uploadBytes,deleteObject } from "firebase/storage";
import "firebase/compat/firestore";
import { updateProjecLength } from "../Reducer/User_Slicer";
import { useDispatch } from "react-redux";
// const dispatch = useDispatch();
const { db, storage } = FirebaseServices;

import EXIF from "exif-js";
import imageCompression from "browser-image-compression";

import heic2any from "heic2any";
import piexif from "piexifjs";

/**
 * Updates the user's location data in the database.
 * @param {Object} payload - The data to update about user's location i.e {ipAddress:"",deviceUsed:""}.
 * @returns {Promise<boolean>} - Returns a promise indicating the success of the update.
 */
// export const updateUserLocation = async (payload = {}) => {
//   try {
//     // Get the user's ID from cookies
//     const userId = getCookies("userId");

//     // Update the user's location data in the database
//     await updateDoc(doc(db, `userData/${userId}`), payload);
//     return true; // Indicate successful update
//   } catch (error) {
//     console.error("Error saving location", error);
//     return false; // Indicate failed update
//   }
// };
export const updateUserLocation = (payload = {}) => {
  // Get the user's ID from cookies
  const userId = getCookies("userId");

  // Update the user's location data in the database using .then and .catch
  return updateDoc(doc(db, `userData/${userId}`), payload)
    .then(() => {
      console.log("Location updated successfully");
      return true; // Indicate successful update
    })
    .catch((error) => {
      console.error("Error saving location", error);
      return false; // Indicate failed update
    });
};

/**
 * Fetches project data by ID.
 * @param {string} projectId - The ID of the project to fetch.
 * @returns {Object} - Project data.
 */
export const getProjectById = async (projectId) => {
  try {
    const userId = getCookies("userId");
    const docRef = doc(db, `userData/${userId}/testingCanva/${projectId}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const projectBasicDetails = docSnap.data();

      if (projectBasicDetails.isNew) {
        const projectData = await fetchAndParseProjectData(userId, projectId);
        // console.log("projectData ", projectBasicDetails)
        projectData.categoryName = projectBasicDetails.Category_Name;
        console.log("projectData======= ", projectBasicDetails, projectData);
        return projectData;
      } else {
        return projectBasicDetails;
      }
    } else {
      console.log("No such project found!");
      return null;
    }
  } catch (error) {
    console.error("Error in getProjectById:", error.message);
    throw error; // Rethrow the error to handle it higher up in the call stack.
  }
};

/**
 * Fetches and parses project data.
 * @param {string} userId - The user's ID.
 * @param {string} projectId - The ID of the project.
 * @returns {Object} - Parsed project data.
 */
const fetchAndParseProjectData = async (userId, projectId) => {
  const projectData = await fetchProjectData(userId, projectId);

  try {
    return JSON.parse(projectData);
  } catch (error) {
    console.error("Error parsing project data:", error.message);
    throw new Error("Error parsing project data.");
  }
};

/**
 * Fetches project data from Firestore.
 * @param {string} userId - The user's ID.
 * @param {string} projectId - The ID of the project.
 * @returns {string} - Raw project data.
 */
const fetchProjectData = async (userId, projectId) => {
  const projectRef = doc(collection(db, `userData/${userId}/testingCanva`), projectId);
  const chunkedDataRef = collection(projectRef, "chunked_data");
  const querySnapshot = await getDocs(query(chunkedDataRef, orderBy("idx", "asc")));

  let rowData = "";

  querySnapshot.forEach((docSnapshot) => {
    const projectData = docSnapshot.data();
    if (parseInt(docSnapshot.id) < projectData.s) {
      rowData += projectData.data;
    }
  });

  if (!rowData) {
    console.warn("No chunked data found for the project.");
    throw new Error("No chunked data found for the project.");
  }

  return rowData;
};

export const getMyProject = async (limit = "5", sortBy, editorFilter, lastDocument, searchTerm) => {
  const status = PROJECT_STATUS.ACTIVE;
  const projectData = await getProjects(status, limit, sortBy, editorFilter, lastDocument, searchTerm);

  return projectData;
};

/**
 * Fetches projects from Firebase Firestore.
 *
 * @param {string} status - Project status ('1' for active).
 * @param {number} limit - Maximum number of projects to retrieve.
 * @param {string} sortBy - Sort direction ('asc' or 'desc').
 * @param {string} orderBy - Field to order by ('updateDate' by default).
 * @returns {Promise<Array>} - Array of project data.
 */

const getProjects = async (
  status = "1",
  limits = 4,
  sortBy = "desc",
  editorFilter = "allProjects",
  lastDocument,
  searchTerm,
  orderByy = "updateDate"
) => {
  try {
    const userId = getCookies("userId");
    const canvasCollectionRef = collection(db, "userData", userId, "testingCanva");

    const queryConditions = [where("Status", "==", status)];

    if (editorFilter !== "allProjects") {
      queryConditions.push(where("Source", "==", editorFilter));
    }

    if (searchTerm) {
      queryConditions.push(where("Project_name", "==", searchTerm));
    } else {
      queryConditions.push(limit(limits));
    }
    queryConditions.push(orderBy(orderByy, sortBy));
    if (lastDocument) {
      const startAfterValue = lastDocument[orderByy]; // Use the orderBy field for startAfter
      queryConditions.push(startAfter(startAfterValue));
    }

    const canvasQuery = query(canvasCollectionRef, ...queryConditions);
    const canvasSnapshot = await getDocs(canvasQuery);
    console.log("query receive size", canvasSnapshot.size);
    const projectDataPromises = canvasSnapshot.docs.map(async (canvasDoc) => {
      const projectId = canvasDoc.id;

      const canvasDataRef = doc(db, "userData", userId, "testingCanva", projectId);
      const canvasDataDoc = await getDoc(canvasDataRef);
      const canvasData = canvasDataDoc.data();
      if (canvasData && canvasData.thumbnail_link) {
        canvasData.projectId = projectId;
        return canvasData;
      } else if (canvasData && canvasData.isNew) {
        const chunkedDataQuery = query(collection(db, "userData", userId, "testingCanva", projectId, "chunked_data"), orderBy("idx", "asc"));
        const chunkedDataSnapshot = await getDocs(chunkedDataQuery);
        const chunkedData = await getParsedProjects([chunkedDataSnapshot]);
        return chunkedData[0];
      } else {
        canvasData.isOld = true;
        return canvasData;
      }
    });

    const projectData = await Promise.all(projectDataPromises);

    projectData.sort((a, b) => (sortBy === "desc" ? b[orderByy] - a[orderByy] : a[orderByy] - b[orderByy]));

    console.log("MyprojectData", projectData);

    return projectData;
  } catch (error) {
    console.error("Error fetching project data:", error);
    throw error;
  }
};

// const getProjects = async (
//   status = "1",
//   limits = 4,
//   sortBy = "desc",
//   editorFilter = "allProjects",
//   lastDocument,
//   searchTerm,
//   orderByy = "updateDate"
// ) => {
//   try {
//     const userId = getCookies("userId");
//     const canvasCollectionRef = collection(db, "userData", userId, "testingCanva");

//     const queryConditions = [where("Status", "==", status)];

//     if (editorFilter !== "allProjects") {
//       queryConditions.push(where("Source", "==", editorFilter));
//     }

//     if (searchTerm) {
//       queryConditions.push(where("Project_name", "==", searchTerm));
//     } else {
//       queryConditions.push(limit(limits));
//     }
//     queryConditions.push(orderBy(orderByy, sortBy));
//     if (lastDocument) {
//       const startAfterValue = lastDocument[orderByy]; // Use the orderBy field for startAfter
//       queryConditions.push(startAfter(startAfterValue));
//     }

//     const canvasQuery = query(canvasCollectionRef, ...queryConditions);
//     const canvasSnapshot = await getDocs(canvasQuery);
//     console.log("query receive size", canvasSnapshot.size);
//     const projectDataPromises = canvasSnapshot.docs.map(async (canvasDoc) => {
//       const projectId = canvasDoc.id;

//       const canvasDataRef = doc(db, "userData", userId, "testingCanva", projectId);
//       const canvasDataDoc = await getDoc(canvasDataRef);
//       const canvasData = canvasDataDoc.data();
//       if (canvasData && canvasData.thumbnail_link) {
//         canvasData.projectId = projectId;
//         return canvasData;
//       } else if (canvasData && canvasData.isNew) {
//         const chunkedDataQuery = query(collection(db, "userData", userId, "testingCanva", projectId, "chunked_data"), orderBy("idx", "asc"));
//         const chunkedDataSnapshot = await getDocs(chunkedDataQuery);
//         const chunkedData = await getParsedProjects([chunkedDataSnapshot]);
//         return chunkedData[0];
//       } else {
//         canvasData.isOld = true;
//         return canvasData;
//       }
//     });

//     const projectData = await Promise.all(projectDataPromises);

//     projectData.sort((a, b) => (sortBy === "desc" ? b[orderByy] - a[orderByy] : a[orderByy] - b[orderByy]));
//     return projectData;
//   } catch (error) {
//     console.error("Error fetching project data:", error);
//     throw error;
//   }
// };

// const getProjectLength = async () => {
//   try {
//     const userId = getCookies("userId");
//     const canvasCollectionRef = collection(db, "userData", userId, "testingCanva");

//     // Use a separate query to get the total length without any limit
//     const totalLengthQuery = query(canvasCollectionRef, where("Status", "==", "1"));
//     const totalLengthSnapshot = await getDocs(totalLengthQuery);
//     const totalLength = totalLengthSnapshot.size;

//     console.log("Total Length of User Projects (Without Limit):", totalLength);
//     dispatch(updateProjecLength(totalLength));
//   } catch (error) {
//     console.error("Error fetching total project length:", error);
//     throw error;
//   }
// };

const getParsedProjects = async (projectDoc = []) => {
  const projectData = [];

  try {
    for (const chunkDoc of projectDoc) {
      let rowData = "";
      for (const doc of chunkDoc.docs) {
        if (doc.exists()) {
          const id = parseInt(doc.id);
          const chunkData = doc.data();

          if (id < chunkData.s) {
            rowData += chunkData.data;
          }
        }
      }

      if (rowData) {
        const parseData = JSON.parse(rowData);
        projectData.push(parseData);
      }
    }
    return projectData;
  } catch (error) {
    console.error("Error in getParsedProject:", error);
    throw error;
  }
};

const sendDatatoJsonUrl = async (Mydata) => {
  try {
    const apiURL = `${Python_FUNCTION_URL}/createProjectJson?userId=${Mydata.userId}&projectId=${Mydata.projectId}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Mydata),
    };

    const response = await fetch(apiURL, requestOptions);

    // Check if the response status is OK (status code 200-299)
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error response:", errorText);
      throw new Error(`Failed to save project data: ${response.status} ${response.statusText}`);
    }

    // If response is OK, parse the JSON data
    const result = await response.json();
    console.log("Auto-save successful123", result);

    return result; // Return the result for further processing
  } catch (error) {
    console.error("An error occurred while sending data:", error.message);
    throw error; // Rethrow the error for better error handling
  }
};

export const createCopyOfProject = (projectDetails, newProjectId, updateDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const newProjectId = getRandomID(20);
      let projectAllDetails = await getProjectById(projectDetails.projectId);
      if (projectAllDetails) {
        projectAllDetails.projectId = newProjectId;
        projectAllDetails.updateDate = updateDate;
        let response = await setUserProjectUpdated(projectAllDetails);
        let responseJson = await sendDatatoJsonUrl(projectAllDetails);
        console.log("responseresponse", response);
        console.log("responseresponse", responseJson);

        if (responseJson) {
          if (response) {
            resolve(newProjectId);
          } else {
            console.error("Error saving copied project");
            reject(new Error("Error saving copied project"));
          }
        }
      } else {
        console.error("Project not found for copying the project");
        reject(new Error("Project not found"));
      }
    } catch (error) {
      console.error("Error creating a copy of the project:", error);
      reject(error);
    }
  });
};

export const changeProjectStatus = (userId, status, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `userData/${userId}/testingCanva/${projectId}`);
      await updateDoc(docRef, { Status: status });
      resolve(true);
    } catch (error) {
      console.error("Error changing project status:", error);
      reject(error);
    }
  });
};

export const projectDeleteListUpdateDb = async (userId, status, projectId) => {
  try {
    const docRef = doc(db, `userData/${userId}/inactiveProjectList/${projectId}`);
    const data = { projectStatus: status, projectId: projectId };
    await setDoc(docRef, data);
    return true;
  } catch (error) {
    console.error("Error project Delete List Update in Db", error);
    throw new Error("Error project Delete List Update in Db", error);
  }
};
export const changingProjectStatus = async (status, projectId) => {
  try {
    const userId = getCookies("userId");
    if (userId) {
      await changeProjectStatus(userId, status, projectId);
      await projectDeleteListUpdateDb(userId, status, projectId);
    }
  } catch (error) {
    console.error("Error deleting project:", error);
    throw new Error("Error deleting project:", error);
  }
};

export const getUploadedFiles = async (userId) => {
  try {
    const uploadedImages = query(collection(db, `userData/${userId}/MyUploads/Images/uploadedImages/`), limit(20));

    const images = await getDoc(doc(db, `userData/${userId}`));
    let desingToolUploadedImage = [];
    if (images.exists()) {
      let ImageData = images.data();

      if (ImageData.MyUpload?.length > 0) {
        // desingToolUploadedImage = ImageData.MyUpload
        for (const image of ImageData.MyUpload) {
          desingToolUploadedImage.push({ webformatURL: image, previewURL: image, width: null, height: null, from: "PreviousEditorUploaded" });
        }
      }
    }

    const querySnapshot = await getDocs(uploadedImages);
    let r = [];
    querySnapshot.forEach((doc) => {
      r.push({
        webformatURL: doc.data().url,
        previewURL: doc.data().url,
        width: doc.data().width,
        height: doc.data().height,
        docId: doc.id,
        from: "NewEditorUploaded",
      });
    });
    let allImages = [...r, ...desingToolUploadedImage];
    return allImages;
  } catch (error) {
    throw new Error("Error getting uploaded images", error);
  }
};
export const updateUserOnLogin = async (payload = {}, userId) => {
  // const userId = getCookies('userId');
  try {
    const docRef = doc(db, `userData/${userId}`);
    await updateDoc(docRef, payload);
    console.log("payloaduserlogin", payload);
    return true;
  } catch (error) {
    return false;
  }
};
export const saveFreeAccount = async () => {
  const userId = getCookies("userId");
  console.log("Start Limited Action Mode", userId);
  const docRef = doc(db, `userData/${userId}`);
  await updateDoc(docRef, { claimFreeAccount: 1 });
};
export const paymentStatusChange = async (userId, userType) => {
  console.log("Making user paid", userId);
  const docRef = doc(db, `userData/${userId}`);
  await updateDoc(docRef, { userType: userType });
  return;
};
export const getDeletedProjectsSDK = async () => {
  const status = PROJECT_STATUS.INACTIVE;
  let limit = 100;
  const projectData = await getProjects(status, limit);
  return projectData;
};

export const deleteProjectPermanently = async (projectDetails) => {
  const userId = getCookies("userId");

  if (!userId) {
    console.error("User ID not found. Aborting delete operation.");
    return;
  }

  const projectPath = `userData/${userId}/testingCanva/${projectDetails.projectId}`;
  const documentRef = doc(db, projectPath);

  try {
    await deleteDoc(documentRef);
    console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error deleting document:", error);
  }
};

export const deleteUploadedImages = async (imageDetails) => {
  const userId = getCookies("userId");
  if (userId) {
    if (imageDetails.from === "NewEditorUploaded") {
      const documentRef = doc(db, `userData/${userId}/MyUploads/Images/uploadedImages/${imageDetails.docId}`);
      deleteDoc(documentRef)
        .then(() => {
          console.log("images successfully deleted!");
        })
        .catch((error) => {
          console.error("Error deleting images: ", error);
        });
    } else if (imageDetails.from === "PreviousEditorUploaded") {
      const documentRef = doc(db, `userData/${userId}`);
      const documentSnapshot = await getDoc(documentRef);
      if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();

        if (data.MyUpload && data.MyUpload.includes(imageDetails.webformatURL)) {
          const updatedArray = data.MyUpload.filter((item) => item !== imageDetails.webformatURL);
          await updateDoc(documentRef, { MyUpload: updatedArray });
          console.log("Image removed from the array.");
        } else {
          console.log("image doesn't exist.");
        }
      } else {
        console.log("Document does not exist.");
      }
    }

    // Delete the document
  }
};
/**
 * Fetch a document from Firebase Firestore.
 *
 * @param {string} path - The path to the Firestore document.
 * @returns {Promise<{ status: boolean, data?: any, message?: string }>}
 */
export const getFirebaseDocument = async (path) => {
  try {
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { status: true, data: docSnap.data() };
    } else {
      return { status: false, message: "Document does not exist" };
    }
  } catch (error) {
    // Handle any potential errors that may occur during the operation
    console.error("Error in getFirebaseDocument:", error);
    return { status: false, message: "An error occurred while fetching the document" };
  }
};

export const setFirebaseDocument = async (path, data = {}, merge) => {
  console.log("firebasedatasave-->>",path,data)
  // const userId = getCookies('userId');
  try {
    const docRef = doc(db, path);
    if(merge){
      await setDoc(docRef, data, { merge: true });
    }else{
      await setDoc(docRef, data);
    }
    
    return true;
  } catch (error) {
    console.error("error setting doc", error)
    return false;
  }
};
export const updateFirebaseDocument = async (path, payload = {}) => {
  try {
    const docRef = doc(db, path);
    await updateDoc(docRef, payload);
    return true;
  } catch (error) {
    console.error("error setting doc", error);
    return false;
  }
};

export const updateAigenerationStart = async (path, data = {}) => {
  console.log("firebasedatasave-->>", path, data);

  try {
    const docRef = doc(db, path);
    await setDoc(docRef, {
      isGenerationDone: false, // Field can be 'isGenerationDone' or any other field name
    });
    console.log("Document updated successfully");
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

// export const updateProductIdFreePlan = async (payload = {}, userId) => {
//   // const userId = getCookies('userId');
//   try {
//     const docRef = doc(db, `userData/${userId}`);
//     await updateDoc(docRef, payload);
//     console.log("payloaduserlogin", payload);
//     return true;
//   } catch (error) {
//     return false;
//   }
// };

// export const getUserAiimage = async () => {
//   const userId = getCookies('userId');
//   const userData = await db
//     .collection('userData')
//     .doc(userId)
//     .collection('Aiimagedata')
//     .doc('allImage')
//     .get();
//   if (userData.exists) {
//     return userData.data();
//   } else {
//     return [];
//   }
// };

export const getRecentData = async () => {
  try {
    // const firestore = firebase.firestore();
    let a = await getFirebaseDocument(`userData/518/Aiimagedata/allImage`);
    // const docRef = doc(db, `userData/518/Aiimagedata/allImage`);
    // const querySnapshot = await getDocs(docRef);

    // // Assuming "allImage" is a field in your document that contains an array named "Ai_image"
    // const allImageArray = querySnapshot.data().allImage;

    // // Sort the "Ai_image" array
    // const sortedAiImage = allImageArray.filter((item) => item.name === "Ai_image"); // Filter only elements with name "Ai_image"
    // //.sort((a, b) => a.someProperty - b.someProperty); // Replace "someProperty" with the actual property you want to sort by

    // // Get only the first 20 elements
    // const limitedSortedAiImage = sortedAiImage.slice(0, 20);

    console.log("getting recent data", a);
  } catch (error) {
    console.error("Error getting recent data:", error);
    throw error;
  }
};

// this function will call only on staging
// export const setRecentDatainDb = async () => {};

export const uploadDallImageinuseraccount = async (userId, value) => {
  const imagedata = { url: value.url, fav: false, A: false, B: false };

  try {
    const docRef = doc(db, "userData", userId, "Aiimagedata", "allImage");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        Ai_image: arrayUnion(imagedata),
      });
    } else {
      console.log("Document not found");
      await setDoc(docRef, {
        Ai_image: arrayUnion(imagedata),
      });
    }

    return { upload: "success" };
  } catch (error) {
    console.error("Error writing document: ", error);
    // Handle error or return an error response
    return { error: "Failed to upload image" };
  }
};
export const uploadLogoinuseraccount = async (userId, value) => {
  const imagedata = { url: value.url, fav: false, A: false, B: false };

  try {
    const docRef = doc(db, "userData", userId, "Aiimagedata", "AiLogo");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        AiLogo: arrayUnion(imagedata),
      });
    } else {
      console.log("Document not found");
      await setDoc(docRef, {
        AiLogo: arrayUnion(imagedata),
      });
    }

    return { upload: "success" };
  } catch (error) {
    console.error("Error writing document: ", error);
    // Handle error or return an error response
    return { error: "Failed to upload image" };
  }
};
export const uploadEmojiinuseraccount = async (userId, value) => {
  const imagedata = { url: value.url, fav: false, A: false, B: false, prompt: value.prompt };

  try {
    const docRef = doc(db, "userData", userId, "Aiimagedata", "AiEmoji");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        AiEmoji: arrayUnion(imagedata),
      });
    } else {
      console.log("Document not found");
      await setDoc(docRef, {
        AiEmoji: arrayUnion(imagedata),
      });
    }

    return { upload: "success" };
  } catch (error) {
    console.error("Error writing document: ", error);
    // Handle error or return an error response
    return { error: "Failed to upload image" };
  }
};
export const uploadAvatarinuseraccount = async (userId, value) => {
  const imagedata = { target_image: value.target_image, fav: false, A: false, B: false, swap_image: value.swap_image, url: value.url };

  try {
    const docRef = doc(db, "userData", userId, "Aiimagedata", "AiAvtar");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        AiAvtar: arrayUnion(imagedata),
      });
    } else {
      console.log("Document not found");
      await setDoc(docRef, {
        AiAvtar: arrayUnion(imagedata),
      });
    }

    return { upload: "success" };
  } catch (error) {
    console.error("Error writing document: ", error);
    // Handle error or return an error response
    return { error: "Failed to upload image" };
  }
};
export const uploadQrCodeinuseraccount = async (userId, value) => {
  const imagedata = { url: value.url, fav: false, A: false, B: false, prompt: value.prompt, content: value.content };

  try {
    const docRef = doc(db, "userData", userId, "Aiimagedata", "AiQrcode");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, {
        AiQrcode: arrayUnion(imagedata),
      });
    } else {
      console.log("Document not found");
      await setDoc(docRef, {
        AiQrcode: arrayUnion(imagedata),
      });
    }

    return { upload: "success" };
  } catch (error) {
    console.error("Error writing document: ", error);
    // Handle error or return an error response
    return { error: "Failed to upload image" };
  }
};

export const convertHeicToJpeg = async (file) => {
  try {
    const jpegBlob = await heic2any({ blob: file, toType: "image/jpeg" });
    return new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpeg"), { type: "image/jpeg" });
  } catch (error) {
    console.error("Error converting HEIC to JPEG:", error);
    throw error;
  }
};

const getOrientation = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      try {
        const binary = event.target.result;
        const exifObj = piexif.load(binary);
        const orientation = exifObj["0th"][piexif.ImageIFD.Orientation];
        console.log("Orientation:", orientation);
        resolve(orientation || 1);
      } catch (error) {
        console.error("Error getting orientation:", error);
        resolve(1); // Default orientation
      }
    };
    reader.onerror = function (error) {
      console.error("File reading error:", error);
      resolve(1); // Default orientation
    };
    reader.readAsDataURL(file);
  });
};

const correctImageOrientation = async (file) => {
  try {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let img = new Image();

    return new Promise((resolve, reject) => {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(new File([blob], file.name, { type: file.type }));
          } else {
            reject(new Error("Canvas toBlob failed"));
          }
        }, file.type);
      };

      img.onerror = (error) => {
        console.error("Error loading image:", error);
        reject(error);
      };

      img.src = URL.createObjectURL(file);
    });
  } catch (error) {
    console.error("Error processing image:", error);
    return file; // Return original file if there's an error
  }
};


// Function to delete the file from Firebase Storage
export const deleteFileFromStorage = async (filePath) => {
  try {
    const storageRef = ref(storage, filePath);
    await deleteObject(storageRef);
    console.log("File deleted successfully.");
  } catch (error) {
    console.error("Error deleting file from storage:", error);
    throw error;
  }
};

export const uploadFileVideo = async (file, uploadingPath) => {
  try {
  
    let fileToUpload = file;

    const storageRef = ref(storage, `${uploadingPath}/${fileToUpload.name}`);
    
    // Upload the file
    const snapshot = await uploadBytes(storageRef, fileToUpload);
    
    // Get the download URL
    const url = await getDownloadURL(storageRef);

    console.log("File uploaded successfully, URL:", url);
    
    // Return the URL and the storage reference path (file path)
    return { url, filePath: `${uploadingPath}/${fileToUpload.name}` };
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const uploadFile = async (file, uploadingPath) => {
  try {
    // Convert HEIC to JPEG if necessary
    if (file.type === "image/heic" ||file?.type === "image/HEIC" || file?.name?.endsWith(".heic")) {
      console.log('Converting HEIC to JPEG');
      file = await convertHeicToJpeg(file);
    }

    const orientation = await getOrientation(file);

    let fileToUpload = file;

    console.log("orientationcorrection", orientation);

    if (orientation !== 1) {
      console.log("Image needs orientation correction.");
      fileToUpload = await correctImageOrientation(file, orientation);
    }

    const storageRef = ref(storage, `${uploadingPath}/${fileToUpload.name}`);
    const snapshot = await uploadBytes(storageRef, fileToUpload);
    const url = await getDownloadURL(storageRef);

    return url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};





export const uploadAiYtstaging = async (value) => {
  const data = { Jsondata: value.jsondata, fav: false, imageUrl: value.imageurl, timestamp: serverTimestamp() };

  try {
    const docRef = doc(db, "userData", value.UserId, "AiThumbnailYoutube", value.projectId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, data);
    } else {
      console.log("Document not found");
      await setDoc(docRef, data);
    }

    return { upload: "success" };
  } catch (error) {
    console.error("Error writing document: ", error);
    // Handle error or return an error response
    return { error: "Failed to upload image" };
  }
};

const convertIndateFormat = () => {
  const date = new Date(); // You can replace this with your specific date

  // Adjust the date to UTC+5:30
  date.setUTCHours(date.getUTCHours() + 5);
  date.setUTCMinutes(date.getUTCMinutes() + 30);

  // Format the date
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "UTC",
  }).format(date);

  return formattedDate;
};
export const uploadAiYtinuseraccount = async (userId, value, canvas) => {
  let aiYtDataUrl = aIYTcanvasDataURL(canvas, 0.5);
  console.log("YT Generation result returned from canvas", aiYtDataUrl);
  const { Title, Description, BackgroundImage, PersonImage } = value;
  let updatedJSONData = {
    userImg: PersonImage,
    Title: Title,
    Description: Description,
    bgimg: BackgroundImage,
  };
  let Mydata = {
    UserId: userId,
    projectId: "Thumb" + Math.floor(Math.random() * 1000000),
    imageurl: aiYtDataUrl,
    jsondata: updatedJSONData,
  };
  // from === "appydesign"

  //for saving in live db
  // const updatedData = await getPostData("thumbnaildata", Mydata, {}, "appydesign");
  // console.log("uploading YTTHUMB", updatedData);

  // for saving in staging db
  uploadAiYtstaging(Mydata);
};

export const getYtRecentData = async (path, usedFor) => {
  // const userId = getCookies("userId");

  const userRef = collection(db, path);
  try {
    const userDataSnapshot = await getDocs(userRef);
    if (!userDataSnapshot.empty) {
      const userData = [];
      userDataSnapshot.forEach((doc) => {
        const data = doc.data();
        // if(usedFor=="AiTemplateMakerEditor"){
          // data.timestamp = parseTimestamp(data.timestamp);
          if(usedFor && usedFor==="AiFlexBannerEditor"){
            if(data.canvasJson){
              data.url = data?.ImageUrl || "https://storage.googleapis.com/imagesai.appypie.com/No_Image_Available.jpg"
            }else{
              return
            }
          }else{
            data.url = data.ImageUrl;
          }
          // delete data["imageUrl"];
          userData.push(data);
        // }else{
        // data.timestamp = parseTimestamp(data.timestamp);
        // data.url = data.imageUrl;
        // delete data["imageUrl"];
        // userData.push(data);
        // }
      });

      // Sort userData based on the timestamp field
      userData.sort((a, b) => b.timestamp - a.timestamp);
      return { status: true, data: userData };
    } else {
      console.log("getUserThumbnailData else condition");
      return { status: true, data: [] };
      // return { status: false, message: "Document does not exist" };
    }
  } catch (error) {
    console.error("Error fetching user thumbnail data:", error);
    return { status: false, message: "An error occurred while fetching the document" };
  }
};
export const getRecentLength = async (path, usedFor)=>{
  
  let userRef = collection(db, path);
  if (usedFor &&(usedFor==="AiFlexBannerEditor")) {
    userRef = query(userRef, where("loadOnCanvas", "==", true));
  }
  try {
    const snapshot = await getDocs(userRef);
    console.log("length getting", snapshot.size);
    return { status: true, data: snapshot.size };
  } catch (error) {
    console.error("Error fetching user thumbnail data:", error);
    return { status: false, message: "An error occurred while fetching the document" };
  }
};
function parseTimestamp(timestampObject) {
  const milliseconds = timestampObject.seconds * 1000 + timestampObject.nanoseconds / 1e6;
  return new Date(milliseconds);
}

export const deletetemp = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "userData/518/testingCanva"));
    // queryConditions.push(limit(limits));
    console.log(querySnapshot.docs.length, "rtyuioiutreryuioiuytrertyui");
    const deletePromises = querySnapshot.docs.map(async (doc) => {
      const data = await doc.data();
      console.log(data, "rtyuioiutreryuioiuytrertyui", db);
      return deleteDoc(doc.ref);
    });

    await Promise.all(deletePromises);

    console.log("Collection successfully deleted!");
  } catch (err) {
    console.error(err.message, "fghjklkgfdfghjkjhgfdfgh");
  }
};

export const fetchUserDocuments = async (uniqueIds) => {
  const currentUserId = getCookies("userId");
  const promises = uniqueIds.map((id) => getFirebaseDocument(`userData/${currentUserId}/AiStudio/${id}`));
  const results = await Promise.all(promises);
  const filteredResults = results.filter((doc) => !doc.data.Delete && !doc.data.errorGenerating);
  return filteredResults;
};

export const cleanUpAfterDownload = () => {
  removeUrlParam("download-gen");
  const pathnameSDKSearchQ = atob(sessionStorage.getItem("pathnameSDKSearchQ"));
  const searchParams = new URLSearchParams(pathnameSDKSearchQ);
  if (searchParams.get("download-gen")) {
    sessionStorage.removeItem("pathnameSDKSearchQ");
  }
};
export const uploadBase64Image = async (base64Image, uploadingPath) => {
  try {
    // Decode base64 string to binary data
    const binaryString = atob(base64Image.split(',')[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Convert binary data to Blob
    const fileType = base64Image.split(';')[0].split(':')[1];
    let file = new Blob([bytes], { type: fileType });

    // Convert Blob to File
    const fileName = `upload_${Date.now()}.${fileType.split('/')[1]}`;
    file = new File([file], fileName, { type: fileType });
    
    let fileToUpload = file;

    const storageRef = ref(storage, `${uploadingPath}/${fileToUpload.name}`);
    const snapshot = await uploadBytes(storageRef, fileToUpload);
    const url = await getDownloadURL(storageRef);

    return url;
  } catch (error) {
    console.error("Error uploading base64 image:", error);
    throw error;
  }
};
export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.onloadend = function () {
        // Resolve the promise with the Base64 string
        resolve(reader.result);
      };

      reader.onerror = function (error) {
        // Reject the promise if an error occurs
        reject(error);
      };

      reader.readAsDataURL(file);
    } else {
      reject(new Error('No file selected'));
    }
  });
}

