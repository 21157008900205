import { max } from "lodash";

///////////

//??????????????






export const _scaleObject = (j) => {
  // console.log(e,)
  var n = j.transform && j.transform
    , i = n && n.target
    , r = i.lockScalingX
    , o = i.lockScalingY
    , c = i.lockScalingFlip
    , t = j.pointer.y
    , e = j.pointer.x
    , a = n.action
  if (r && o)
    return !1;
  var l, u = i.translateToOriginPoint(i.getCenterPoint(), n.originX, n.originY), h = i.toLocalPoint(new fabric.Point(e, t), n.originX, n.originY), d = { x: (i._getTransformedDimensions().x - 1), y: (i._getTransformedDimensions().y) }, p = i.translateToOriginPoint(i.getCenterPoint(), n.originX, "center");
  if (_setLocalMouse(h, n),
    l = _setObjectScale(h, n, r, o, a, c, d),
    i.changeRotationWhenScaleX && i.changeRotationWhenScaleX()) {
    var g = this._currentTransform
      , f = "left";
    "scaleX" === a ? f = "left" : "scaleY" === a && (f = "right"),
      this._rotateObject(e, t, f),
      i.setPositionByOrigin(p, g.originX, "center")
  } else
    i.setPositionByOrigin(u, n.originX, n.originY);
  return l
}
export const _setLocalMouse = (e, t) => {
  var n = t.target
    , r = n.canvas.getZoom()
    , i = n.padding / r;
  "right" === t.originX ? e.x *= -1 : "center" === t.originX && (e.x *= 2 * t.mouseXSign,
    e.x < 0 && (t.mouseXSign = -t.mouseXSign)),
    "bottom" === t.originY ? e.y *= -1 : "center" === t.originY && (e.y *= 2 * t.mouseYSign,
      e.y < 0 && (t.mouseYSign = -t.mouseYSign)),
    Math.abs(e.x) > i ? e.x < 0 ? e.x += i : e.x -= i : e.x = 0,
    Math.abs(e.y) > i ? e.y < 0 ? e.y += i : e.y -= i : e.y = 0
}


export const _setObjectScale = (e, t, a, n, i, r, o) => {
  var c = t.target
    , l = !1
    , u = !1
    , h = !1
    , d = e.x * c.scaleX / o.x
    , p = e.y * c.scaleY / o.y
    , g = c.scaleX !== d
    , f = c.scaleY !== p;
  if (t.newScaleX = d,
    t.newScaleY = p,
    fabric.Textbox && "scaleX" === i && c instanceof fabric.Textbox || ("scaleY" === i || "scaleX" === i) && c.changeSizeWhenScaleXY) {
    if ("scaleX" === i) {
      if (c.group && c.group.canModifyChildren)
        return c.group.updateSlotSize({
          target: c,
          multiply: e.x / o.x,
          by: i,
          corner: t.corner
        }),
          !0;
      var m = c.width * (e.x / o.x);
      if (c.getMinWidth && m >= c.getMinWidth() || !c.getMinWidth && m > 0)
        return h = (m = Math.max(m, 1 / c.scaleX, c.limitMinWidth || 0)) !== c.width,
          c.set({
            width: m
          }),
          c.setCoords(),
          h
    } else {
      if (c.group && c.group.canModifyChildren)
        return c.group.updateSlotSize({
          target: c,
          multiply: e.y / o.y,
          by: i,
          corner: t.corner
        }),
          !0;
      var v = c.height * (e.y / o.y);
      if (c.getMinHeight && v >= c.getMinHeight() || !c.getMinHeight && v > 0)
        return h = (v = Math.max(v, 1 / c.scaleY, c.limitMinHeight || 0)) !== c.height,
          c.set({
            height: v
          }),
          h
    }
    return !1
  }
  if (r && d <= 0 && d < c.scaleX && (l = !0,
    e.x = 0),
    r && p <= 0 && p < c.scaleY && (u = !0,
      e.y = 0),
    "equally" !== i || a || n)
    if (i)
      if ("scaleX" !== i || c.get("lockUniScaling")) {
        if ("scaleY" === i && !c.get("lockUniScaling")) {
          u || n || c.set("scaleY", p) && (h = f);
          var b = Math.max(1 / c.height, c.scaleY, (c.limitMinHeight || 0) / c.height);
          c.set("scaleY", b)
        }
      } else {
        l || a || c.set("scaleX", d) && (h = g);
        var A = Math.max(1 / c.width, c.scaleX, (c.limitMinWidth || 0) / c.width);
        c.set("scaleX", A)
      }
    else {
      l || a || c.set("scaleX", d) && (h = h || g),
        u || n || c.set("scaleY", p) && (h = h || f);
      var y = Math.max(Math.max(1 / c.width, c.scaleX, (c.limitMinWidth || 0) / c.width), Math.max(1 / c.height, c.scaleY, (c.limitMinHeight || 0) / c.height));
      c.scale(y)
    }
  else {
    h = this._scaleObjectEqually(e, c, t, o);
    Math.max(Math.max(1 / c.width, c.scaleX), Math.max(1 / c.height, c.scaleY))
  }
  // return l || u || this._flipObject(t, i),
  h
}

///////------->>>>>


export const _onClipMouseDown = (e) => {

  let t = e.transform;
  let z = e.target;
  if (z.cropArea && isPerpendicular(z)) {
    let a = {
      left: z.left,
      top: z.top,
      angle: z.angle,
    };
    if (z.editingAngle) {
      let n = z.cropArea.getCenterPoint(),
        i = new fabric.Point(z.left, z.top),
        r = fabric.util.rotatePoint(
          i,
          n,
          fabric.util.degreesToRadians(-z.editingAngle)
        );
      z.set("left", r.x);
      z.set("top", r.y);
      z.set("angle", getClipAngle(z) - z.editingAngle).setCoords();
      z.cropArea.rotate(0).setCoords();
      z.cropArea.rotate(0).setCoords();
    }
    let o = z.getBoundingRect(!0, !0),
      c = z.cropArea.scaleX,
      l = z.cropArea.scaleY,
      u = z.cropArea.width,
      h = z.cropArea.height,
      d = z.cropArea.getScaledWidth(),
      p = z.cropArea.getScaledHeight(),
      g = z.cropArea.left - o.left,
      f = z.cropArea.top - o.top;
    "tl" === t.corner
      ? ((c = (d + g) / u), (l = (p + f) / h))
      : "tr" === t.corner
        ? ((c = (o.width - g) / u), (l = (p + f) / h))
        : "br" === t.corner
          ? ((c = (o.width - g) / u), (l = (o.height - f) / h))
          : "bl" === t.corner
            ? ((c = (d + g) / u), (l = (o.height - f) / h))
            : "mt" === t.corner
              ? (h = (f + p) / l)
              : "mr" === t.corner
                ? (u = (o.width - g) / c)
                : "mb" === t.corner
                  ? (h = (o.height - f) / l)
                  : "ml" === t.corner && (u = (g + d) / c);
    (z.editingClipScaleX = Math.min(c, l));
    (z.editingClipScaleY = Math.min(c, l));
    (z.editingClipScaleW = u);
    (z.editingClipScaleH = h);
    z.editingAngle &&
      (z.setOptions(a),
        z.setCoords(),
        z.cropArea.rotate(z.editingAngle).setCoords());
  }
};
export const _onMousedown = (e) => {
  let t = e.transform
    , a = t && t.corner
    , n = getOriginalRect(t)
    , i = getPreviousCenterPoint(e);
  if (n && e && e.transform) {
    t.target.clipChild.rotate(-(t.target.imageAngle));
    let r = t.target.clipChild.getBoundingRect(!0, !0);
    t.target.clipChild.rotate(t.target.editingAngle);
    let o = fabric.util.rotatePoint(new fabric.Point(t.target.left, t.target.top), i, fabric.util.degreesToRadians(- t.target.angle))
      , c = 0
      , l = 0
      , u = t.target.width
      , h = t.target.height
      , d = t.target.getScaledWidth()
      , p = t.target.getScaledHeight()
      , g = r.left - o.x
      , f = r.top - o.y;
    "tl" === t.corner ? (c = (d - g) / u,
      l = (p - f) / h) : "tr" === t.corner ? (c = (g + r.width) / u,
        l = (p - f) / h) : "br" === t.corner ? (c = (g + r.width) / u,
          l = (f + r.height) / h) : "bl" === t.corner && (c = (d - g) / u,
            l = (f + r.height) / h),
      t.target.minScale = Math.max(c, l)
  } else { _isChangeSizeCorner(a) && t.target.initialImageSize && _beforeChangeSize(e) }
}
export const _beforeChangeSize = (e) => {
  console.log(e)
  let r = e.transform.target
  r.previousImageViewport = rt({}, r.imageViewport),
    r.previousViewSize = {
      width: r.width,
      height: r.height
    },
    r.preImageScale = r.imageScale
}
export const isPerpendicular = (g) => {
  var e = (g.imageAngle || 0) % 90;
  return e <= 1 || 90 - e <= 1;
};

export const getClipAngle = (g) => {
  var e = g.editingAngle;
  return g.isEditing || (e = g.angle), (g.imageAngle || 0) + (e || 0);
};
// function(e, t, n) {
//   "use strict";
//   function r(e, t, n) {
//       return t in e ? Object.defineProperty(e, t, {
//           value: n,
//           enumerable: !0,
//           configurable: !0,
//           writable: !0
//       }) : e[t] = n,
//       e
//   }
//   n.d(t, "a", (function() {
//       return r
//   }
//   ))
// }
function it(e, t) {
  let a = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    let n = Object.getOwnPropertySymbols(e);
    console.log(n)
    t && (n = n.filter((function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable
    }
    ))),
      a.push.apply(a, n)
  }
  console.log(a)
  return a
}
/////////

/////////
function r(e, t, n) {
  return t in e ? Object.defineProperty(e, t, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = n,
    e
}
/////
export function rt(e) {
  for (let t = 1; t < arguments.length; t++) {
    let a = null != arguments[t] ? arguments[t] : {};
    t % 2 ? it(a, !0).forEach((function (t) {
      Object(r)(e, t, a[t])
    }
    )) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : it(a).forEach((function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(a, t))
    }
    ))
  }
  return e
}
export const handleImageScale = (e) => {
  e.transform.target.imageChanged = !0;
  var t = e.transform,
    a = t && t.corner,
    n = getOriginalRect(t);
  if (t.target.isEditing && e && e.transform && n) {
    if (
      t.target.scaleX <= t.target.minScale ||
      t.target.scaleY <= t.target.minScale
    ) {
      var i = t.target.translateToOriginPoint(
        t.target.getCenterPoint(),
        t.originX,
        t.originY
      );
      (t.target.scaleX = t.target.minScale),
        (t.target.scaleY = t.target.minScale),
        t.target.setPositionByOrigin(i, t.originX, t.originY);
    } else t.target.editingScale = t.target.scaleX;
    // this._showOrHideGridlines(!0);
  } else {
    // _isChangeSizeCorner(a) && _onSizeChanged(a, e);
  }
};

export const _isChangeSizeCorner = (e) => {
  return "mr" == e || "mb" == e || "ml" == e || "mt" == e;
};

export const _onSizeChanged = (e, j) => {
  let rtz = j.transform && j.transform.target
  if (rtz.initialImageSize && rtz.imageViewport) {
    var t = rtz.width,
      a = rtz.height,
      n = rtz.initialImageSize.width / rtz.initialImageSize.height;
    if ("mr" === e) {
      let i =
        1 +
        (rtz.width - rtz.previousViewSize.width) /
        rtz.previousViewSize.width,
        r = rtz.previousImageViewport.width * i;
      if (rtz.imageViewport.x + r > 1) {
        let o = rtz.imageViewport.x + r,
          c = 1 - rtz.imageViewport.x,
          s = a / (t / c / n),
          l = (rtz.previousImageViewport.height - s) / 2;
        (rtz.imageViewport.y = _(0, rtz.previousImageViewport.y + l, 1)),
          (rtz.imageViewport.height = _(0, s, 1 - rtz.imageViewport.y)),
          (rtz.imageViewport.width = c),
          (rtz.imageScale = rtz.preImageScale * o);
      } else rtz.imageViewport.width = r;
    } else if ("ml" === e) {
      var u = rtz.previousViewSize.width / rtz.previousImageViewport.width,
        h = (rtz.width - rtz.previousViewSize.width) / u,
        d = rtz.previousImageViewport.x - h;
      if (d < 0) {
        var p = 1 - d,
          g = a / (t / rtz.imageViewport.width / n),
          f = (rtz.previousImageViewport.height - g) / 2;
        (rtz.imageViewport.y = _(0, rtz.previousImageViewport.y + f, 1)),
          (rtz.imageViewport.height = _(0, g, 1 - rtz.imageViewport.y)),
          (rtz.imageViewport.x = 0),
          (rtz.imageScale = rtz.preImageScale * p);
      } else
        (rtz.imageViewport.x = d),
          (rtz.imageViewport.width =
            rtz.previousImageViewport.width +
            (rtz.previousImageViewport.x - d));
    } else if ("mb" === e) {
      var m =
        1 +
        (rtz.height - rtz.previousViewSize.height) /
        rtz.previousViewSize.height,
        v = rtz.previousImageViewport.height * m;
      if (rtz.imageViewport.y + v > 1) {
        var b = rtz.imageViewport.y + v,
          A = 1 - rtz.imageViewport.y,
          y = t / ((a / A) * n),
          E = (rtz.previousImageViewport.width - y) / 2;
        (rtz.imageViewport.x = _(0, rtz.previousImageViewport.x + E, 1)),
          (rtz.imageViewport.width = _(0, y, 1 - rtz.imageViewport.x)),
          (rtz.imageViewport.height = A),
          (rtz.imageScale = rtz.preImageScale * b);
      } else rtz.imageViewport.height = v;
    } else if ("mt" === e) {
      var C = rtz.previousViewSize.height / rtz.previousImageViewport.height,
        O = (rtz.height - rtz.previousViewSize.height) / C,
        k = rtz.previousImageViewport.y - O;
      if (k < 0) {
        var w = 1 - k,
          S = t / ((a / rtz.imageViewport.height) * n),
          I = (rtz.previousImageViewport.width - S) / 2;
        (rtz.imageViewport.x = _(0, rtz.previousImageViewport.x + I, 1)),
          (rtz.imageViewport.width = _(0, S, 1 - rtz.imageViewport.x)),
          (rtz.imageViewport.y = 0),
          (rtz.imageScale = rtz.preImageScale * w);
      } else
        (rtz.imageViewport.y = k),
          (rtz.imageViewport.height =
            rtz.previousImageViewport.height +
            (rtz.previousImageViewport.y - k));
    }
    Object(throttle)(setImageStrokeDirty(j), 200)();
  }
};
let throttle = function (e, t, n) {
  var r = !0
    , i = !0;
  if ("function" != typeof e) {
    throw new rt(u);
  }
  return As(n) && (r = "leading" in n ? !!n.leading : r,
    i = "trailing" in n ? !!n.trailing : i),
    os(e, t, {
      leading: r,
      maxWait: t,
      trailing: i
    })
}
export const setImageStrokeDirty = (j) => {
  let rtz = j.transform.target
  return function () {
    rtz.imgStrokeChange = !0,
      rtz.imageChanged = !0
  }
}
function As(e) {
  var t = typeof e;
  return null != e && ("object" == t || "function" == t)
}
let oa = function (e, t) {
  return window.setTimeout(e, t)
}
function os(e, t, n) {
  var r, i, a, s, l, c, f = 0, h = !1, p = !1, d = !0, o;
  if ("function" != typeof e)
    throw new rt(u);
  function m(t) {
    var n = r
      , a = i;
    return r = i = o,
      f = t,
      s = e.apply(a, n)
  }
  function v(e) {
    var n = e - c;
    return c === o || n >= t || n < 0 || p && e - f >= a
  }
  function g() {
    var e = es();
    if (v(e))
      return y(e);
    l = oa(g, function (e) {
      var n = t - (e - c);
      return p ? Kn(n, a - (e - f)) : n
    }(e))
  }
  function y(e) {
    return l = o,
      d && r ? m(e) : (r = i = o,
        s)
  }
  function _() {
    var e = es()
      , n = v(e);
    if (r = arguments,
      i = this,
      c = e,
      n) {
      if (l === o)
        return function (e) {
          return f = e,
            l = oa(g, t),
            h ? m(e) : s
        }(c);
      if (p)
        return $i(l),
          l = oa(g, t),
          m(c)
    }
    return l === o && (l = oa(g, t)),
      s
  }
  return t = Hs(t) || 0,
    As(n) && (h = !!n.leading,
      a = (p = "maxWait" in n) ? max(Hs(n.maxWait) || 0, t) : a,
      d = "trailing" in n ? !!n.trailing : d),
    _.cancel = function () {
      l !== o && $i(l),
        f = 0,
        r = c = i = l = o
    }
    ,
    _.flush = function () {
      return l === o ? s : y(es())
    }
    ,
    _
}
function Hs(e) {
  if ("number" == typeof e)
    return e;
  if (Ns(e))
    return F;
  if (As(e)) {
    var t = "function" == typeof e.valueOf ? e.valueOf() : e;
    e = As(t) ? t + "" : t
  }
  if ("string" != typeof e)
    return 0 === e ? e : +e;
  e = yn(e);
  var n = Ge.test(e);
  return n || Xe.test(e) ? Dt(e.slice(2), n ? 2 : 8) : qe.test(e) ? F : +e
}
var es = function () {
  return Date.now()
}
export const showClipBounds = (g) => {
  if (!g.isEditing && g.imageViewport) {
    g.canvas
      ? (g.previousIndex = this.canvas._objects.indexOf(this))
      : (this.previousIndex = void 0),
      this.saveState(),
      (this._saveStateProperties = E(this._stateProperties)),
      this._hideImgStyles(),
      (this.clipChild = new We({
        width: this.width,
        height: this.height,
        left: this.left,
        top: this.top,
        angle: this.angle || 0,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        fill: "#00000000",
        centeredRotation: !0,
        excludeFromExport: !0,
      })),
      (this.clipChild.clipParent = this);
    var e = this._getControlsVisibility();
    this.checkDisableClipChild()
      ? this.clipChild.setControlsVisibility({
        tl: !1,
        tr: !1,
        br: !1,
        bl: !1,
        ml: !1,
        mt: !1,
        mr: !1,
        mb: !1,
        mtr: !1,
        mbm: !1,
      })
      : this.clipChild.setControlsVisibility(
        rt({}, e, {
          mtr: !1,
          mbm: !1,
        })
      );
    var t = this.clipChild.cornerStyleIcons;
    (this.clipChild.cornerStyleIcons = rt({}, t, {
      tl: "clip",
      tr: "clip",
      bl: "clip",
      br: "clip",
    })),
      this.clipChild.saveState(),
      (this.clipChildState = E(this.clipChild.getSavedState())),
      (this.editingAngle = this.angle || 0);
    var a = this.calcCoords(!0),
      n = this.getCenterPoint();
    this.editingScale = this.scaleX;
    var i = this.getScaledWidth() / this.imageViewport.width / this.scaleX,
      r = this.getScaledHeight() / this.imageViewport.height / this.scaleY;
    (this.width = i), (this.height = r);
    var o = this.getScaledWidth(),
      c = this.getScaledHeight();
    (this.scaleX *= this.imageCanvasScale),
      (this.scaleY *= this.imageCanvasScale);
    var l = this.getScaledWidth(),
      u = this.getScaledHeight(),
      h = this.getOriginalRect(),
      d = new s.fabric.Point(h.x, h.y);
    this.flipX &&
      (this.imageViewport.x =
        1 - this.imageViewport.width - this.imageViewport.x),
      this.flipY &&
      (this.imageViewport.y =
        1 - this.imageViewport.height - this.imageViewport.y);
    var p = d.x - this.imageViewport.x * o,
      g = d.y - this.imageViewport.y * c;
    if (this.imageAngle) {
      var f = {
        x: this.imageViewport.x + this.imageViewport.width / 2,
        y: this.imageViewport.y + this.imageViewport.height / 2,
      };
      (p -= (l - o) * f.x), (g -= (u - c) * f.y);
    }
    var m = this.angle || 0,
      v = m + (this.imageAngle || 0),
      b = s.fabric.util.rotatePoint(
        new s.fabric.Point(p, g),
        n,
        s.fabric.util.degreesToRadians(v)
      );
    (this.left = b.x),
      (this.top = b.y),
      (this.previousImageViewport = rt({}, this.imageViewport)),
      (this.imageViewport.x = 0),
      (this.imageViewport.y = 0),
      (this.imageViewport.width = 1),
      (this.imageViewport.height = 1),
      this.onClipChanged(!0),
      this.imageAngle && (this.angle = v),
      s.fabric.util.trigger(Oe.FTIMAGE_SHOW_CLIP, {
        object: this,
        clipCoords: rt({}, a, {
          left: h.x,
          top: h.y,
          width: this.getClipArea().width,
          height: this.getClipArea().height,
          angle: m,
          flipX: this.flipX,
          flipY: this.flipY,
          centerPoint: n,
          clipPath: this._clipExtraData(),
        }),
      }),
      this.rotate(v),
      this.group || this.setCoords(),
      this.bringToFront(),
      this.canvas.add(this.clipChild),
      this.clipChild.on("moving", this.updateClipReact.bind(this)),
      this.clipChild.on("scaling", this._onClipScaling.bind(this)),
      this.clipChild.on("mousedown", this._onClipMouseDown.bind(this));
  }
};

export const _adjustPositionV2 = (g) => {
  var e = getPreviousCenterPoint(g),
    t = fabric.util.rotatePoint(
      new fabric.Point(g.transform.target.left, g.transform.target.top),
      e,
      fabric.util.degreesToRadians(-g.transform.target.angle)
    ),
    a = t.x,
    n = t.y;
  g.transform.target.clipChild.rotate(-g.transform.target.imageAngle);
  var i = g.transform.target.clipChild.getBoundingRect(!0, !0);
  g.transform.target.clipChild.rotate(g.transform.target.editingAngle);
  g.transform.target.cropArea.rotate(g.transform.target.angle);
  var r = i.left - (g.transform.target.getScaledWidth() - i.width),
    o = i.top - (g.transform.target.getScaledHeight() - i.height),
    c = _(r, a, i.left),
    l = _(o, n, i.top);
  if (c != a || l != n) {
    var u = fabric.util.rotatePoint(
      new fabric.Point(c, l),
      e,
      fabric.util.degreesToRadians(g.transform.target.angle)
    );
    g.transform.target.set("left", u.x), g.transform.target.set("top", u.y);
  }
  console.log(g);
};
export const getPreviousCenterPoint = (g) => {
  // console.log(g)
  return (
    g.transform.target.clipChild &&
    g.transform.target.clipChild.getCenterPoint()
  );
};
export const _ = (e, t, a) => {
  return fabric.util.capValue(e, t, a);
};
export const getOriginalRect = (e) => {

  let j = e.target.clipChild
  if (j) {
    var t = e.target.getCenterPoint(),
      a = fabric.util.rotatePoint(
        new fabric.Point(e.target.left, e.target.top),
        t,
        fabric.util.degreesToRadians(-(e.target.angle || 0))
      );
    return {
      x: a.x,
      y: a.y,
      width: e.target.width,
      height: e.target.height,
      scaleX: e.target.scaleX,
      scaleY: e.target.scaleY,
    };
  }
};

export const handleMouseDown = (e) => {
  if (n && e && e.transform) {
    var t = e.transform,
      a = t && t.corner,
      n = getOriginalRect(t),
      i = getPreviousCenterPoint(e);
    t.target.clipChild.rotate(-t.target.imageAngle);
    var r = t.target.clipChild.getBoundingRect(!0, !0);
    t.target.clipChild.rotate(t.target.editingAngle);
    var o = fabric.util.rotatePoint(
      new fabric.Point(t.target.left, t.target.top),
      i,
      fabric.util.degreesToRadians(-t.target.angle)
    ),
      c = 0,
      l = 0,
      u = t.target.width,
      h = t.target.height,
      d = t.target.getScaledWidth(),
      p = t.target.getScaledHeight(),
      g = r.left - o.x,
      f = r.top - o.y;
    "tl" === t.corner
      ? ((c = (d - g) / u), (l = (p - f) / h))
      : "tr" === t.corner
        ? ((c = (g + r.width) / u), (l = (p - f) / h))
        : "br" === t.corner
          ? ((c = (g + r.width) / u), (l = (f + r.height) / h))
          : "bl" === t.corner && ((c = (d - g) / u), (l = (f + r.height) / h)),
      (t.target.minScale = Math.max(c, l));
  }
  else { _isChangeSizeCorner(a) && t.target.initialImageSize && _beforeChangeSize(e) }
};
// export const _beforeChangeSize = () => {

// }

export const _adjustScalingV2 = (e) => {
  // this.imageChanged = !0;
  var t = e.transform,
    a = t && t.corner,
    n = getOriginalRect(t);
  if (t.target.isEditing && e && e.transform && n) {
    if (
      t.target.scaleX <= t.target.minScale ||
      t.target.scaleY <= t.target.minScale
    ) {
      var i = this.translateToOriginPoint(
        this.getCenterPoint(),
        t.originX,
        t.originY
      );
      (this.scaleX = this.minScale),
        (this.scaleY = this.minScale),
        this.setPositionByOrigin(i, t.originX, t.originY);
    } else t.target.editingScale = t.target.scaleX;
    // this._showOrHideGridlines(!0)
  }
  // else
  // this._isChangeSizeCorner(a) && this._onSizeChanged(a)
};
