import { getCookies, removeAllCookies, removeCookies, setCookies } from "./Cookies";
import * as appConstants from "./AppConstant";
import * as buffer from "buffer";
import { getUserDetails } from "./factory";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { updateUserLocation, updateUserOnLogin } from "../helper/sdk_factory";
import { getRealmSession } from "../helper/realm";
import CryptoJS from "crypto-js";
window.Buffer = buffer.Buffer;

export const getSession = () => {
  //return { email: 'pabaho2557@llubed.com', id: 93 };

  const loginStatus = getAuthCookies(appConstants.LOGIN_STATUS);
  let userData = "";

  if (loginStatus == "1") {
    const encryptData = getAuthCookies("keydata");
    userData = encryptData ? dycKeyData("decrypt", encryptData) : "";

    // console.log('userData', userData);

    const userPayload = {
      emailId: userData.email,
      userEmail: userData.email,
    };
    setDataInCookies(userPayload);
  }
  return userData;
};
export const getAuthCookies = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");
  let authCookiesData = "";
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      authCookiesData = c.substring(name.length, c.length);
    }
  }
  return authCookiesData;
};
// export const dycKeyData = (string) => {
//   const key = "a2dc2a99e649f147bcabc5a99bea7d96";
//   string = hexToStr(string);
//   if (string.search(key)) {
//     let newstr = string.replace(key, "");
//     const buff = Buffer.from(newstr, "base64");
//     const str = buff.toString("utf-8");
//     return str;
//   } else {
//     return false;
//   }
// };
export const dycKeyData = (action = "decrypt", string) => {
  var encryptionMethod = "AES-256-CBC";
  var secret = "&idh74zD37SsjnjnsADD##@!!Wsnjd$3"; //must be 32 char length
  var iv = "dmKn#3&6dh@!hjs!";
  if (action == "encrypt") {
    // var encryptor = crypto.createCipheriv(encryptionMethod, secret, iv);
    // return encryptor.update(string, "utf8", "base64") + encryptor.final("base64");

    const cipher = CryptoJS.AES.encrypt(string, CryptoJS.enc.Utf8.parse(secret), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // console.log("encrypting dataaaa before ", string);
    // Get the base64-encoded string of the ciphertext
    const encryptedString = cipher.toString();
    // console.log("encrypting dataaaa after", encryptedString, string);
    return encryptedString;
  } else if (action == "decrypt") {
    // var decryptor = crypto.createDecipheriv(encryptionMethod, secret, iv);
    // let datas = decryptor.update(string, "base64", "utf8") + decryptor.final("utf8");

    const decipher = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Base64.parse(string) }, CryptoJS.enc.Utf8.parse(secret), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let datas = JSON.parse(decipher.toString(CryptoJS.enc.Utf8));

    if (datas) {
      return datas;
    } else {
      return false;
    }
  }
};

export const setDataInCookies = (data = {}) => {
  for (let key of Object.keys(data)) {
    // console.log('*****************cookies', key, '=====>', data[key]);
    setCookies(key, data[key]);
  }
};
function hexToStr(hex) {
  hex = hex.toString(); //force conversion
  var str = "";
  for (var i = 0; i < hex.length && hex.substr(i, 2) !== "00"; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

/**
 * Create an authenticated session for the user.
 * @param {Object} userData - User data for authentication.
 * @returns {Object} - User response object after authentication.
 */
export const createAuthSession = async (userData) => {
  // console.log('userData--->>>>>', userData);
  try {
    // Fetch user details from the server

    let userResponse = await getUserDetails(userData);
    // Handle missing userResponse
    if (!userResponse || !userResponse.userId || Object.keys(userResponse).length === 0) {
      userResponse = createDefaultUserPayload(userData);
    }
    // if (!userResponse || Object.keys(userResponse).length === 0) {
    //   userResponse = createDefaultUserPayload(userData);
    // }
    // Store user data

    storeUserData(userResponse);
    console.log("handleMeterBilling", userResponse);

    return userResponse;
  } catch (error) {
    console.error("Error creating auth session:", error);
    throw error; // Rethrow the error for proper error handling
  }
};

export const updateAuthSession = (realmData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentUserId = getCookies("userId");

      if (!currentUserId) {
        return reject(new Error("Current user not found"));
      }
      if (!realmData || !realmData._id) {
        deleteLocalMemory();
        const redirectUrl = `${appConstants.SIGNUP_URL}&${sessionStorage.getItem("pathnameSDK") ? atob(sessionStorage.getItem("pathnameSDK")) : "/"}`;
        window.location.replace(redirectUrl);
      } else {
        const { access_token, _id, alias, email, first_name, last_name, mobile_country_code, mobile_number, user_mobile, phone_number, country } =
          realmData;

        let imgProfile = realmData.profile_pic === "" ? "notexist" : `${realmData.imageurl}/${realmData.profile_pic}`;

        const userprofiledata = {
          first_name: realmData.first_name,
          last_name: realmData.last_name,
          profile_pic: imgProfile,
        };
        setDataInCookies(userprofiledata);

        const appAccessData = getAppAccessData(realmData);
        console.log("app access data: ", appAccessData);

        if (appAccessData && appAccessData.status === "deactivated") {
          alert("Your account is deactivated. Please contact our customer support");
          return reject(new Error("Account deactivated"));
        }

        if (!appAccessData) {
          // Call registerProduct or perform any other required action
        }

        const payload = { email, id: _id };
        let userResponse = await getUserDetails(payload);

        if (Object.keys(userResponse).length === 0) {
          const currentTime = new Date().getTime();
          const userPayload = {
            accountStatus: 0,
            SignUp_date: currentTime,
            lastLogin: currentTime,
            clientId: _id,
            email: email,
            mobile_country_code: mobile_country_code,
            mobile_number: mobile_number,
            user_mobile: user_mobile ? phone_number : "",
            userName: first_name ? `${first_name} ${last_name}` : "New User",
            country,
          };

          console.log("updateOrCreateUser", userPayload, access_token);
          await updateOrCreateUser(userPayload, access_token);

          await registerUserLocation();
        } else {
          setDataInCookies(userResponse);
          userResponse.userName = first_name ? `${realmData.first_name} ${realmData.last_name}` : "New User";

          if (typeof realmData.trasactional === "boolean") {
            if (!realmData.trasactional || userResponse.emailNotifications) {
              if (!userResponse.emailNotifications) {
                userResponse.emailNotifications = {};
              }
              userResponse.emailNotifications.newProject = realmData.trasactional;
            }
          }

          let userResponseToUpdate = {
            email: realmData.email,
            userName: userResponse.userName,
          };

          if (userResponse.emailNotifications) {
            userResponseToUpdate.emailNotifications = userResponse.emailNotifications;
          }

          console.log("updateOrCreateUser", userResponse, userResponseToUpdate, userResponse.userId);
          await updateUserOnLogin(userResponseToUpdate, userResponse.userId);
        }

        resolve("Authentication session updated successfully");
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getAppAccessData = (userData) => {
  const appAccessData = userData && userData.apps_access.find((appAccess) => appAccess.product == "design");
  return appAccessData;
};
// Create a default user payload
const createDefaultUserPayload = (userData) => {
  return {
    emailId: userData.email,
    userEmail: userData.email,
    userId: userData.id,
    clientId: userData.id,
    totalMemoryUsed: 0,
    totalDesignCreated: 0,
    totalPhotoCreated: 0,
    userType: "free",
    accountStatus: 0,
    claimFreeAccount: 0,
    SignUp_date: new Date().getTime(),
    paymentDayLeft: -1,
    planId: null,
    currency: null,
  };
};
// Store user data in cookies and session storage
const storeUserData = (userResponse) => {
  setDataInCookies(userResponse);
  sessionStorage.setItem("emailId", userResponse.emailId);
  sessionStorage.setItem("clientId", userResponse.clientId);
};
/**
 * Authenticate a user with Firebase using a custom token.
 * @param {string} client_token - The client token for authentication.
 * @param {string} _id - The user's ID.
 * @param {string} alias - The user's alias.
 * @returns {Promise<boolean>} - A Promise that resolves to true on successful authentication.
 */


 export const firebaseAuth = async (clientToken, userId, userAlias) => {
  let retryCount = 0;
  const MAX_RETRIES = 3;
  
  const fetchToken = async () => {
    const TOKEN_URL = `${appConstants.FUNCTION_URL}/getAuthTokenAPISDK?client_token=${clientToken}&_id=${userId}&alias=${userAlias}`;
    
    console.log("Calling firebaseAuth", clientToken, userId, userAlias, TOKEN_URL);
    
    if (!TOKEN_URL) {
      throw new Error("TOKEN_URL does not exist");
    }
    
    try {
      const response = await fetch(TOKEN_URL);
      
      if (!response.ok) {
        throw new Error("Failed to fetch token");
      }

      const token = await response.text();
      return token;
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error;
    }
  };

  // console.log("Calling firebaseAuth",retryCount , MAX_RETRIES);
  
  // Retry logic
  while (retryCount < MAX_RETRIES) {
    try {
      const token = await fetchToken();
      const auth = getAuth();
      
      await signInWithCustomToken(auth, token)
        .then((userCredential) => {
          return userCredential.user.getIdToken();
        })
        .then((idToken) => {
          console.log("ID Token:", idToken);
        });
      
      console.log("Authentication successful");
      return true; // Authentication succeeded
    } catch (error) {
      retryCount++;
      console.log(`Retrying... attempt ${retryCount}`);
      if (retryCount >= MAX_RETRIES) {
        console.error("Max retries reached. Authentication failed.");
        window.location.reload();
      }
      
      // Optionally, add a delay between retries (e.g., 1 second)
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
};


/**
 * Registers the user's location by updating it in the database and setting it in cookies.
 * If the user is logged in, their location is updated in the database.
 * @returns {Promise<void>}
 */
export const registerUserLocation = async () => {
  try {
    // Get the user's current location
    const location = await getUserLocation();

    // Set the location data in cookies
    setDataInCookies(location);

    // Retrieve the user's ID from cookies
    const userId = getCookies("userId");

    if (userId) {
      // Update the user's location in the database
      await updateUserLocation(location);
    }

    console.log("User location registration successful");
  } catch (error) {
    console.error("Error registering user location", error);
    throw new Error({ message: `Error registering user location, ${error} ` });
  }
};
/**
 * Retrieves the user's location information using an external API.
 * @returns {Promise<{ ipAddress: string, countryCode: string }>} - The user's location information.
 */
export async function getUserLocation() {
  // Default location information in case of API failure
  let location = { ipAddress: "49.36.223.0", countryCode: "IN" };

  const myHeaders = new Headers();
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    // Fetch user location data from the API
    const response = await fetch("https://checkout.appypie.com/api/get-visitor-ip", requestOptions);
    const responseJson = await response.json();

    if (responseJson.status === 1) {
      const { ipAddress, countryCode } = responseJson;
      location = { ipAddress, countryCode };
    }

    return location;
  } catch (error) {
    console.log("Error fetching user location", error);
    return location; // Return default location in case of an error
  }
}
/**
 * Delete the user session and perform necessary cleanup functions
 * @async
 * @throws {Error} if an error occurs during the process
 */
export const deleteSession = async () => {
  try {
    // Retrieve the session ID from sessionStorage
    const sessionId = sessionStorage.getItem("sessionId");
    // if (sessionId) {
    //     BQ_Log({
    //         Action: appConstants.LOG_ACTION.INTERVAL,
    //         Type: 'logout',
    //         sessionId
    //     });
    // }
    // Clear local memory
    deleteLocalMemory();
    // BQ_Log({
    //     Action: appConstants.LOG_ACTION.SESSION,
    //     Type: 'logout'
    // });
    // activityLog('logout');
    // Redirect user for and after logout
    const redirectUrl = `${appConstants.LOGOUT_URL}&${appConstants.RETURN_URL}`;
    window.location.replace(redirectUrl);
  } catch (error) {
    // Instead of returning, throw the error for higher-level handling
    throw new Error("Error in deleteSession >>>>" + error.message);
  }
};
/**
 * Clears specific items from local memory, including session storage and cookies.
 */
export const deleteLocalMemory = () => {
  // Clear session storage
  sessionStorage.clear();

  // Remove specific cookies related to user information
  removeCookies("userType");
  removeCookies("userId");
  removeCookies("emailId");
  removeCookies("userEmail");
  removeCookies("totalMemoryUsed");
  removeCookies("totalDesignCreated");
  removeCookies("totalPhotoCreated");
  removeCookies("claimFreeAccount");
  removeCookies("accountStatus");
  removeCookies("first_name");
  removeCookies("last_name");
  removeCookies("profile_pic");

  // Remove all cookies (if needed)
  removeAllCookies();
};
export const getPathName = () => {
  try {
    const pathnameSDK = sessionStorage.getItem("pathnameSDK");
    const pathnameSDKSearchQ = sessionStorage.getItem("pathnameSDKSearchQ");

    const mainpathname = pathnameSDK
      ? atob(pathnameSDK) === "/login" ||
        atob(pathnameSDK) === "/LOGIN" ||
        atob(pathnameSDK) === "/signup" ||
        atob(pathnameSDK) === "/SIGNUP" ||
        atob(pathnameSDK) === "//"
        ? "/"
        : atob(pathnameSDK)
      : "/";
    const mainpathnameSearchQ = pathnameSDKSearchQ ? atob(pathnameSDKSearchQ) : "";

    return mainpathname + mainpathnameSearchQ;
  } catch (error) {
    console.error("An error occurred in getPathName:", error);
    return "/";
  }
};
