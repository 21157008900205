import { createSlice } from "@reduxjs/toolkit";

export const aiStudioLeftSlice = createSlice({
  name: "aiStudioLeft",
  initialState: {
    dalleGenerations: {
      promptValue: "",
      //   quality: null,
      //   selectedStyle: null,
      charRemaining: 1000,
      numberOfGenerations: 1,
      error: { errorInGeneration: false, errorText: "" },
      isGenerating: false,
    },
    SDXLGenerations: {
      promptValue: "",
      charRemaining: 1000,
      selectedStyle: null,
      numberOfGenerations: 1,
      error: { errorInGeneration: false, errorText: "" },
      isGenerating: false,
    },
    MainSection: {
      allGeneratedImage: [],
      generatingBox: { status: false, count: 0 },
    },
  },
  reducers: {
    updateDalleGenerations(state, action) {
      state.dalleGenerations = { ...state.dalleGenerations, ...action.payload };
    },
    updateSDXLGenerations(state, action) {
      state.SDXLGenerations = { ...state.SDXLGenerations, ...action.payload };
    },
    updateMainSection(state, action) {
      state.MainSection = { ...state.MainSection, ...action.payload };
    },
    // updateAllGeneratedImage(state, action) {
    //   return {
    //     ...state,
    //     MainSection: {
    //       ...state.MainSection,
    //       allGeneratedImage: [action.payload, ...state.MainSection.allGeneratedImage],
    //     },
    //   };
    // },
    removeGeneratedImage(state, action) {
      return {
        ...state,
        MainSection: {
          ...state.MainSection,
          allGeneratedImage: state.MainSection.allGeneratedImage.filter((image) => image.docId !== action.payload.docId),
        },
      };
    },

    updateAllGeneratedImage(state, action) {
      // Check if the image (or placeholder) already exists in the state
      const existingIndex = state.MainSection.allGeneratedImage.findIndex((img) => img.docId === action.payload.docId);

      if (existingIndex !== -1) {
        // Image exists: Update the existing image/placeholder
        const updatedImages = state.MainSection.allGeneratedImage.map((image, index) => {
          if (index === existingIndex) {
            // Found the image to update
            return { ...image, ...action.payload, isPlaceholder: false };
          }
          return image;
        });

        return {
          ...state,
          MainSection: {
            ...state.MainSection,
            allGeneratedImage: updatedImages,
          },
        };
      } else {
        // Image does not exist: Add the new image to the array
        return {
          ...state,
          MainSection: {
            ...state.MainSection,
            allGeneratedImage: [action.payload, ...state.MainSection.allGeneratedImage],
          },
        };
      }
    },

    updateAiStudioCreditsAndCta(state, action) {
      console.log("handleMeterBilling cdata studio_left", action.payload);

      const ctaDetailsMapping = {
        0: { isPaymentRequired: false, btn_cta: "Generate" }, //generation allowed
        1: { isPaymentRequired: true, btn_cta: "Start Free Trial" }, // start free trial
        2: { isPaymentRequired: true, btn_cta: "Upgrade to unlock credits" }, // upgrade to unlock credits by checkout
        3: { isPaymentRequired: true, btn_cta: "Add Funds to Wallet" }, //add fund
        4: { isPaymentRequired: true, btn_cta: "Subscribe" }, // buy now or subscribe
        5: { isPaymentRequired: true, btn_cta: "Upgrade to unlock credits" }, //upgrade to unlock by API
      };

      const { AIImageGenerations } = action.payload;
      console.log("handleMeterBilling cdata studio_left", action.payload);
      return;
      action.payload = {
        ...action.payload,
        AIQRCodeGenerations: AIImageGenerations,
        AIEmojiGenerations: AIImageGenerations,
        AIAvatarGenerations: AIImageGenerations,
        AIYTGenerations: AIImageGenerations,
        AIAdsGenerations: AIImageGenerations,
      };

      Object.entries(action.payload).forEach(([key, data]) => {
        const cta_data = data.CTA;
        const ctaDetails = ctaDetailsMapping[cta_data.code_num] || {};

        state[key] = { ...state[key], numCredits: data.creditRemaining, cta_data: cta_data, ctaDetails: ctaDetails };
        // this.setState({
        //   numCredits: cdata.creditBalance,
        //   cta_data: cta_data,
        //   isCreditDataLoaded: true,
        //   isFreeUser: false,
        // });
      });
    },
  },
});
export const {
  updateActveTab,
  updateActiveModel,
  // updateDalleGenerations,
  // updateSDXLGenerations,
  updateMainSection,
  updateAllGeneratedImage,
  removeGeneratedImage,
  updateAiStudioCreditsAndCta,
} = aiStudioLeftSlice.actions;
