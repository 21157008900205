import axios from "axios";
import { SEARCH_BASE_URL } from "./AppConstant";

/**
 * Creates a JWT token using the provided data.
 *
 * @param {object} data - Data for creating the JWT token.
 * @returns {Promise<string>} - The created JWT token.
 * @throws {Error} - If an error occurs during token creation.
 */
export const createJwtToken = async (data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "Your-Custom-Header": "Header-Value",
    };

    const response = await axios.post(`${SEARCH_BASE_URL}/createJwtToken`, data, { headers });
    // console.log("Created JWT tokennnnn in function", data, response);
    // Handle successful response
    // console.log("Token created:", response.data.token);
    return response.data.token;
  } catch (error) {
    // Handle error
    console.error("Error creating token:", error);
    throw error;
    // throw new Error("Failed to create JWT token");
  }
};

/**
 * Verifies a JWT token.
 *
 * @param {string} token - The JWT token to be verified.
 * @returns {Promise<object>} - The payload extracted from the verified token.
 * @throws {Error} - If the token is missing or verification fails.
 */
export const verifyJwtToken = async (token) => {
  try {
    if (!token) {
      throw new Error("Token is missing");
    }

    const data = { token };
    const response = await axios.post(`${SEARCH_BASE_URL}/verifyJwtToken`, data);
    // console.log("verified JWT tokennnnn in function", JSON.parse(atob(response.data.payload)));
    // Handle successful response
    // console.log("Token verification response:", response.data.payload);

    return JSON.parse(atob(response.data.payload));
  } catch (error) {
    // Handle error
    console.error("Error verifying token:", error.message);
    throw error;
    // throw new Error("Failed to verify JWT token");
  }
};
