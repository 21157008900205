import { useDispatch } from "react-redux";
import {
  renderloaderFrame,
  update_crop_mode,
} from "../../Reducer/Canvas_Slicer";
import { isPerpendicular, _onClipMouseDown } from "./Ap_CropImage";

let img__ = "";
let left1 = img__.left || 0;
let top1 = img__.top || 0;
let scale1x; //= img__.scaleX || 0
let scale1y; //= img__.scaleY || 0
let width1 = img__.width || 0;
let height1 = img__.height || 0;

let clipPath = "";
let cropMask = "";
let cropArea = "";
let left2 = cropArea.left || 0;
let top2 = cropArea.top || 0;
let scale2x = cropArea.scaleX || 0;
let scale2y = cropArea.scaleY || 0;
let width2 = cropArea.width || 0;
let height2 = cropArea.height || 0;
// let Canvas = ''
let imgid = "";
let Desired_img_index = 0;
let Desired_imgRect_index = 0;

export const addRect2img = (Canvas, boxframe, obj) => {
  let ImageControl = new fabric.Rect(
    {
      // selectable: true,
      left: obj.left,
      top: obj.top,
      width: obj.width,
      height: obj.height,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      // originX:'center',
      // originY:'center',
      absolutePositioned: true,
      angle: obj.angle,
      // clipPath: boxframe,
      typeis: "image",
      // fill: "red",

      opacity: 0,
      id: obj.id,
      // centeredRotation: true,
      transparentCorners: false,
      lockScalingFlip: true,
      strokeWidth: 0,
      cornerColor: "red",
      original_img: obj,
      llc: "opl",
    },
    {
      crossOrigin: "anonymous",
    }
  );
  return ImageControl;
};
export const handleMoving = (Canvas, obj, img) => {
  // Canvas._objects.map((imag) => {
  //   if (imag.id === obj.id && imag.type === "image") {
  //     // temp_id = imag.id
  //     imag.set({
  //       top: obj.top,
  //       left: obj.left,
  //     });
  //     // console.log(obj.top, imag.top)
  //   }
  // });
  if (obj.original_img) {
    if (obj.original_img.id === obj.id && obj.original_img.type === "image") {
      obj.original_img.set({
        top: obj.top,
        left: obj.left,
      });
    }
  }
  obj.setCoords();
};
export const handlertc = (Canvas, obj, img, boxframe) => {
  obj.originX = "center";
  obj.originY = "center";
  obj.top = obj.top + obj.getScaledHeight() / 2;
  obj.left = obj.left + obj.getScaledWidth() / 2;
  (cropArea.originX = "center"),
    (cropArea.originY = "center"),
    (cropArea.top = obj.top + obj.getScaledHeight() / 2);
  cropArea.left = obj.left + obj.getScaledWidth() / 2;
};
export const handleRotating = (Canvas, obj, img) => {
  // Canvas._objects.map((imag) => {
  //   if (imag.id == obj.id && imag.type === "image") {
  //     img_ = imag;

  //     imag.rotate(obj.angle);
  //     imag.set({
  //       // originX:"center",
  //       // originY:"center",
  //       angle: obj.angle,

  //       top: obj.top,
  //       left: obj.left,
  //     });
  //     img_.editingAngle = obj.angle;
  //     img_.setCoords();
  //     Canvas.renderAll();
  //   }
  // });
  if (obj.original_img) {
    if (obj.original_img.id === obj.id && obj.original_img.type === "image") {
      obj.original_img.rotate(obj.angle);
      obj.original_img.set({
        // originX:"center",
        // originY:"center",
        angle: obj.angle,

        top: obj.top,
        left: obj.left,
      });
      obj.original_img.editingAngle = obj.angle;
      obj.original_img.setCoords();
      Canvas.renderAll();

      if (obj.original_img.imageLoading) {
        // console.log("rotating image", obj.original_img);
        // renderloaderFrame(obj.original_img);
        obj.original_img.render = function (ctx) {
          var angleRadians = (this.angle * Math.PI) / 180, // Convert angle to radians
            centerX = this.getCenterPoint().x,
            centerY = this.getCenterPoint().y,
            z = (this.canvas && this.canvas.getZoom()) || 1,
            o = Math.max(this.scaleX || 1, this.scaleY || 1),
            c = 12,
            s = 0.5 / o / z,
            n = (((+new Date() / 2) % 360) * Math.PI) / 180,
            i = (((+new Date() / 4) % 360) * Math.PI) / 180;

          // Apply rotation around the object's center
          ctx.save(); // Save the current context state
          ctx.translate(centerX, centerY); // Translate to the center of the object
          ctx.rotate(angleRadians); // Rotate the context
          ctx.translate(-centerX, -centerY); // Translate back

          // Your loader drawing code (simplified for brevity)
          ctx.fillStyle =
            "rgba(210, 210, 210, " +
            (0.2 +
              0.4 * Math.sin((((+new Date() / 4) % 360) * Math.PI) / 180 / 2)) +
            ")";
          let b = fabric.util.rotatePoint(
            new fabric.Point(obj.left, obj.top),
            obj.getCenterPoint(),
            fabric.util.degreesToRadians(-obj.angle)
          );
          ctx.fillRect(
            b.x,
            b.y,
            this.width * this.scaleX,
            this.height * this.scaleY
          );
          ctx.beginPath();
          ctx.arc(
            this.getCenterPoint().x,
            this.getCenterPoint().y,
            c,
            n,
            n + Math.PI
          );
          ctx.strokeStyle = "#4BD3FB";
          ctx.fillStyle = "#4BD3FB";
          ctx.fill();
          ctx.closePath();

          ctx.restore(); // Restore the context to its original state
          console.log("rotating image", obj.left, b.x);
          if (this.canvas) {
            this.canvas.requestRenderAll();
          }
        };
      }
    }
  }

  obj.setCoords();
};

export const handleScaling = (Canvas, e, img) => {
  // console.log(img)
  // img = Canvas.getObjects();
  // img.map((imag) => {
  if (e.transform.target.original_img) {
    let imag = e.transform.target.original_img;
    if (imag.id == e.transform.target.id && imag.type === "image") {
      // console.log("-------imagecontrol sclaing-------")
      if (e.transform.action === "scaleX") {
        // console.log("scaleX")
        let viewPortWidth =
          (e.transform.target.width * e.transform.target.scaleX) / imag.scaleX;
        if (e.transform.corner === "ml") {
          // console.log("scaleX", imag, e.transform.target)
          let c = viewPortWidth - imag.width;
          let cy = imag.cropX - c;

          let nt = imag.left - c * imag.scaleX;
          let nw = imag.width + c;
          if (cy >= 0) {
            imag.set({
              cropX: cy,
              left: e.transform.target.left,
              top: e.transform.target.top,
              width: nw,
            });
          } else {
            let wMax = imag.width;
            let ns = (viewPortWidth * imag.scaleX) / wMax;
            let nh = (imag.height * imag.scaleY) / ns;
            imag.set({
              cropX: 0,
              // left: nt,
              left: e.transform.target.left,
              top: e.transform.target.top,
              width: wMax,
              scaleX: ns,
              scaleY: ns,
              height: nh,
            });
          }
          Canvas.renderAll();
        } else if (e.transform.corner === "mr") {
          if (viewPortWidth <= imag._width - imag.cropX) {
            imag.set({ width: viewPortWidth });
          } else {
            console.log("scalingout");
            let wMax = imag._width - imag.cropX;
            let ns = (viewPortWidth * imag.scaleX) / wMax;
            let nh = (imag.height * imag.scaleY) / ns;
            imag.set({
              width: wMax,
              scaleX: ns,
              scaleY: ns,
              height: nh,
            });
          }
          Canvas.renderAll();
        }
      } else if (e.transform.action === "scaleY") {
        let viewPortHeight =
          (e.transform.target.height * e.transform.target.scaleY) / imag.scaleY;
        if (e.transform.corner === "mt") {
          // console.log("scaleY", imag, e.transform.target)
          let c = viewPortHeight - imag.height;
          let cy = imag.cropY - c;
          let nt = imag.top - c * imag.scaleY;
          let nw = imag.height + c;
          if (cy >= 0) {
            imag.set({
              cropY: cy,
              // top: nt,
              left: e.transform.target.left,
              top: e.transform.target.top,
              height: nw,
            });
          } else {
            let hMax = imag.height;
            let ns = (viewPortHeight * imag.scaleY) / hMax;
            let nw = (imag.width * imag.scaleX) / ns;
            imag.set({
              height: hMax,
              scaleX: ns,
              scaleY: ns,
              width: nw,
              cropY: 0,
              // top: nt,
              left: e.transform.target.left,
              top: e.transform.target.top,
            });
          }
          Canvas.renderAll();
        } else if (e.transform.corner === "mb") {
          // console.log("mb", e, imag)
          // console.log("scaleY, mb", imag, e.transform.target)
          if (viewPortHeight <= imag._height - imag.cropY) {
            imag.set({ height: viewPortHeight });
          } else {
            let hMax = imag._height - imag.cropY;
            let ns = (viewPortHeight * imag.scaleY) / hMax;
            let nw = (imag.width * imag.scaleX) / ns;
            imag.set({
              height: hMax,
              scaleX: ns,
              scaleY: ns,
              width: nw,
            });
          }
          Canvas.renderAll();
        }
      }
      if (e.transform.action === "scale") {
        let k =
          (e.transform.target.width * e.transform.target.scaleX) / imag.width;
        imag.set({
          scaleX: k,
          scaleY: k,
          top: e.transform.target.top,
          left: e.transform.target.left,
        });
        Canvas.renderAll();
      }
    }
  }
  Canvas.renderAll();
  // });
  //e.transform.target.setCoords();
};
export const CropImage = (Canvas, e, bimg, boxframe, Active_imageRect) => {
  Canvas = Canvas;
  let obj = e.target;
  let img_;
  let sWidth = 2;
  if (e.target.group) {
    e.target.group._objects.map((imag) => {
      if (imag.id == obj.id) {
        if (imag.type == "rect") {
          Active_imageRect = imag;
        }
        if (imag.type === "image") {
          img_ = imag;
        }
      }
    });
  } else {
    Canvas._objects.map((imag) => {
      if (imag.id == obj.id) {
        if (imag.type == "rect") {
          Active_imageRect = imag;
        }
        if (imag.type === "image") {
          img_ = imag;
        }
      }
    });
  }
  img_.set({
    previousPoint: {
      left: img_.left,
      top: img_.top,
      width: img_.width,
      height: img_.height,
      scaleX: img_.scaleX,
      scaleY: img_.scaleY,
      cropX: img_.cropX,
      cropY: img_.cropY,
      angle: img_.angle ? img_.angle : 0,
    },
  });
  // console.log("Image", img_);

  // console.log(img_)
  img_.evented = true;
  img_.editingScale = img_.scaleX;
  imgid = img_.id;
  img__ = img_;
  left1 = img_.left;
  top1 = img_.top;
  img_.setControlVisible("mtr", false);
  img_.setControlVisible("ml", false);
  img_.setControlVisible("mt", false);
  img_.setControlVisible("mr", false);
  img_.setControlVisible("mb", false);
  // scale1x = img_.scaleX;
  // scale1y = img_.scaleY;
  // width1 = img_.width;
  // height1 = img_.height;

  let allObj = Canvas.getObjects();
  for (let i = 0; i < allObj.length; i++) {
    if (allObj[i].id === img_.id && allObj[i].type === img_.type) {
      Desired_img_index = i;
    }
    if (allObj[i].id === img_.id && allObj[i].type !== img_.type) {
      Desired_imgRect_index = i;
    }
  }

  Canvas.bringToFront(img_);

  // img_.clipPath = null;
  img_.set({
    stroke: "#00ff00",
    // strokeWidth: 6,
    width: img_.width, //- 6,
    height: img_.height, //- 6,
  });

  if (img_.isCropped == "original") {
    let new_c = {
      x: img_.left - img_.cropX * img_.scaleX,
      y: img_.top - img_.cropY * img_.scaleY,
    };

    let n = e.target.getCenterPoint();
    let vc = { x: e.target.left, y: e.target.top };
    let v = (img_.angle || 0) + (img_.imageAngle || 0);
    let b = fabric.util.rotatePoint(
      new fabric.Point(new_c.x, new_c.y),
      vc,
      fabric.util.degreesToRadians(v)
    );
    img_.set({
      top: b.y,
      left: b.x, //b.x-40,
      angle: img_.angle ? img_.angle : 0,
      cropY: 0,
      cropX: 0,
      selectable: !0,
      isEditing_: !0,
      width: img_._width, //- 6,
      height: img_._height, // - 6,
      setControlsVisibility: {
        mtr: false,
        ml: false,
        mr: false,
        mt: false,
        mb: false,
      },
    });

    Canvas.setActiveObject(img_);

    clipPath = new fabric.Rect({
      left: Active_imageRect.left,
      top: Active_imageRect.top,
      fill: "rgba(255,0,0,0.5)",
      opacity: 1,
      // originX:'center',
      // originY:'center',
      width: Active_imageRect.getScaledWidth(),
      height: Active_imageRect.getScaledHeight(),

      inverted: true,
      absolutePositioned: true,
      angle: Active_imageRect.angle ? Active_imageRect.angle : 0,
      lockScalingFlip: true,
    });
    // console.log(Canvas.getCenter(), fabric.util.transformPoint(new fabric.Point(0, 0), Canvas.viewportTransform))
    cropMask = new fabric.Rect({
      left: 0 - Canvas.viewportTransform[4] / Canvas.getZoom(),
      top: 0 - Canvas.viewportTransform[5] / Canvas.getZoom(),
      fill: "#CBCDCE", //"rgb(100,100,100)",
      opacity: 0.8,
      // scaleX: Canvas.viewportTransform[0],
      // scaleY: Canvas.viewportTransform[3],
      width: Canvas.width / Canvas.getZoom(),
      height: Canvas.height / Canvas.getZoom(),
      clipPath: clipPath,
      selectable: false,
      id: "masking",
      lockScalingFlip: true,
    });
    cropArea = new fabric.Rect({
      // padding: 1,
      // rx: 5,
      // ry: 5,
      left: Active_imageRect.left,
      top: Active_imageRect.top,
      fill: "",
      width: Active_imageRect.getScaledWidth() - sWidth,
      height: Active_imageRect.getScaledHeight() - sWidth,
      // scaleX: Active_imageRect.original_img.scaleX,
      // scaleY: Active_imageRect.original_img.scaleY,
      absolutePositioned: true,
      id: "_cropArea",
      lockMovementX: true,
      lockMovementY: true,
      perPixelTargetFind: true,
      stroke: "rgb(0,0,0)",
      // originX:'center',
      // originY:'center',
      strokeWidth: sWidth,

      strokeUniform: true,
      noScaleCache: false,
      lockScalingFlip: true,
      angle: Active_imageRect.angle ? Active_imageRect.angle : 0,
      transparentCorners: false,
      lockUniScaling: true,
      borderColor: "black",
      cornerColor: "black",
      cornerSize: 10,
      cornerStyle: "circle",
      pimg: img_,
      cropMask: cropMask,
      centeredRotation: true,

      // dirty: true,
    });

    img_.set({
      cropArea: cropArea,
      clipChild: cropArea,
      // editingAngle: img_.angle,
    });

    cropArea = cropArea.setControlsVisibility({ mtr: false });

    cropArea.on("mouseover", (e) => {
      // console.log(e)

      Canvas.setActiveObject(cropArea);

      Canvas.renderAll();
    });

    cropArea.on("scaling", (e) => {
      handleMaskingsclaing(Canvas, e, boxframe, [img__], Active_imageRect);
    });

    cropArea.on("mousedown", (e) => {
      // console.log("cropArea mousedown", e)
      _onClipMouseDown(e);
    });
    Canvas.controlsAboveOverlay = true;
    Canvas.overlayImage = cropMask;
    Canvas.remove(obj);
    Canvas.add(cropArea);

    // Canvas.bringToFront(img_)
    Canvas.setActiveObject(img_);
    Canvas.bringToFront(cropArea);
    Canvas.renderAll();
    left1 = img_.left;
    top1 = img_.top;
    scale1x = cropArea.scaleX;
    scale1y = cropArea.scaleY;
    width1 = cropArea.width;
    height1 = cropArea.height;
  }
  Canvas.renderAll();

  return img_;
};

export const handleMasking = () => {};
export const eventOpen = () => {};
export const eventOut = () => {};

export const handleMaskingsclaing = (Canvas, e, boxframe, img, imgRect) => {
  let img_; //= e.transform.target.pimg
  let sWidth = 2;
  let obje = e.target;
  cropArea = e.transform.target;

  Canvas.getObjects().map((imag) => {
    if (imag.id == imgid && imag.type === "image") {
      img_ = imag;
    }
  });

  let cropArea_new = new fabric.Rect({
    left: cropArea.left,
    top: cropArea.top,
    fill: "rgba(255,255,255,0)",
    opacity: 0.5,
    width: cropArea.width + sWidth / cropArea.scaleX,
    height: cropArea.height + sWidth / cropArea.scaleY,
    inverted: true,
    absolutePositioned: true,
    scaleX: cropArea.scaleX,
    scaleY: cropArea.scaleY,
    angle: cropArea.angle,
    lockScalingFlip: true,
    strokeWidth: 0,
  });

  cropMask.clipPath = cropArea_new;
  Canvas.renderAll();

  //''''''''''''//
  if (img__.cropArea) {
    var t = img__.scaleX,
      a = img__.scaleY,
      n = img__.getCenterPoint(),
      i = img__.scaleX,
      r = img__.scaleY;
    img__.scaleX = i;
    img__.scaleY = r;
    img__.setPositionByOrigin(n, "center", "center");
    img__.setCoords();
    for (
      var o = e.transform, c = img__.cropArea.getCoords(!0, !0), s = !0, l = 0;
      l < c.length;
      l++
    ) {
      var u = c[l];
      if (!img__.containsPoint(u, null, !0)) {
        // console.log(u)
        s = !1;
        break;
      }
    }
    // let cx2 = cropArea.left + cropArea.getScaledWidth();
    // // // let cy1 = crpar.top
    // let cy2 = cropArea.top + cropArea.getScaledHeight();
    // // // let ix1 = crpar.pimg.left
    // let ix2 = cropArea.pimg.left + cropArea.pimg.getScaledWidth();
    // // // let iy1 = crpar.pimg.top;
    // let iy2 = cropArea.pimg.top + cropArea.pimg.getScaledHeight();
    // let obj = e.transform.target;
    // let o = e.transform
    // obj.setCoords();

    if (
      !s &&
      img__.editingClipScaleX &&
      img__.editingClipScaleY &&
      img__.editingClipScaleH &&
      img__.editingClipScaleW
    ) {
      var h = img__.cropArea.translateToOriginPoint(
        img__.cropArea.getCenterPoint(),
        o.originX,
        o.originY
      );
      img__.cropArea.scaleX = img__.editingClipScaleX;
      img__.cropArea.scaleY = img__.editingClipScaleY;
      img__.cropArea.width = img__.editingClipScaleW;
      img__.cropArea.height = img__.editingClipScaleH;
      img__.cropArea.setPositionByOrigin(h, o.originX, o.originY);
      cropArea_new.scaleX = img__.editingClipScaleX;
      cropArea_new.scaleY = img__.editingClipScaleY;
      cropArea_new.width = img__.editingClipScaleW;
      cropArea_new.height = img__.editingClipScaleH;
      cropArea_new.setPositionByOrigin(h, o.originX, o.originY);
      Canvas.setActiveObject(img_);
      // console.log("if", img__.cropArea.width, img__.cropArea.height)
    } else
      isPerpendicular(img__) ||
        ((img__.editingClipScaleX = img__.cropArea.scaleX),
        (img__.editingClipScaleY = img__.cropArea.scaleY),
        (img__.editingClipScaleW = img__.cropArea.width),
        (img__.editingClipScaleH = img__.cropArea.height));
    {
      img__.editingClipScaleX = img__.cropArea.scaleX;
      img__.editingClipScaleY = img__.cropArea.scaleY;
      img__.editingClipScaleH = img__.cropArea.height;
      img__.editingClipScaleW = img__.cropArea.width;

      img__.scaleX = t;
      img__.scaleY = a;
      img__.setPositionByOrigin(n, "center", "center");
      img__.setCoords();
      Canvas.setActiveObject(img_);
    }
  }

  //''''''''''''//

  // // console.log("cropArea--->>>", cropArea.getScaledWidth(), e.transform.target.getScaledWidth(), "\n", "pimg--->>>", cropArea.pimg.getScaledWidth(), e.transform.target / pimg.getScaledWidth())
  // let cx2 = cropArea.left + cropArea.getScaledWidth();
  // // // let cy1 = crpar.top
  // let cy2 = cropArea.top + cropArea.getScaledHeight();
  // // // let ix1 = crpar.pimg.left
  // let ix2 = cropArea.pimg.left + cropArea.pimg.getScaledWidth();
  // // // let iy1 = crpar.pimg.top;
  // let iy2 = cropArea.pimg.top + cropArea.pimg.getScaledHeight();
  // let obj = e.transform.target;
  // obj.setCoords();

  // if (
  //   cropArea.getScaledWidth() >= img_.getScaledWidth() ||
  //   cropArea.getScaledHeight() >= img_.getScaledHeight() ||
  //   cropArea.left < img_.left ||
  //   cropArea.top < img_.top ||
  //   cx2 > ix2 ||
  //   cy2 > iy2
  // ) {
  //   obj.left = left1;
  //   obj.top = top1;
  //   obj.scaleX = scale1x;
  //   obj.scaleY = scale1y;
  //   obj.width = width1;
  //   obj.height = height1;
  //   cropArea_new.left = left1;
  //   cropArea_new.top = top1;
  //   cropArea_new.scaleX = scale1x;
  //   cropArea_new.scaleY = scale1y;
  //   cropArea_new.width = width1;
  //   cropArea_new.height = height1;
  //   obj.setCoords();
  //   // console.log("rhgugkkfkogjragiewuyweuiryorqiuygilhdsokjgnf")
  // } else {
  //   // console.log("else", )
  //   left1 = obj.left;
  //   top1 = obj.top;
  //   scale1x = obj.scaleX;
  //   scale1y = obj.scaleY;
  //   width1 = cropArea.width;
  //   height1 = cropArea.height;
  //   obj.setCoords();
  //   Canvas.setActiveObject(img_);
  // }

  // cropArea.setCoords()
};

export const rotateSelection = (picture, angle, Canvas) => {
  Canvas.discardActiveObject();
  var sel = new fabric.ActiveSelection([picture, picture.cropArea], {
    canvas: Canvas,
  });
  Canvas.setActiveObject(sel);
  sel.rotate(angle);
  Canvas.requestRenderAll();

  //unselect
  Canvas.discardActiveObject();
  Canvas.requestRenderAll();
};

export const handleImageScale_stop = (Canvas, e, boxframe) => {
  let img_ = e.transform.target;
  // console.log(e.transform.target.scaleX, e.transform.target.scaleY);
  let img_right = img_.left + img_.getScaledWidth();
  let img_bottom = img_.top + img_.getScaledHeight();
  let cropArea_right = cropArea.left + cropArea.getScaledWidth();
  let cropArea_bottom = cropArea.top + cropArea.getScaledHeight();
  img_.setCoords();
  // if (img_.scaleX <= img_.scaleeee || img_.scaleY <= img_.scaleeeeY) {
  //   img_.scaleX = img_.scaleeee;
  //   img_.scaleY = img_.scaleeeeY;
  // }
  if (
    img_.getScaledWidth() <= cropArea.getScaledWidth() ||
    img_.getScaledHeight() <= cropArea.getScaledHeight() ||
    img_.left > cropArea.left ||
    img_.top > cropArea.top ||
    img_right < cropArea_right ||
    img_bottom < cropArea_bottom
  ) {
    // console.log("imagescaling stop---->>>>>>", img_.top, cropArea.top)
    img_.left = left2;
    img_.top = top2;
    img_.scaleX = scale2x;
    img_.scaleY = scale2y;
    img_.width = width2;
    img_.height = height2;
    Canvas.renderAll();
  } else {
    left2 = img_.left;
    top2 = img_.top;
    scale2x = img_.scaleX;
    scale2y = img_.scaleY;
    width2 = img_.width;
    height2 = img_.height;

    Canvas.renderAll();
  }
  img_.setCoords();
};
export const handleImageMoving_stop = (Canvas, e, boxframe) => {
  // console.log("image will not move", e.transform.target.top, cropArea.top);
  let img_ = e.transform.target;
  let bx1 = cropArea.left; // cropArea Left
  let bx2 = cropArea.left + cropArea.getScaledWidth(); // cropArea Right
  let by1 = cropArea.top; // cropArea Top
  let by2 = cropArea.top + cropArea.getScaledHeight(); // cropArea Bottom

  let ix1 = img_.left; // img_ left
  let ix2 = img_.left + img_.getScaledWidth(); // img_ right
  let iy1 = img_.top; //img_ top
  let iy2 = img_.top + img_.getScaledHeight(); // img_ bottom

  if (ix1 >= bx1) {
    // img_ left > cropArea left
    img_.set({ left: bx1 });
  }
  if (ix2 <= bx2) {
    img_.set({ left: bx2 - img_.getScaledWidth() });
  }
  if (iy1 >= by1) {
    img_.set({ top: by1 });
  }
  if (iy2 <= by2) {
    img_.set({ top: by2 - img_.getScaledHeight() });
  }
  left2 = img_.left;
  top2 = img_.top;
  scale2x = img_.scaleX;
  scale2y = img_.scaleY;
  width2 = img_.width;
  height2 = img_.height;
};
export const RestoreimagePosition = (
  Canvas,
  obj,
  imageRect_,
  boxframe,
  img_cropArea,
  cropMode
) => {
  console.log("restoring positiond", obj);
  let imageRect;
  if (obj.type === "image") {
    let img_ = obj;

    // let cropArea = img_cropArea;

    imageRect_.map((imgrec) => {
      if (imgrec.id === img_.id) {
        imageRect = imgrec;
      }
    });
    // console.log("diff....", cropArea.getScaledWidth(), img_.getScaledWidth())
    // let nc = { x: ((cropArea.left - img_.left) / img_.scaleX), y: ((cropArea.top - img_.top) / img_.scaleY) }
    // let vc = { x: cropArea.left, y: cropArea.top }
    // let v = (img_.angle || 0) + (img_.imageAngle || 0)
    // let b = fabric.util.rotatePoint(new fabric.Point(nc.x, nc.y), vc, fabric.util.degreesToRadians(v));
    let cREct = getOriginalRect(cropArea, cropArea);
    let iRect = getOriginalRect(img_, cropArea);
    img_.set({
      top: cropArea.top,
      left: cropArea.left,
      angle: cropArea.angle ? cropArea.angle : 0,
      cropY: (cREct.y - iRect.y) / img_.scaleY,
      cropX: (cREct.x - iRect.x) / img_.scaleX,
      width: cropArea.getScaledWidth() / img_.scaleX,
      // clipPath: boxframe,
      height: cropArea.getScaledHeight() / img_.scaleY,
      // isCropped: "cropped",
      selectable: false,
      evented: false,
      stroke: null,
      strokeWidth: null,
      cropArea: null,
      clipChild: null,
    });

    imageRect.set({
      left: cropArea.left,
      top: cropArea.top,
      width: img_.width,
      height: img_.height,
      scaleX: img_.scaleX,
      scaleY: img_.scaleY,
      absolutePositioned: true,
      angle: cropArea.angle ? cropArea.angle : 0,
    });
    // console.log(cropArea.width, img_.width, imageRect.width)
    Canvas.remove(cropArea);
    Canvas.add(imageRect);
    Canvas.renderAll();
    // Canvas.setActiveObject(imageRect);
    // console.log("all objec before setting", Canvas.getObjects())
    //---------------Sending to their position----------------//
    let Current_img_index;
    let Current_imgRect_index;
    const allObj = Canvas.getObjects();
    for (let i = 0; i < allObj.length; i++) {
      if (allObj[i].id === img_.id && allObj[i].type === img_.type) {
        Current_img_index = i;
      }
      if (allObj[i].id === img_.id && allObj[i].type !== img_.type) {
        Current_imgRect_index = i;
      }
    }
    let sendBackward_img_times = Current_img_index - Desired_img_index;
    let sendBackward_imgRect_times =
      Current_imgRect_index - Desired_imgRect_index;
    for (let i = 0; i < sendBackward_img_times; i++) {
      Canvas.sendBackwards(img_);
    }
    for (let i = 0; i < sendBackward_imgRect_times; i++) {
      Canvas.sendBackwards(imageRect);
    }
    // console.log(Canvas.getObjects());
    //-------------------------------------------------------------//
    Canvas.overlayImage = null;
    Canvas.renderAll();
    // console.log("Restoring Position", imageRect, img_)
    img_.setCoords();
    // left1 = img_.left;
    // top1 = img_.top;
    // scale1x = img_.scaleX;
    // scale1y = img_.scaleY;

    // width1 = cropArea.width;
    // height1 = cropArea.height;

    // left2 = cropArea.left;
    // top2 = cropArea.top;
    // scale2x = cropArea.scale2x;
    // scale2y = cropArea.scale2y;
    // width2 = img_.width;
    // height2 = img_.height;
  } else {
    // console.log("else")
  }
  // if (obj.id === "_cropArea") {
  //     let img_ = obj.pimg
  //     let cropArea = obj;
  //     img_.set({
  //         top: cropArea.top,
  //         left: cropArea.left,
  //         cropY: (cropArea.top - img_.top) / img_.scaleY,
  //         cropX: (cropArea.left - img_.left) / img_.scaleX,
  //         width: (cropArea.getScaledWidth()) / img_.scaleX,
  //         height: (cropArea.getScaledHeight()) / img_.scaleY,
  //         isCropped: "cropped",
  //         selectable: false,
  //         evented: false,
  //     })
  //     Canvas.remove(cropArea);
  //     imageRect.set({
  //         left: cropArea.left,
  //         top: cropArea.top,
  //         width: img_.width,
  //         height: img_.height,
  //         absolutePositioned: true,
  //         angle: cropArea.angle,
  //     })
  //     // Canvas.remove(cropArea);
  //     Canvas.add(imageRect);
  //     Canvas.setActiveObject(imageRect);
  //     Canvas.overlayImage = null;
  //     Canvas.renderAll();
  // }
  obj.setCoords();
  // ---------------rotation calculation --------------------//
  //------------------------------------------------------------//
  // imageRect.setCoords()
  Canvas.croping_active_img = null;
  Canvas.savestate();
  return { cropMode: false, cropArea: cropArea, imageRect }; //cropMode = false;
};

export const getOriginalRect = (e, ca) => {
  if (e) {
    var t = ca.getCenterPoint(),
      a = fabric.util.rotatePoint(
        new fabric.Point(e.left, e.top),
        t,
        fabric.util.degreesToRadians(-(e.angle || 0))
      );
    return {
      x: a.x,
      y: a.y,
      width: e.width,
      height: e.height,
    };
  }
};

export const _onClipScaling = (e) => {
  // console.log(e.transform.target.scaleX, e.transform.target.scaleY)
  if (img__.cropArea) {
    var t = img__.scaleX,
      a = img__.scaleY,
      n = img__.getCenterPoint(),
      i = img__.getScaledWidth() / img__._width,
      r = img__.getScaledHeight() / img__._height;
    img__.scaleX = i;
    img__.scaleY = r;
    img__.setPositionByOrigin(n, "center", "center");
    img__.setCoords();
    for (
      var o = e.transform, c = img__.cropArea.getCoords(!0, !0), s = !0, l = 0;
      l < c.length;
      l++
    ) {
      var u = c[l];
      if (!img__.containsPoint(u, null, !0)) {
        s = !1;
        break;
      }
    }
    if (
      !s &&
      img__.editingClipScaleX &&
      img__.editingClipScaleY &&
      img__.editingClipScaleH &&
      img__.editingClipScaleW
    ) {
      var h = img__.cropArea.translateToOriginPoint(
        img__.cropArea.getCenterPoint(),
        o.originX,
        o.originY
      );
      img__.cropArea.scaleX = img__.editingClipScaleX;
      img__.cropArea.scaleY = img__.editingClipScaleY;
      img__.cropArea.width = img__.editingClipScaleW;
      img__.cropArea.height = img__.editingClipScaleH;
      img__.cropArea.setPositionByOrigin(h, o.originX, o.originY);
    } else {
      isPerpendicular(img__) ||
        ((img__.editingClipScaleX = img__.cropArea.scaleX),
        (img__.editingClipScaleY = img__.cropArea.scaleY),
        (img__.editingClipScaleW = img__.cropArea.width),
        (img__.editingClipScaleH = img__.cropArea.height));
      img__.editingClipScaleX = img__.cropArea.scaleX;
      img__.editingClipScaleY = img__.cropArea.scaleY;
      img__.editingClipScaleH = img__.cropArea.height;
      img__.editingClipScaleW = img__.cropArea.width;

      img__.scaleX = t;
      img__.scaleY = a;
      img__.setPositionByOrigin(n, "center", "center");
      img__.setCoords();
    }
  }
};

export const updateClipReact = (img) => {
  if (img.clipChild) {
    var e = img.getOriginalRect(),
      t = img.clipChild.calcCoords(!0),
      a = img.clipChild.getCenterPoint();
    s.fabric.util.trigger(Oe.FTIMAGE_SHOW_CLIP, {
      object: this,
      clipCoords: rt({}, t, {
        left: e.x,
        top: e.y,
        width: this.getClipArea().width,
        height: this.getClipArea().height,
        angle: this.clipChild.angle,
        flipX: this.flipX,
        flipY: this.flipY,
        centerPoint: a,
        clipPath: this._clipExtraData(),
      }),
      isUpdate: !0,
    });
  }
};

export const _onMousedown = (e) => {
  // debugger;
  let t = e.transform,
    a = t && t.corner,
    n = getOriginalRects(e.target),
    i = getPreviousCenterPoint(e);
  if (n && e && e.transform) {
    t.target.clipChild.rotate(-t.target.imageAngle);
    let r = t.target.clipChild.getBoundingRect(!0, !0);
    t.target.clipChild.rotate(t.target.editingAngle);
    let o = fabric.util.rotatePoint(
        new fabric.Point(t.target.left, t.target.top),
        t.target.cropArea.getCenterPoint(),
        fabric.util.degreesToRadians(-t.target.angle)
      ),
      c = 0,
      l = 0,
      u = t.target.width,
      h = t.target.height,
      d = t.target.width * t.target.scaleX,
      p = t.target.height * t.target.scaleY,
      g = r.left - o.x,
      f = r.top - o.y;
    // "tl" === t.corner ? (((c = (d - g) / u), (l = (p - f) / h))) : "tr" === t.corner ? (c = (g + r.width) / u,
    //   l = (p - f) / h) : "br" === t.corner ? (c = (g + r.width) / u,
    //     l = (f + r.height) / h) : "bl" === t.corner && (c = (d - g) / u,
    //       l = (f + r.height) / h);
    //.....

    if ("tl" === t.corner) {
      c = (d - g) / u;
      l = (p - f) / h;
    } else if ("tr" === t.corner) {
      c = (g + r.width) / u;
      l = (p - f) / h;
      // console.log("c,l--->>", c, l)
    } else if ("br" === t.corner) {
      c = (g + r.width) / u;
      l = (f + r.height) / h;
    } else if ("bl" === t.corner) {
      c = (d - g) / u;
      l = (f + r.height) / h;
    }
    //...

    t.target.minScale = Math.max(c, l);
  } else {
  }
};

export const _onScaling = (e) => {
  // debugger;
  let img_ = e.transform.target;
  // this.imageChanged = !0;
  // img.map((imag) => {
  //   if (imag.id == e.transform.target.id) {
  //     img_ = imag;
  //   }
  // });
  var t = e.transform,
    a = t && t.corner,
    n = getOriginalRects(img_);
  if (img_.isEditing_ && e && e.transform) {
    // console.log(img_.cropArea.scaleX, img_.scaleX, cropArea.width, cropArea.getScaledWidth())
    if (img_.scaleX <= img_.minScale || img_.scaleY <= img_.minScale) {
      var i = img_.translateToOriginPoint(
        img_.getCenterPoint(),
        t.originX,
        t.originY
      );
      img_.scaleX = img_.minScale;
      img_.scaleY = img_.minScale;
      img_.setPositionByOrigin(i, t.originX, t.originY);
      // img_.setCoords();
    } else img_.editingScale = img_.scaleX;
    // img_.minScale = img_.scaleX;
    // this._showOrHideGridlines(!0)
  } else {
  }
  // this._isChangeSizeCorner(a) && this._onSizeChanged(a)
};
export const getOriginalRects = (e) => {
  let j = e.cropArea;
  if (j) {
    var t = e.getCenterPoint(),
      a = fabric.util.rotatePoint(
        new fabric.Point(e.left, e.top),
        t,
        fabric.util.degreesToRadians(-(e.angle || 0))
      );
    return {
      x: a.x,
      y: a.y,
      width: e._width,
      height: e._height,
      scaleX: e.scaleX,
      scaleY: e.scaleY,
    };
  }
};

export const _getOriginalRects = (e) => {
  let j = e.target.cropArea;
  if (j) {
    var t = e.target.getCenterPoint(),
      a = fabric.util.rotatePoint(
        new fabric.Point(e.target.left, e.target.top),
        t,
        fabric.util.degreesToRadians(-(e.target.angle || 0))
      );
    return {
      x: a.x,
      y: a.y,
      width: e.target._width,
      height: e.target._height,
      scaleX: e.target.scaleX,
      scaleY: e.target.scaleY,
    };
  }
};

export const _adjustPositionV2 = (g) => {
  var e = getPreviousCenterPoint(g),
    t = fabric.util.rotatePoint(
      new fabric.Point(g.transform.target.left, g.transform.target.top),
      e,
      fabric.util.degreesToRadians(-(g.transform.target.angle || 0))
    ),
    a = t.x,
    n = t.y;
  g.transform.target.cropArea.rotate(-g.transform.target.imageAngle);

  var i = g.transform.target.cropArea.getBoundingRect(!0, !0);
  g.transform.target.cropArea.rotate(g.transform.target.editingAngle);
  g.transform.target.cropArea.rotate(g.transform.target.angle);
  var r = i.left - (g.transform.target.getScaledWidth() - i.width),
    o = i.top - (g.transform.target.getScaledHeight() - i.height),
    c = _(r, a, i.left),
    l = _(o, n, i.top);
  if (c != a || l != n) {
    var u = fabric.util.rotatePoint(
      new fabric.Point(c, l),
      e,
      fabric.util.degreesToRadians(g.transform.target.angle || 0)
    );
    g.transform.target.set("left", u.x), g.transform.target.set("top", u.y);
  }
  // console.log(g.transform.target.left, g.transform.target.top)
};

export const _adjustPositionV2_sttencil = (g) => {
  var e = getPreviousCenterPoint_stencil(g),
    t = fabric.util.rotatePoint(
      new fabric.Point(g.transform.target.left, g.transform.target.top),
      e,
      fabric.util.degreesToRadians(-(g.transform.target.angle || 0))
    ),
    a = t.x,
    n = t.y;
  //g.transform.target.bndry.rotate(-g.transform.target.imageAngle);
  g.transform.target.bndry.rotate(-g.transform.target.imageAngle);
  var i = g.transform.target.bndry.getBoundingRect(!0, !0);
  g.transform.target.bndry.rotate(g.transform.target.editingAngle);
  g.transform.target.bndry.rotate(g.transform.target.angle);
  var r = i.left - (g.transform.target.getScaledWidth() - i.width),
    o = i.top - (g.transform.target.getScaledHeight() - i.height),
    c = _(r, a, i.left),
    l = _(o, n, i.top);
  if (c != a || l != n) {
    console.log("movinggggggg before rotatepoint", c, l, a, n);
    var u = fabric.util.rotatePoint(
      new fabric.Point(c, l),
      e,
      fabric.util.degreesToRadians(g.transform.target.angle)
    );
    console.log("movinggggggg after rotatepoint", u.x, u.y);
    g.transform.target.set("left", u.x), g.transform.target.set("top", u.y);
  }
  // console.log(g.transform.target.left, g.transform.target.top)
};
export const getPreviousCenterPoint_stencil = (g) => {
  // console.log(g)
  return g.transform.target.bndry && g.transform.target.bndry.getCenterPoint();
};

export const getPreviousCenterPoint = (g) => {
  // console.log(g)
  return (
    g.transform &&
    g.transform.target &&
    g.transform.target.cropArea &&
    g.transform.target.cropArea.getCenterPoint()
  );
};
export function _(e, t, a) {
  return fabric.util.capValue(e, t, a);
}
