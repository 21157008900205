import { createSlice } from "@reduxjs/toolkit";
import json from "../component/ImagesTemplate/ImageCategories.json";

export const aiStudioSlice = createSlice({
  name: "aiStudio",
  initialState: {
    activeTab: null,
    activeModel: json.initialModelData[0],
    avatarData:null
  },
  reducers: {
    updateActveTab(state, action) {
      state.activeTab = action.payload.value;
    },
    updateActiveModel(state, action) {
      state.activeModel = action.payload;
    },
    updateAvatarData(state,action){
      state.avatarData = action.payload;
    }
  },
});
export const { updateActveTab, updateActiveModel,updateAvatarData } = aiStudioSlice.actions;
