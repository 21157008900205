import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import { useLocation, useParams } from "react-router-dom";
import { getSession } from "./utils/AuthService";
import { useSelector } from "react-redux";
import RoutesConfig from "./RoutesConfig";
import CreditPolicyUpdate from "./component/Popup/StudioModal/CreditPolicyUpdate";
let { id: userId } = getSession();

function App() {
  const params = useParams();
  const location = useLocation();
  const designSDKLoginStatus = useSelector((state) => state.user.designSDKLoginStatus); 
  const creditPolicyUpdatePopup = useSelector((state) => state.user.creditPolicyUpdatePopup); 

  const isLoggedInWithDesignSDK =
    userId && designSDKLoginStatus === "DesignSDK";

  // State to manage authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedInWithDesignSDK);
  const showCreditPolicyUpdatePopup = creditPolicyUpdatePopup&&(location.pathname === "/" || location.pathname.startsWith("/editor") || location.pathname.startsWith("/playground"));

  useEffect(() => {
    if (location.pathname.split("?")[0] === "/withoutcc") {
      sessionStorage.setItem(
        "paymentRedirectionPathName",
        sessionStorage.getItem("pathnameSDK")
      );
    }
    sessionStorage.setItem("pathnameSDK", btoa(location.pathname));
    if(sessionStorage.getItem("paymentRedirectionPathName")&&new URLSearchParams(window.location.search)&&new URLSearchParams(window.location.search).get('uniqueId')&&new URLSearchParams(window.location.search).get('uniqueId')=="zutvf9096k"){
      sessionStorage.setItem("pathnameSDK", sessionStorage.getItem("paymentRedirectionPathName"));
      sessionStorage.removeItem("paymentRedirectionPathName");
    }
    // console.log("dsofljfdcdsfg", location.search)

    // sessionStorage.setItem("pathnameSDKSearchQ", btoa(location.search));
  }, []);

  return (
    <>
    {showCreditPolicyUpdatePopup&&<CreditPolicyUpdate showCreditPolicyUpdatePopup={showCreditPolicyUpdatePopup}/>}
    <RoutesConfig isAuthenticated={isAuthenticated} userId={userId} setIsAuthenticated={setIsAuthenticated} />
    </>
  );
}

export default App;

