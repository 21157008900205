import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-toastify/dist/ReactToastify.css";
import store from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SDKUSEDFOR } from "./utils/AppConstant";
import ErrorBoundary from "./component/ErrorBoundary";
const domain = window.location.hostname;
const current_url = window.location.href;
const containsVideoTool = current_url.includes("devvideo");
function createDiv() {
  var createDiv = document.createElement("div");
  createDiv.id = "appyDesign";
  document.body.appendChild(createDiv);
}
createDiv();

function customLog(message, style) {
  setTimeout(console.info.bind(console, `%c${message}`, style), 0);
}

if (
  process.env.NODE_ENV === "production" &&
  SDKUSEDFOR === "LIVE" &&
  !containsVideoTool &&
  !current_url.includes("designnft") &&
  !current_url.includes("designlocal") &&
  !current_url.includes("appydesignpay")
) {
  customLog("STOP!", "font-size: 40px; color: red; font-weight: bold;");
  customLog(
    "The developer console and other developer tools are intended for appypie web development professionals. Misuse can lead to functionality issues or security vulnerabilities.",
    "font-size: 18px;"
  );
  customLog(`${process.env.REACT_APP_BUILD_DATE}`, "color: red;");

  console.log = function () {};
  // console.error = function () {};
  console.warn = function () {};
  console.trace = function () {};
  console.time = function () {};
  console.timeEnd = function () {};
}
console.log(`**** ${SDKUSEDFOR} ${process.env.NODE_ENV} Mode ****`);

const root = ReactDOM.createRoot(document.getElementById("appyDesign"));

//document.addEventListener('DOMContentLoaded', function() {
const userAgent = navigator.userAgent;
const isChromeOnAndroid = /Chrome/.test(userAgent) && /Android/.test(userAgent);
const isChromeOnIphone = /CriOS/.test(userAgent) && /iPhone/.test(userAgent);
const isSafariOnIOS =
  /iPhone|iPad|iPod/.test(userAgent) &&
  /Safari/.test(userAgent) &&
  !/CriOS/.test(userAgent);
// Detect any iPad (all versions)
const isIPad =
  /iPad/.test(userAgent) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
const isChromeOnIPad = /CriOS/.test(userAgent) && isIPad;

if (isChromeOnIPad) {
  document.body.classList.add("chrome-ipad");
} else if (isIPad) {
  document.body.classList.add("safari-ipad");
}
if (isChromeOnAndroid) {
  document.body.classList.add("chrome-android");
}

if (isChromeOnIphone) {
  document.body.classList.add("chrome-ios");
}

if (isSafariOnIOS) {
  document.body.classList.add("chrome-ios-safari");
}
//});
// document.addEventListener("DOMContentLoaded", function () {
//   console.log("DOM fully loaded and parsed");

//   const userAgent = navigator.userAgent;
//   console.log("User Agent:", userAgent);

//   if (!userAgent) {
//     console.error("User agent is undefined");
//     return;
//   }

//   const isChromeOnIphone = /CriOS/.test(userAgent) && /iPhone/.test(userAgent);
//   console.log("Is Chrome on iPhone:", isChromeOnIphone);

//   if (isChromeOnIphone) {
//     document.body.classList.add("chrome-iphone");
//     console.log("Class 'chrome-iphone' added to body");
//   } else {
//     console.log("Not Chrome on iPhone, no class added");
//   }
// });
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <ErrorBoundary> */}
      <App />
      {/* </ErrorBoundary> */}
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
