import { createSlice } from "@reduxjs/toolkit";


export const mini_canvas_slicer = createSlice({
    name: "mini-canvas",
    initialState: {
        mini_canvas: null,

    },
    reducers: {
        updateMinicanvas: (state, action) => {
            return {
                ...state,
                mini_canvas: action.payload.value,
            }
        }
    }
})

export const { updateMinicanvas } = mini_canvas_slicer.actions