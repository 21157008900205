import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by Error Boundary: ", error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-light">
          <div className="card text-center" style={{ maxWidth: '400px' }}>
            <div className="card-header">
              <h1 className="text-danger">Oops!</h1>
            </div>
            <div className="card-body">
              <p className="card-text">
                Something went wrong. We're working on it!
              </p>
            </div>
            <div className="card-footer">
              <button 
                onClick={this.handleRefresh} 
                className="btn btn-primary"
              >
                Refresh Page
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
