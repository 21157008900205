import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    previous_template: {
        loaded_template: { category_name: null, category_data: null },
        searched_template_details: { search_status: false, search_word: null, search_data: null }
    },
    previous_photo_section: {
        singlecategory_details: { category_name: null, category_data: null },
        all_category_details: { category_data: null }
    }
}
export const left_Section_Previous_slice = createSlice({
    name: "left_Section_Previous_data",
    initialState,
    reducers: {
        update_previously_fetched_template: (state, action) => {
            state.previous_template.loaded_template.category_name = action.payload.category_name;
            state.previous_template.loaded_template.category_data = action.payload.category_data;
        },
        update_previously_search_template: (state, action) => {
            state.previous_template.searched_template_details.search_status = action.payload.search_status;
            state.previous_template.searched_template_details.search_word = action.payload.search_word;
            state.previous_template.searched_template_details.search_data = action.payload.search_data;
        },
        update_previously_fetched_photo_section: (state, action) => {
            return {
                ...state,
                previous_photo_section: {
                    ...state.previous_photo_section,
                    singlecategory_details: {
                        ...state.previous_photo_section.singlecategory_details,
                        category_name: action.payload.category_name ? ((action.payload.category_name === "0") ? null : action.payload.category_name) : state.previous_photo_section.singlecategory_details.category_name,//action.payload.category_name,
                        category_data: action.payload.category_data ? ((action.payload.category_data === "0") ? null : action.payload.category_data) : state.previous_photo_section.singlecategory_details.category_data//action.payload.category_data,
                    },
                    all_category_details: {
                        ...state.previous_photo_section.all_category_details,
                        category_data: action.payload.all_category_data ? action.payload.all_category_data : state.previous_photo_section.all_category_details.category_data,//action.payload.all_category_data
                    }
                }
            };
        }
    }
})
export const { update_previously_fetched_template, update_previously_fetched_photo_section, update_previously_search_template } = left_Section_Previous_slice.actions