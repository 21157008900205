import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPathName, getSession } from "../../utils/AuthService";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [messageHead, setMessageHead] = useState("Your payment is successful.");
  const [btnText, setBtnText] = useState("Explore Design Tool");
  const [messageBody, setMessageBody] = useState(
    "Thank you for subscribing Design tool. You can download the payment receipt from the “Billing Info” section."
  );

  useEffect(() => {
    const userData = getSession();
    if (!userData) {
      const pathnameSDK = sessionStorage.getItem("pathnameSDK");
      const pathname = pathnameSDK ? atob(pathnameSDK) : "/";
      const redirectUrl = `${SIGNUP_URL}&${pathname}`;
      window.location.replace(redirectUrl);
      return;
    }
    console.log("payment success page", Object.fromEntries([...searchParams]));
    let { type } = Object.fromEntries([...searchParams]);

    if (type && (type === "tryNow" || type === "tryNowWithCC" || type === "tryNowWithoutCC")) {
      setMessageHead("");
      setMessageBody("Your free trial for Appy Pie Design begins now…");
    }
  }, []);

  const redirectToReleventPath = () => {
    let paymentRedirectionPath = sessionStorage.getItem("successRedirection") || sessionStorage.getItem("paymentRedirectionPathName");
    if (paymentRedirectionPath) {
      console.log("paymentRedirectionPath if",paymentRedirectionPath);
      
      sessionStorage.setItem("pathnameSDK", paymentRedirectionPath);
      navigate(atob(paymentRedirectionPath));
      sessionStorage.removeItem("paymentRedirectionPathName");
      sessionStorage.removeItem("successRedirection");
    } else {
      console.log("paymentRedirectionPath else");
      let path = sessionStorage.getItem("pathnameSDK") && atob(sessionStorage.getItem("pathnameSDK"));
      if (path == "/payment-success") {
        sessionStorage.setItem("pathnameSDK", "/");
      }
      navigate("/");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="msgBox d-flex gap-3 flex-column flex-wrap justify-content-center text-center py-5 min-vh-100 align-items-center">
              <img alt="paymentSuccess" src="/payment/success.svg" />
              <h2 className="fw-semibold mt-3">Congratulations! {messageHead}</h2>
              <p className="fs-5 mb-0">{messageBody}</p>
              <a
                onClick={() => {
                  redirectToReleventPath();
                }}
                className="link-offset-3 fw-medium btn btn-outline-primary"
              >
                {btnText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
