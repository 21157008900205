import { configureStore } from "@reduxjs/toolkit";
import { Canvas_Slice } from "../Reducer/Canvas_Slicer";
import { mini_canvas_slicer } from "../Reducer/mini_canvas_slicer";
import { projct_related, toolBar_slice } from "../Reducer/Project_related";
import { template_slice } from "../Reducer/Template_Slicer";
import { left_Section_Previous_slice } from "../Reducer/Left_section_Previous_data_slicer";
import { userSlice } from "../Reducer/User_Slicer";
import { aiToolsSlice } from "../Reducer/AiTools_Slicer";
import { meterBillingSlice } from "../Reducer/MeterBilling_Slicer";
import { aiStudioSlice } from "../Reducer/AiStudioSlicer";
import { aiStudioLeftSlice } from "../Reducer/AiStudioLeftSlicer";

const delayResetMiddleware = (store) => (next) => (action) => {
  if (action.type === "aiTools/updateAIAvatarGenerations" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIAvatarGenerations({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (action.type === "aiTools/updateAILogoGenerations" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAILogoGenerations({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (action.type === "aiTools/updateAiTextToImage" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAiTextToImage({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (action.type === "aiTools/updateAIQRCodeGenerations" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIQRCodeGenerations({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (action.type === "aiTools/updateAIEmojiGenerations" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIEmojiGenerations({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (action.type === "aiTools/updateAIYTGenerations" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (action.type === "aiTools/updateAiTemplateMakerEditor" && action.payload.error && action.payload.error.errorInGeneration) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  } else if (
    action.type === "aiTools/updateAIQRCodeGenerations" &&
    action.payload.standardQrError &&
    action.payload.standardQrError.standardQrerrorInGeneration
  ) {
    setTimeout(() => {
      store.dispatch(
        aiToolsSlice.actions.updateAIQRCodeGenerations({ standardQrError: { standardQrerrorInGeneration: false, standardQrerrorText: "" } })
      );
    }, 5000);
  } else if (action.type === "aiTools/updateAIAvatarGenerations" && action.payload.NSFWerror && action.payload.NSFWerror.NSFWDetected) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIAvatarGenerations({ NSFWerror: { NSFWDetected: false, NSFWerrorText: "" } }));
    }, 5000);
  } else if (
    action.type === "aiTools/updateAIAvatarGenerations" &&
    action.payload.NSFWerrorReface &&
    action.payload.NSFWerrorReface.NSFWDetectedReface
  ) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIAvatarGenerations({ NSFWerrorReface: { NSFWDetectedReface: false, NSFWerrorTextReface: "" } }));
    }, 5000);
  }else if (
    action.type === "aiTools/updateAIFlexBannerGenerations" && action.payload.error && action.payload.error.errorInGeneration
  ) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ error: { errorInGeneration: false, errorText: "" } }));
    }, 5000);
  }else if (
    action.type === "aiTools/updateAIFlexBannerGenerations" && action.payload.uploadingError && action.payload.uploadingError.errorInUploading
  ) {
    setTimeout(() => {
      store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ uploadingError: { errorInUploading: false, errorUploadingText: "" } }));
    }, 5000);
  }

  return next(action);
};

const updateAiToolSliceWhileMeterBilledSliceUpdate = (store) => (next) => (action) => {
  if (
    action.type === "meterBilling/updateMeteredDetails" &&
    (action.payload.countryCode || action.payload.MeterBillingPrice || action.payload.paymentDetails)
  ) {
    if (action.payload.countryCode) {
      store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ countryCode: action.payload.countryCode }));
      store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ countryCode: action.payload.countryCode }));
      store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ countryCode: action.payload.countryCode }));
    }
    if (action.payload.MeterBillingPrice) {
      store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ MeterBillingPrice: action.payload.MeterBillingPrice }));
      store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ MeterBillingPrice: action.payload.MeterBillingPrice }));
      store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ MeterBillingPrice: action.payload.MeterBillingPrice }));
    }
    if (action.payload.paymentDetails && action.payload.paymentDetails?.payment_status) {
      store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ paymentStatus: action.payload.paymentDetails?.payment_status }));
      store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ paymentStatus: action.payload.paymentDetails?.payment_status }));
      store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ paymentStatus: action.payload.paymentDetails?.payment_status }));
    }
  }else if(action.type === "user/updateUserProductDetails"&&action.payload.paymentStatus){
    store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ paymentStatus: action.payload.paymentStatus }));
    store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ paymentStatus: action.payload.paymentStatus }));
    store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ paymentStatus: action.payload.paymentStatus }));
  }else if(action.type === "meterBilling/updatePaymentDetails"&&action.payload.paymentDetails?.payment_status){
    store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ paymentStatus: action.payload.paymentStatus }));
    store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ paymentStatus: action.payload.paymentStatus }));
    store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ paymentStatus: action.payload.paymentStatus }));
  }else if(action.type==="meterBilling/updateStudioCreditDetails"){
    store.dispatch(aiToolsSlice.actions.updateAiTemplateMakerEditor({ StudioCreditDetails: action.payload }));
    store.dispatch(aiToolsSlice.actions.updateAIYTGenerations({ StudioCreditDetails: action.payload }));
    store.dispatch(aiToolsSlice.actions.updateAIFlexBannerGenerations({ StudioCreditDetails: action.payload }));
  }
  return next(action);
};

export default configureStore({
  reducer: {
    project: projct_related.reducer,
    _canvas: Canvas_Slice.reducer,
    toolBar: toolBar_slice.reducer,
    template: template_slice.reducer,
    left_Section_Previous_data: left_Section_Previous_slice.reducer,
    mini_canvas: mini_canvas_slicer.reducer,
    user: userSlice.reducer,
    aiTools: aiToolsSlice.reducer,
    meterBilling: meterBillingSlice.reducer,
    aiStudio: aiStudioSlice.reducer,
    aiStudioLeft: aiStudioLeftSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(delayResetMiddleware,updateAiToolSliceWhileMeterBilledSliceUpdate),
});
